import React from "react";
import BuyAxies from "./BuyAxies";
import GetAScholarship from "./GetAScholarship";
import Obtaining from "./Obtaining";
import About from "./About";
import { questions } from "./questions";
import FAQ from "../../components/FAQ/FAQ";
import CallToAction from "../../components/CallToAction/CallToAction";
import Page from "../../components/Page/Page";
import PageSEO from "../../components/SEO/PageSEO";

const PersonalAxies = () => {
  return (
    <>
      <PageSEO
        title="Personal Axies | Axie.Tech"
        description="Personal Axies are your own unique NFTs (Non-Fungible Tokens) that can earn, breed and be traded on the Marketplace."
      />
      <Page container={false}>
        <About />
        <Obtaining />
        <BuyAxies />
        <GetAScholarship />
        <FAQ questions={questions} />
      </Page>

      <CallToAction
        title="Building your first team?"
        description="Check out our Axie Builds page for ideas!"
        buttonText="Axie Builds"
        buttonTo="/guides/builds"
        axie="puffy"
      />
    </>
  );
};

export default PersonalAxies;
