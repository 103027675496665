import React from "react";
import Text from "components/Typography/Text";
import { Stack } from "@mui/material";

const ClassChip = ({ axieClass, image, alt }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={0.5}
      sx={{
        borderRadius: 10,
        maxWidth: "100px",
        padding: 1,
      }}
    >
      <img width="50px" src={image} alt={alt} title={alt} />
      <Text textAlign="center" fontWeight="600" style={{ fontSize: "14px" }}>
        {axieClass}
      </Text>
    </Stack>
  );
};

export default ClassChip;
