import React from "react";
import Welcome from "./Welcome";
import Axies from "./Axies";
import HowToPlay from "./HowToPlay";
import Page from "../../components/Page/Page";
import PageSEO from "../../components/SEO/PageSEO";
import CommunityBanner from "pages/Home/CommunityBanner";

const NewPlayers = () => {
  return (
    <>
      <PageSEO
        title="New Players | Axie.Tech"
        description="The New Player Guide is a quick primer on everything you need to get started, plus an overview of the Axie ecosystem."
      />
      <Page container={false}>
        <Welcome />
        <Axies />
        <HowToPlay />
        <CommunityBanner />
      </Page>
    </>
  );
};

export default NewPlayers;
