import fonts from "./fonts";
import { colors } from "./colors";

const theme = {
  typography: {
    fontFamily: fonts.body,
    button: {
      textTransform: "lowercase",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: colors.background,
          scrollbarColor: `${colors.scrollbar.thumb} ${colors.scrollbar.background}`,
          "&::-moz-selection": {
            background: colors.secondary,
            color: colors.black,
          },
          "& ::selection": {
            background: colors.secondary,
            color: colors.black,
            "-webkit-text-fill-color": colors.black,
          },
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: colors.scrollbar.background,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: colors.scrollbar.thumb,
            minHeight: 24,
            border: `3px solid ${colors.scrollbar.background}`,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: colors.scrollbar.thumb,
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: colors.scrollbar.thumb,
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: colors.scrollbar.thumbHover,
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: colors.scrollbar.background,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 500,
          fontSize: "2.875rem",
          lineHeight: 1,
          fontFamily: fonts.changa,
          color: colors.typography.main,
        },
        h2: {
          fontWeight: 500,
          fontSize: "2.375rem",
          lineHeight: 1.1,
          fontFamily: fonts.changa,
          color: colors.typography.main,
        },
        h3: {
          fontWeight: 500,
          fontSize: "1.75rem",
          lineHeight: 1.2,
          fontFamily: fonts.changa,
          color: colors.typography.main,
        },
        h4: {
          fontWeight: 700,
          fontSize: "1.2rem",
          lineHeight: 1.2,
          fontFamily: fonts.changa,
          color: colors.primary,
        },
        h5: {
          fontWeight: 700,
          fontSize: "1.188rem",
          lineHeight: 1.2,
          color: colors.typography.main,
        },
        body1: {
          lineHeight: 1.6,
          fontSize: "1.188rem",
          fontWeight: 400,
          color: colors.typography.main,
        },
        body2: {
          lineHeight: 1.6,
          fontSize: "1rem",
          fontWeight: 400,
          color: colors.typography.main,
        },
        span: {
          fontSize: "1rem",
          fontWeight: 400,
          color: colors.typography.main,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          lineHeight: 1.4,
          background: "#191919",
          color: "#fff",
          fontWeight: "500",
          borderRadius: "8px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {},
    },
    MuiDialogTitle: {
      styleOverrides: {},
    },
    MuiFormHelperText: {
      styleOverrides: {},
    },
    MuiFormLabel: {
      styleOverrides: {},
    },
    MuiPagination: {
      styleOverrides: {},
    },
    MuiPaginationItem: {
      styleOverrides: {},
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          transition: "0.2s all ease",
          borderRadius: "8px",
          fontWeight: 500,
          "& .MuiOutlinedInput-notchedOutline": {
            transition: "0.2s all ease",
          },
          "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {},
          "&:hover, &:active, &.Mui-focused": {},
          "&:hover": {},
          "&:active, &.Mui-focused": {},
        },
        input: {
          "&:focus": {
            borderRadius: "8px",
          },
          "&::placeholder": {
            color: colors.primary,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: "1px solid transparent",
          borderRadius: "8px",
          fontWeight: 500,
          "&:hover": {},
          "&:active, &.Mui-focused": {},
          "&:hover:not(.Mui-disabled):before": {
            borderColor: "transparent",
          },
          "&::before": {
            borderColor: "transparent",
          },
          "&::after": {
            borderColor: "transparent",
          },
        },
        input: {
          borderColor: "transparent",
          "&:focus": {
            borderRadius: "8px",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {},
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
        label: {
          lineHeight: 1.2,
          position: "relative",
          top: 1,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {},
    },
    MuiPaper: {
      styleOverrides: {},
    },
    MuiPopover: {
      styleOverrides: {},
    },
    MuiMenuItem: {
      styleOverrides: {},
    },
    MuiTabs: {
      styleOverrides: {},
    },
    MuiSnackbar: {
      styleOverrides: {},
    },
    MuiSnackbarContent: {
      styleOverrides: {},
    },
    MuiTab: {
      styleOverrides: {},
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: colors.primary,
          textDecoration: "none",
          "&.MuiLink-underlineAlways": {
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
          "&:hover": {
            backgroundColor: "#1ad3b014",
            transform: "scale(1.15)",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {},
    },
    MuiTablePagination: {
      styleOverrides: {},
    },
    MuiDataGrid: {
      styleOverrides: {},
    },
    MuiDivider: {
      styleOverrides: {},
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          transition: "0.2s transform ease",
          background: colors.secondary,
          color: colors.primary,
          textTransform: "lowercase",
          border: "none",
          boxShadow: `3px 3px 0px ${colors.shadow}, inset 0px -4px 0px rgba(233, 84, 95, 0.2), inset 0px 3px 0px rgba(255, 255, 255, 0.3)`,
          fontWeight: "800",
          borderRadius: "8px",
          "&:hover": {
            background: colors.primary,
            color: colors.white,
            transform: "scale(1.04)",
            boxShadow: `3px 3px 0px ${colors.shadow}, inset 0px -4px 0px rgba(165, 59, 67, 0.24), inset 0px 3px 0px rgba(255, 255, 255, 0.17)`,
          },
          "&:active": {
            transform: "scale(0.99)",
          },
        },
        containedPrimary: {},
        containedSecondary: {},
        containedSizeSmall: {
          padding: "10px 16px",
          fontSize: "16px",
        },
        containedSizeMedium: {
          padding: "12px 20px",
          fontSize: "22px",
        },
        containedSizeLarge: {},
        outlined: {},
        outlinedPrimary: {},
        outlinedSecondary: {},
        outlinedSizeSmall: {},
        outlinedSizeMedium: {},
        outlinedSizeLarge: {},
        text: {
          whiteSpace: "nowrap",
          fontWeight: 600,
          padding: "4px 10px",
          textTransform: "uppercase",
          backgroundColor: "rgba(255, 96, 96, 0.04)",
          "&:hover": {
            backgroundColor: "rgba(250, 218, 95, 0.15)",
          },
        },
        textSizeSmall: {
          "& .MuiButton-endIcon svg": {
            height: 10,
            width: "auto",
          },
        },
        textSizeMedium: {},
        textSizeLarge: {},
        iconSizeSmall: {
          "& svg": {
            height: 12,
            width: "auto",
          },
        },
        iconSizeMedium: {
          "& svg": {
            height: 14,
            width: "auto",
          },
        },
        iconSizeLarge: {},
      },
    },
  },
  palette: {
    colors,
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.black,
    },
    text: {
      primary: colors.primary,
      secondary: colors.secondary,
      bright: colors.black,
      regular: colors.black,
      brown: colors.brown,
      darkBrown: colors.darkBrown,
      white: colors.white,
      darkBlue: colors.darkBlue,
      muted: colors.typography.muted,
    },
    background: {
      default: colors.background,
    },
  },
};

export default theme;
