import React from "react";
import CardTemplate from "../../components/GameTemplates/CardTemplate";
import { Stack, Container } from "@mui/material";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import { connect } from "react-redux";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import { getToolCards } from "../../store/data/dataSelectors";
import SectionHeader from "../../components/Layout/SectionHeader";
import { useTheme } from "@mui/system";
import CreatedWith from "./CreatedWith";
import NameAndCreated from "./NameAndCreated";

const Tools = ({ toolData }) => {
  const theme = useTheme();
  return (
    <Container>
      <Stack gap={4}>
        <SectionHeader>
          <Title newTag={true}>Tools</Title>
          <Text large={true} textAlign="center">
            Tool Cards are added to your deck. They come with a variety of
            different benefits.
          </Text>
        </SectionHeader>
        {toolData.tools == null ? (
          <></>
        ) : toolData.loading ? (
          <CustomLoader />
        ) : (
          <Stack gap={2}>
            {toolData.tools.map((item, i) => (
              <Stack
                key={`TOOL_${i}`}
                gap={4}
                direction="row"
                alignItems="center"
                sx={{
                  background: "#fff",
                  borderRadius: 3,
                  p: 2,
                  boxShadow: "0px 0px 8px #f0dcc0a8",
                  [theme.breakpoints.down("lg")]: {
                    flexDirection: "column",
                  },
                }}
              >
                <Stack
                  gap={4}
                  direction="row"
                  alignItems="center"
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      gap: 4,
                      alignItems: "flex-start",
                    },
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <NameAndCreated item={item} />
                  <CardTemplate
                    width={240}
                    card={item}
                    style={{
                      transition: "0.2s transform ease",
                      "&:hover": { transform: "scale(1.05)" },
                      mt: 1,
                    }}
                  />
                </Stack>

                <CreatedWith item={item} itemType="tool" />
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  toolData: getToolCards(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
