import { Box } from "@mui/material";
import React from "react";

const Card = ({ children, style }) => {
  return (
    <Box
      sx={{
        borderRadius: "24px",
        background: "#fff",
        boxShadow: "0 4px rgba(0,0,0,0.08), 0 0 10px #f4e8d869",
        p: 3,
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default Card;
