import * as types from "./dataActionTypes";

export const fetchCards = () => ({
  type: types.FETCH_DATA_CARDS,
});

export const fetchCardsStarted = () => ({
  type: types.FETCH_DATA_CARDS_STARTED,
});

export const fetchCardsFailed = () => ({
  type: types.FETCH_DATA_CARDS_FAILED,
});

export const fetchCardsSucceeded = (data) => ({
  type: types.FETCH_DATA_CARDS_SUCCEEDED,
  data,
});
