export const getCardRotation = (idx, numCards) => {
  let center;

  if (numCards % 2 === 0) {
    center = numCards / 2;

    return idx < center ? (idx - center + 1) * 2 : (idx - center) * 2;
  } else {
    center = (numCards - 1) / 2;

    return (idx - center) * 2;
  }
};

export const getCardTranslation = (idx, numCards) => {
  let center;

  if (numCards % 2 === 0) {
    center = numCards / 2;

    return idx < center ? (idx - center + 1) * -4 : (idx - center) * 4;
  } else {
    center = (numCards - 1) / 2;

    return idx < center ? (idx - center) * -4 : (idx - center) * 4;
  }
};

export const getOriginCardArtUrl = (cardImage) => {
  if (cardImage == null) return null;

  if (cardImage.startsWith("https://")) {
    return cardImage;
  }

  return `/images/templates/card/art/${cardImage.replace("png", "jpg")}`;
};
