import React from "react";
import { Box, Stack } from "@mui/material";
import Section from "./Section";

const IconSection = ({ children, img, alt }) => {
  return (
    <Stack
      direction="row"
      gap={4}
      flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
    >
      <Box
        sx={{
          img: { maxWidth: 100, m: "0 auto", display: "block" },
        }}
      >
        <img src={img} alt={alt} />
      </Box>
      <Section>{children}</Section>
    </Stack>
  );
};

export default IconSection;
