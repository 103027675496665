import { Stack, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Section from "../../components/Layout/Section";
import CharmTemplate from "../../components/GameTemplates/CharmTemplate";
import { connect } from "react-redux";
import Image from "../../components/Image/Image";
import { CURRENT_SEASON } from "config";
import EraInfo from "pages/Runes/EraInfo";

const CharmBasics = ({ charms }) => {
  const names = [
    "Charm of Power II",
    "Rocket Stamp",
    "Charm of Endurance II",
    "Energy Drink M",
    "Charm of Focus I",
    "Earth Stamp",
    "Charm of Faith II",
    "Scotch Tape",
  ];

  return (
    <Container>
      <Stack gap={4} sx={{ position: "relative" }}>
        <Section>
          <Title>Charm Basics</Title>

          <Text large={true}>
            Charms are special wearable items that provide bonus effects to Axie
            abilities. Charms come in 10 different classes, 1 for each Axie
            Class, plus a Neutral Class.
          </Text>
        </Section>

        <Stack
          direction="row"
          alignItems="center"
          gap={{ xs: 2, sm: 2, md: 0 }}
          flexWrap={{ xs: "wrap", sm: "wrap", md: "wrap", lg: "nowrap" }}
          justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
        >
          {charms != null &&
            names.map((name, i) => {
              const charm = charms.find(
                (c) => c.name === name && c.season === CURRENT_SEASON
              );

              if (charm) {
                return (
                  <CharmTemplate
                    charm={charm}
                    key={`CHARM_${i}`}
                    width={160}
                    style={{
                      mx: -1,
                      transition: "0.2s transform ease",
                      "&:hover": {
                        cursor: "pointer",
                        transform: "scale(1.3)",
                        zIndex: 10,
                      },
                      transform: `rotate(4deg)`,
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
        </Stack>

        <Text large={true}>Charms also come in 4 different rarities:</Text>

        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
          justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
        >
          <Image
            src="/images/pages/charms/common.png"
            alt="Common Rarity Charm"
            maxWidth={180}
            scale={1.1}
          />
          <Image
            src="/images/pages/charms/rare.png"
            alt="Rare Rarity Charm"
            maxWidth={180}
            scale={1.1}
          />
          <Image
            src="/images/pages/charms/epic.png"
            alt="Epic Rarity Charm"
            maxWidth={180}
            scale={1.1}
          />
          <Image
            src="/images/pages/charms/mystic.png"
            alt="Mystic Rarity Charm"
            maxWidth={180}
            scale={1.1}
          />
        </Stack>

        <EraInfo itemType="Charms" />
      </Stack>
    </Container>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  charms: state.data.charms,
});

export default connect(mapStateToProps, mapDispatchToProps)(CharmBasics);
