import React from "react";
import Text from "../../components/Typography/Text";
import { Stack } from "@mui/material";

const CardTags = () => {
  const tags = [
    { slug: "attack", description: "This Card does damage." },
    {
      slug: "power",
      description: "Enhance this Axie's abilities in future rounds.",
    },
    {
      slug: "innate",
      description: "Start each battle with this Card in your hand.",
    },
    {
      slug: "skill",
      description: "This is a utility Card.",
    },
    {
      slug: "retain",
      description: "Do not discard this Card at the end of your turn.",
    },
    {
      slug: "banish",
      description: "Remove the Card from your deck until the end of battle.",
    },
    {
      slug: "ethereal",
      description:
        "When your turn ends, if the Card is still in your hand, banish it.",
    },
    {
      slug: "secret",
      description: "This Card adds an effect that comes into play next turn.",
    },
    {
      slug: "unplayable",
      description: "This Card cannot be played.",
    },
  ];
  return (
    <Stack gap={2}>
      {tags.map((tag, i) => (
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{ img: { width: 150, borderRadius: "16px" } }}
          key={`CARD_TAG_${i}`}
        >
          <img
            src={`/images/pages/gameplay-guide/tags/${tag.slug}.jpg`}
            alt={tag.slug}
          />
          <Text>{tag.description}</Text>
        </Stack>
      ))}
    </Stack>
  );
};

export default CardTags;
