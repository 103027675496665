import { Stack } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Info from "../../components/Info/Info";
import Image from "../../components/Image/Image";
import Section from "../../components/Layout/Section";
import { useTheme } from "@mui/system";

const WhatIsAdventure = () => {
  const theme = useTheme();
  return (
    <Stack gap={4}>
      <Section>
        <Title>What is Adventure Mode?</Title>
        <Text large={true}>
          Adventure Mode is the player vs. environment (PvE) gameplay mode in
          Axie Infinity. As the storyline unfolds, you'll battle waves of
          Chimera, level up your Axies and collect rewards.
        </Text>

        <Text large={true}>
          The Adventure Mode storyline is comprised of different Chapters. Each
          Chapter has 7 Big Stages and 12 Small Stages. Collect stars in Big
          Stages by completing the specific Challenges listed at the beginning
          of the Stage.
        </Text>
      </Section>

      <Image
        src="/images/pages/adventure/map.jpg"
        alt="Adventure Map"
        plain={true}
      />

      <Info
        style={{
          maxWidth: 500,
          ml: "auto",
          mt: -8,
          [theme.breakpoints.down("sm")]: {
            mt: 0,
          },
        }}
      >
        Stars are used for unlocking Milestone Rewards and improving the quality
        of Foraging rewards.
      </Info>
    </Stack>
  );
};

export default WhatIsAdventure;
