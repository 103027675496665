import { Stack, Grid, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Info from "../../components/Info/Info";
import Image from "../../components/Image/Image";

const EquippingRunes = () => (
  <Container>
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 0 }}>
        <Image
          src="/images/pages/runes/equipping-runes.png"
          alt="Axies with Runes"
          borderRadius={3}
          maxWidth={400}
          style={{ m: "0 auto" }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} order={{ xs: 0, sm: 0, md: 1 }}>
        <Stack gap={1}>
          <Title>Equipping Runes</Title>
          <Text large={true}>
            Each axie can be equipped with one rune of neutral or same class as
            the axie body. For example, a Beast Axie can equip 1 Beast Rune or 1
            Neutral Rune.
          </Text>
          <Text large={true}>
            To equip a Rune, open the game and go to an Axie's profile page. Tap
            on the empty Rune slot to browse your Inventory and select your
            desired Rune.
          </Text>
          <Info style={{ mt: 2 }}>
            You can collect and store multiple copies of the same Rune in your
            Inventory.
          </Info>
        </Stack>
      </Grid>
    </Grid>
  </Container>
);

export default EquippingRunes;
