import React from "react";
import { Stack } from "@mui/material";

const SectionHeader = ({ children, style }) => {
  return (
    <Stack
      gap={1}
      alignItems="center"
      sx={{ maxWidth: 480, m: "0 auto", ...style }}
    >
      {children}
    </Stack>
  );
};

export default SectionHeader;
