import { Box, Button, Container, Stack } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import { useTheme } from "@mui/system";
import Image from "../../components/Image/Image";
import SubTitle from "../../components/Typography/SubTitle";
import { HashLink } from "react-router-hash-link";

const Modes = () => {
  const theme = useTheme();

  return (
    <Box
      id="arena-modes"
      gap={5}
      sx={{
        py: 8,
        px: 2,
        background: theme.palette.colors.wood.background,
        position: "relative",
        backgroundAttachment: "attached",
      }}
    >
      <Image
        src="/images/accents/pattern-decorative.png"
        alt="Decorative pattern"
        style={{ position: "absolute", top: 88, img: { zIndex: 1 } }}
      />

      <Container sx={{ position: "relative", zIndex: 2 }}>
        <Stack gap={7}>
          <Title textAlign="center" color="#fff">
            Arena Modes
          </Title>

          <Stack
            flexDirection="row"
            flexWrap={{ xs: "wrap", sm: "wrap", md: "wrap", lg: "nowrap" }}
            justifyContent="center"
            gap={6}
          >
            <Mode title="Casual Mode" img="practice" hashTo="#practice-mode">
              <Text textAlign="center">
                Casual battles are a great way to learn the game or try new
                strategies. Practice Mode does not have a ranking system.
              </Text>
            </Mode>

            <Mode title="Ranked Mode" img="ranked" hashTo="#ranked-mode">
              <Text textAlign="center">
                Ranked Mode has the same battle system as Casual Mode plus a
                ranking system. To climb the rankings, players win battles and
                earn Victory Stars.
              </Text>
            </Mode>

            <Mode
              title="Tournament Mode"
              img="tournament"
              hashTo="#tournament-mode"
            >
              <Text textAlign="center">
                Tournaments are held periodically and are an opportunity for
                players to test their Arena skills, compete, and win prizes.
              </Text>
            </Mode>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

const Mode = ({ title, children, img, hashTo }) => {
  const theme = useTheme();

  return (
    <Stack gap={2} sx={{ flexBasis: "33%" }}>
      <Box
        sx={{
          alignSelf: "center",
          img: {
            width: 200,
          },
        }}
      >
        <img src={`/images/pages/arena/${img}.png`} alt={title} />
      </Box>
      <SubTitle textAlign="center" color="#fff">
        {title}
      </SubTitle>
      <Stack
        sx={{
          "& .MuiTypography-root": { color: theme.palette.colors.wood.text },
        }}
      >
        {children}
      </Stack>
      <Button
        component={HashLink}
        to={hashTo}
        variant="text"
        sx={{ alignSelf: "center" }}
      >
        Learn More
      </Button>
    </Stack>
  );
};

export default Modes;
