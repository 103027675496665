import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { FaPlay } from "react-icons/fa";
import SubTitle from "../../components/Typography/SubTitle";
import Text from "../../components/Typography/Text";
import Title from "../../components/Typography/Title";
import SectionHeader from "../../components/Layout/SectionHeader";
import { Link as RouterLink } from "react-router-dom";
import CustomLink from "../../components/CustomLink/CustomLink";
import { connect } from "react-redux";
import CardTemplate from "../../components/GameTemplates/CardTemplate";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import Image from "../../components/Image/Image";

const CardsPowerUps = ({ cards }) => {
  const theme = useTheme();
  return (
    <Stack gap={6}>
      <SectionHeader>
        <Title>Cards &amp; Power-Ups</Title>
        <Text large={true} textAlign="center">
          An Axie's unique abilities are determined by its
          <CustomLink to="/cards">Cards</CustomLink> and enhanced using
          <CustomLink to="/runes">Runes</CustomLink> and
          <CustomLink to="/charms" endSpace={false}>
            Charms
          </CustomLink>
          .
        </Text>
      </SectionHeader>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
          gridGap: "24px",
          [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1.5fr 1fr",
          },
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <Cards cards={cards} />

        <Stack gap={2} justifyContent="space-between" sx={{ height: "100%" }}>
          <InfoSection
            title="Runes"
            description="Runes are special items that can be equipped in an Axie's Rune Slot to
              provide passive effects."
            imageSlugs={["rune-1", "rune-2", "rune-3", "rune-4"]}
            to="/runes"
            iconSlug="rune-icon"
          />

          <InfoSection
            title="Charms"
            description="Charms are special items that can be installed into an Axie's Card slots
        to enhance its abilities."
            imageSlugs={["charm-1", "charm-2", "charm-3", "charm-4"]}
            to="/charms"
            iconSlug="charm-icon"
          />
        </Stack>
      </Box>
    </Stack>
  );
};

const Cards = ({ cards }) => {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.down("lg"));
  const medium = useMediaQuery(theme.breakpoints.down("md"));
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const ids = [
    "eyes-clear",
    "back-tri-spikes",
    "ears-clover",
    "mouth-cute-bunny",
    "horn-pocky",
    "tail-the-last-one",
    "tail-iguana",
    "back-bidens",
    "mouth-doubletalk",
    "back-ronin",
    "ears-larva",
    "horn-babylonia",
    "tail-hot-butt",
    "back-scarab",
    "eyes-scar",
    "tail-cottontail",
    "mouth-risky-fish",
    "eyes-cucumber-slice",
  ];

  return (
    <Wrapper>
      <Stack gap={1}>
        <SubTitle
          icon={
            <Image
              maxWidth={40}
              src={`/images/pages/home/card-icon.png`}
              alt="Cards"
              to="/cards"
            />
          }
        >
          Cards
        </SubTitle>

        <Stack
          direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
          alignItems={{
            xs: "flex-end",
            sm: "flex-end",
            md: "flex-end",
            lg: "center",
          }}
          gap={{ xs: 2, sm: 2, md: 3 }}
        >
          <Text>
            Each Axie has 6 different Cards that determine its abilities in
            Battle. Axies get 1 Card per body part.
          </Text>

          <Button
            variant="contained"
            endIcon={<FaPlay />}
            size="small"
            sx={{ minWidth: 150 }}
            to="/cards"
            component={RouterLink}
          >
            Learn More
          </Button>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        gap={0.5}
        sx={{
          mt: 0.5,
        }}
      >
        {ids.map((id, i) => {
          const card = cards != null && cards.find((c) => c.partId === id);

          if (
            card &&
            ((!small && !medium && !large) ||
              (i < 6 && large) ||
              (i < 6 && small))
          ) {
            return (
              <CardTemplate
                card={card}
                key={`CARD_${i}`}
                width={small ? 120 : medium ? 126 : large ? 126 : 100}
                style={{
                  transition: "0.2s transform ease",
                  "&:hover": {
                    transform: "scale(1.3)",
                    zIndex: 10,
                  },
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </Stack>
    </Wrapper>
  );
};

const InfoSection = ({ title, description, iconSlug, to, imageSlugs }) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <Stack gap={3}>
        <Stack gap={1}>
          <SubTitle
            icon={
              <Image
                maxWidth={40}
                src={`/images/pages/home/${iconSlug}.png`}
                alt={title}
                to={to}
              />
            }
          >
            {title}
          </SubTitle>
          <Text>{description}</Text>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          sx={{
            img: {
              transition: "0.2s transform ease",
              "&:hover": {
                transform: "scale(1.2)",
              },
            },
          }}
        >
          {imageSlugs.map((slug, i) => (
            <Image
              key={i}
              src={`/images/pages/home/${slug}.png`}
              alt={title}
              maxWidth={70}
              style={{
                [theme.breakpoints.down("lg")]: {
                  maxWidth: 56,
                },
                [theme.breakpoints.down("md")]: {
                  maxWidth: 90,
                },
                [theme.breakpoints.down("sm")]: {
                  maxWidth: 66,
                },
              }}
            />
          ))}
        </Stack>
      </Stack>

      <Button
        variant="contained"
        endIcon={<FaPlay />}
        size="small"
        sx={{ alignSelf: "flex-end" }}
        to={to}
        component={RouterLink}
      >
        Learn More
      </Button>
    </Wrapper>
  );
};

const Wrapper = ({ children }) => (
  <Stack
    sx={{
      p: 1,
      borderRadius: "24px",
      background: "#FFF2DF",
      boxShadow: "4px 4px #f9e3c4",
      flexGrow: 1,
    }}
  >
    <Stack
      gap={2}
      justifyContent="space-between"
      sx={{
        p: 2,
        borderRadius: "20px",
        background: "#fff",
        height: "100%",
      }}
    >
      {children}
    </Stack>
  </Stack>
);

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({ cards: state.data.cards });

export default connect(mapStateToProps, mapDispatchToProps)(CardsPowerUps);
