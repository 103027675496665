import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import PageTitle from "../../components/Typography/PageTitle";
import Paper from "../Paper/Paper";

const PageHeader = ({ img, height, title, text, contents }) => {
  const theme = useTheme();
  return (
    <Box sx={{ px: 2 }}>
      <Box
        sx={{
          backgroundImage: `url('${img}')`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height,
          position: "relative",
          zIndex: 3,
          borderRadius: "32px",
          boxShadow: "inset 0px 0px 6px #d2c0b5",
          maxWidth: 1600,
          m: "0 auto",
          mb: title != null ? 8 : 0,
          [theme.breakpoints.down("md")]: {
            height: height / 2,
          },
          [theme.breakpoints.down("sm")]: {
            height: height / 3,
          },
        }}
      >
        {title != null && (
          <Paper
            vertical={false}
            style={{
              position: "absolute",
              bottom: "0%",
              left: "50%",
              transform: "translateX(-50%) translateY(50%)",
              maxWidth: 540,
              boxShadow: `0 4px #bea79b`,
              [theme.breakpoints.down("md")]: {
                width: "90%",
              },
              [theme.breakpoints.down("sm")]: {
                p: "4px",
                transform: "translateX(-50%) translateY(60%)",
              },
            }}
            middleStyle={{
              [theme.breakpoints.down("sm")]: { borderRadius: "28px" },
            }}
          >
            <Stack gap={1} alignItems="center">
              <PageTitle color="text.brown">{title}</PageTitle>
              <Typography
                textAlign="center"
                color="text.darkBrown"
                fontWeight={600}
                variant="span"
                lineHeight={1.45}
                fontSize={18}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 16,
                  },
                }}
              >
                {text}
              </Typography>
              {contents}
            </Stack>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default PageHeader;
