import { Grid, Stack } from "@mui/material";
import React from "react";
import Title from "components/Typography/Title";
import SubTitle from "components/Typography/SubTitle";
import Text from "components/Typography/Text";
import Section from "components/Layout/Section";
import CustomLink from "components/CustomLink/CustomLink";
import List from "components/List/List";
import ListItem from "components/List/ListItem";
import { INSPECTOR_URL } from "config";
import Image from "../../components/Image/Image";
import Card from "../../components/Card/Card";

const PotentialPoints = () => {
  return (
    <Grid container spacing={6} id="potential-points">
      <Grid item xs={12} sm={12} md={7}>
        <Section>
          <Title>Potential Points</Title>
          <Text large={true}>
            Axies have a total of 15 Potential Points. Each axie part provides 2
            class Potential Points. The axie's body provides 3 class Potential
            Points.
          </Text>
          <Text large={true}>
            The number of Potential Points an axie has for each class influences
            the <CustomLink to="/charms">Charms</CustomLink> that it can equip.
          </Text>
          <Text large={true}>
            As an example, let's say we have an Axie with a Reptile type body, 1
            Reptile body part, 3 bug body parts,and 2 Aquatic body parts.
          </Text>
          <Card>
            <Stack direction="row" alignItems="center" gap={2} sx={{ my: 1 }}>
              <Image
                src="/images/pages/what-are-axies/reptile-axie-right.png"
                alt="Reptile axie"
                maxWidth={100}
                card={false}
                boxShadow={false}
              />
              <Text fontWeight={800} style={{ fontSize: 14 }}>
                My Potential Points in different classes allow me to equip
                Charms!
              </Text>
            </Stack>
          </Card>

          <Text large={true}>This Axie's Potential Points would be:</Text>

          <List>
            <ListItem title="Reptile Body + 1 Reptile Parts">
              5 Reptile Potential Points
            </ListItem>
            <ListItem title="3 Bug Body Parts">6 Bug Potential Points</ListItem>
            <ListItem title="2 Aquatic Body Parts">
              4 Aquatic Potential Point
            </ListItem>
          </List>
        </Section>
      </Grid>

      <Grid item xs={12} sm={12} md={5}>
        <Stack
          gap={3}
          direction={{
            xs: "column-reverse",
            sm: "column-reverse",
            md: "column",
          }}
        >
          <SubTitle>Potential Points &amp; Charms</SubTitle>
          <Text large={true}>
            A Charm requires Potential Points of a matching Class.
            <CustomLink to="/charms">Charms</CustomLink> reduce an Axie's
            available Potential Points when they are allocated.
          </Text>

          <Text large={true}>
            For example, to equip an Aquatic Charm, an Axie must have Aquatic
            Potential Points. Once this Charm is equipped, the Axie's total
            available Aquatic Potential Points will be reduced. This is called
            the Potential Cost of a Charm.
          </Text>
          <Text large={true}>
            Wondering how many Potential Points your Axie has? Use the
            <CustomLink href={INSPECTOR_URL}>Axie Inspector</CustomLink> to find
            out!
          </Text>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PotentialPoints;
