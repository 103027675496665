import { Stack, Grid, Typography } from "@mui/material";
import React from "react";
import Text from "components/Typography/Text";
import ClassIcon from "components/ClassIcon/ClassIcon";
import NamePlate from "components/ExploreAxies/NamePlate";

const Axie = ({ axie }) => (
  <Grid item xs={12} sm={12} md={6} lg={4} order={{ md: 0, lg: 1 }}>
    <Stack gap={8} sx={{ position: "relative", maxWidth: 340, m: "0 auto" }}>
      <img
        src={`/images/starter-axies/${axie.slug}.png`}
        alt={`${axie.name} - Starter Axie`}
      />
      <ClassIcon
        axieClass={axie.class}
        width={60}
        style={{ position: "absolute", right: 0, top: -30, zIndex: 2 }}
      />
      <Stack gap={3}>
        <NamePlate>
          <Typography variant="h1">{axie.name}</Typography>
        </NamePlate>
        <Text textAlign="center" fontWeight={800}>
          {axie.perk}
        </Text>
      </Stack>
    </Stack>
  </Grid>
);

export default Axie;
