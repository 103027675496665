import React from "react";
import Page from "../../components/Page/Page";
import { Typography, Button, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { FaPlay } from "react-icons/fa";

const Page404 = () => {
  return (
    <>
      <Page>
        <Stack gap={4} alignItems="flex-start">
          <Stack gap={2}>
            <Typography variant="h1" color="text.bright">
              Wer content?
            </Typography>
            <Typography variant="body1" color="text.regular">
              We searched high and low but couldn't find the page you were
              looking for.
            </Typography>
          </Stack>
          <Button
            component={RouterLink}
            to="/"
            variant="contained"
            endIcon={<FaPlay />}
          >
            Go Home
          </Button>
        </Stack>
      </Page>
    </>
  );
};

export default Page404;
