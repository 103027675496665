import { Box, Stack } from "@mui/material";
import React from "react";
import Text from "../../../components/Typography/Text";
import SubTitle from "../../../components/Typography/SubTitle";
import Image from "../../../components/Image/Image";
import { FaPlay } from "react-icons/fa";
import { useTheme } from "@mui/system";
import CustomLink from "../../../components/CustomLink/CustomLink";
import Title from "../../../components/Typography/Title";

const ListOfAxies = () => {
  const theme = useTheme();
  const axies = [
    {
      name: "Origin Axies",
      img: "origin",
      description:
        "Origin Axies were sold from February to April 2018. Owning an Origin Axie is a marker of being an Axie OG.",
      total: "~3,400",
      href: "https://marketplace.axieinfinity.com/axie/?title=Origin&mystic=0",
    },
    {
      name: "Mystic Axies",
      img: "mystic",
      description:
        "Origin Axies with at least 1 Mystic Part are called Mystic Axies. Mystic Parts have unique aesthetics compared to their normal variants.",
      total: "~1,200",
      href: "https://marketplace.axieinfinity.com/axie/?mystic=1&mystic=2&mystic=3&mystic=4&mystic=5&mystic=6",
    },
    {
      name: "Holiday Axies",
      img: "holiday",
      description:
        "Holiday Axies originated from special breeding events held in Winter 2018 and Winter 2019. These Axies have Christmas parts, some of which are very rare or animated.",
      total: "~3,000",
      href: "https://marketplace.axieinfinity.com/axie/?xmas=1&xmas=2&xmas=3&xmas=4&xmas=5&xmas=6",
    },
    {
      name: "Japanese Axies",
      img: "japanese",
      description:
        "Prior to the migration to Ronin, it used to be possible to breed Axies with special Japanese-themed parts.",
      total: "~16,500",
      href: "https://marketplace.axieinfinity.com/axie/?region=japan",
    },
    {
      name: "MEO Axies",
      img: "meo",
      description:
        "MEO Axies are used to introduce new body parts. We are currently in MEO Season 2; the Season 1 supply has been capped. MEO Axies do not require SLP to breed.",
      total: "~2,500",
      href: "https://marketplace.axieinfinity.com/axie/?title=MEO-Corp&title=MEO-Corp-II",
    },
    {
      name: "Agamogenesis Axies",
      img: "agamogenesis",
      description:
        "Agamogenesis Axies have 1/1 skin and animated body parts. There are only 3 Agamogenesis Axies in existence.",
      total: "3",
      href: "https://marketplace.axieinfinity.com/axie/?title=AgamoGenesis&auctionTypes=Sale",
    },
  ];
  return (
    <Stack gap={8}>
      <Title textAlign="center">Types of Rare Axies</Title>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={4}
        justifyContent="space-between"
        sx={{
          [theme.breakpoints.down("md")]: {
            justifyContent: "center",
          },
        }}
      >
        {axies.map((axie, i) => (
          <Axie axie={axie} key={`AXIE_${i}`} />
        ))}
      </Stack>
    </Stack>
  );
};

const Axie = ({ axie }) => {
  const theme = useTheme();
  return (
    <CustomLink
      href={axie.href}
      style={{ "&:hover": { textDecoration: "none" } }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        sx={{
          width: 360,
          p: 1,
          pb: 2,
          height: "100%",
          border: "1px solid #eee",
          borderRadius: 2,
          transition: "0.2s ease all",
          position: "relative",
          "&:hover": {
            transform: "scale(1.1)",
            borderColor: theme.palette.colors.black,
            boxShadow: "0px 0px 8px #f0dcc0a8",
            "& .browse": {
              opacity: 1,
              width: "100px",
            },
            "& .total": {
              borderRadius: "8px 0 0 8px",
            },
          },
        }}
      >
        <Stack alignItems="center" gap={2}>
          <Image
            src={`/images/pages/guides/collectors/${axie.img}.jpg`}
            alt={axie.name}
            maxWidth={200}
          />

          <Stack gap={0.5} alignItems="center">
            <SubTitle textAlign="center">{axie.name}</SubTitle>
            <Text textAlign="center">{axie.description}</Text>
          </Stack>
        </Stack>

        <Stack direction="row">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            className="total"
            gap={1}
            sx={{
              background: theme.palette.colors.black,
              p: "4px 8px",
              borderRadius: "8px",
              height: "100%",
            }}
          >
            <Text color="#fff">Total:</Text>
            <Text fontWeight={800} color="#fff">
              {axie.total}
            </Text>
          </Stack>
          <Box
            className="browse"
            sx={{ width: 0, transition: "0.3s all ease", overflow: "hidden" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
              sx={{
                background: "#ff606038",
                p: "4px 8px",
                borderRadius: "0 8px 8px 0",
                height: "100%",
                textTransform: "uppercase",
                svg: {
                  height: 12,
                  width: "auto",
                },
              }}
            >
              <Text fontWeight={800} style={{ fontSize: 13 }}>
                Browse
              </Text>
              <FaPlay />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </CustomLink>
  );
};

export default ListOfAxies;
