import React from "react";
import Text from "../../../components/Typography/Text";
import Title from "../../../components/Typography/Title";
import { Grid, Stack } from "@mui/material";
import Section from "../../../components/Layout/Section";
import Image from "../../../components/Image/Image";
import List from "../../../components/List/List";
import ListItem from "../../../components/List/ListItem";

const Intro = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={12} md={7} order={{ xs: 1, sm: 1, md: 1, lg: 0 }}>
        <Section>
          <Title>It's WooHoo time!</Title>
          <Text large={true}>
            Just like real-world pets, Axies can breed to create more Axies. In
            this Guide, you'll learn how to breed your Axies and what resources
            are required. Let's dive in!
          </Text>

          <Text large={true} fontWeight={800}>
            Breeding Limitations:
          </Text>
          <List>
            <ListItem>Axies can breed a maximum of 7 times.</ListItem>
            <ListItem>
              Sibling Axies and parents/offspring Axies cannot breed.
            </ListItem>
            <ListItem>
              Breeding costs vary depending on how many times the parent Axies
              have been bred.
            </ListItem>
          </List>

          <Text color="text.muted" style={{ fontSize: 12 }}>
            Breeding is risky and there are many factors to consider. Please do
            your own research and do not depend on this guide or the tools
            referenced within to make financial decisions.
          </Text>
        </Section>
      </Grid>

      <Grid item xs={12} sm={12} md={5} order={{ xs: 0, sm: 0, md: 0, lg: 1 }}>
        <Stack gap={1} alignItems="center">
          <Image
            src="/images/pages/guides/breeding/breeding.jpg"
            alt="An Axie with eggs"
            maxWidth={500}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Intro;
