import { Grid } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Info from "../../components/Info/Info";
import Image from "../../components/Image/Image";
import Section from "../../components/Layout/Section";

const Foraging = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <Image
          src="/images/pages/adventure/foraging-rewards.jpg"
          alt="Foraging Rewards"
          plain={true}
          maxWidth={500}
          style={{ m: "0 auto" }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={7}>
        <Section
          info={
            <Info>
              If you haven't collected your Foraging rewards for 12 hours, no
              new items will be added until you collect the existing ones.
            </Info>
          }
        >
          <Title newTag={true}>Foraging</Title>
          <Text large={true}>
            Axies will sometimes collect items on their adventures and put them
            in the Foraging Box, which you can open from the Adventure Mode map.
          </Text>
          <Text large={true}>
            Be sure to check your Foraging Box often! Resources like EXP and
            Moon Shards accumulate over time, even when you aren't playing. And,
            the more total Stars you've collected, the better the foraging
            rewards!
          </Text>
        </Section>
      </Grid>
    </Grid>
  );
};

export default Foraging;
