import { Stack, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Section from "../../components/Layout/Section";
import RuneTemplate from "components/GameTemplates/RuneTemplate";
import { connect } from "react-redux";

const StarterRunes = ({ runes }) => {
  const starterRuneIds = [
    "rune_aquatic_puffy_1",
    "rune_beast_buba_1",
    "rune_beast_tripp_1",
    "rune_bird_momo_1",
    "rune_bug_pomodoro_1",
    "rune_plant_ena_1",
    "rune_plant_olek_1",
    "rune_reptile_venoki_1",
    "rune_beast_xia_1",
    "rune_beast_bing_1",
    "rune_aquatic_noir_1 ",
    "rune_aquatic_rouge_1 ",
  ];

  const starterRunes = runes.filter(
    (rune) => starterRuneIds.includes(rune.itemId) && rune.season === "Unknown"
  );

  return (
    <Container>
      <Stack gap={4}>
        <Section>
          <Title>Starter Runes</Title>
          <Text large={true}>
            Starter Runes are non-NFT runes. They can be earned by making
            progress in the game, such as playing ranked Arena games, or through
            daily login rewards. Equip your Starter Runes to your Starter Axies
            and see what effects you can create!{" "}
          </Text>
        </Section>

        <Stack
          direction="row"
          alignItems="center"
          gap={{ xs: 2, sm: 2, md: 4 }}
          flexWrap="wrap"
          justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
        >
          {starterRunes.map((rune, i) => (
            <RuneTemplate width={180} key={`RUNE_${i}`} rune={rune} />
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  runes: state.data.runes,
});

export default connect(mapStateToProps, mapDispatchToProps)(StarterRunes);
