import React from "react";
import SubTitle from "components/Typography/SubTitle";

const Label = ({ children, textAlign }) => (
  <SubTitle fontSize={24} textAlign={textAlign}>
    {children}
  </SubTitle>
);

export default Label;
