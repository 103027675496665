import React from "react";
import Page from "../../components/Page/Page";
import WhatIsArena from "./WhatIsArena";
import SeasonsRewards from "./SeasonsRewards";
import Leaderboard from "./Leaderboard";
import Modes from "./Modes";
import PageSEO from "../../components/SEO/PageSEO";
import RankedMode from "./RankedMode/RankedMode";
import PracticeMode from "./PracticeMode";
import TournamentMode from "./TournamentMode";
import CallToAction from "../../components/CallToAction/CallToAction";

const Arena = () => {
  return (
    <>
      <PageSEO
        title="Arena | Axie.Tech"
        description="In Arena, one of the two gameplay modes in Axie Infinity, you'll battle against other players and their teams
        of Axies."
      />
      <Page
        headerImg="/images/banners/arena.jpg"
        headerHeight={500}
        title="Arena"
        headerText="In Arena mode, you'll battle against other players and their teams
        of Axies."
        container={false}
      >
        <WhatIsArena />
        <Modes />
        <PracticeMode />
        <RankedMode />
        <Leaderboard />
        <TournamentMode />
        <SeasonsRewards />
      </Page>
      <CallToAction
        title="Arena Pro?"
        description="Check out the upcoming Esports event schedule and register for a tournament!"
        buttonText="Learn More"
        buttonTo="/esports"
      />
    </>
  );
};

export default Arena;
