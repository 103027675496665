import React from "react";
import CustomLink from "../../../components/CustomLink/CustomLink";
import { MARKETPLACE_URL } from "../../../config";
import { Box, Stack, Container, useMediaQuery } from "@mui/material";
import Image from "../../../components/Image/Image";
import { useTheme } from "@mui/system";
import CardTemplate from "../../../components/GameTemplates/CardTemplate";
import TextBubble from "./TextBubble";
import { connect } from "react-redux";

const Axies = ({ cards }) => {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const axies1 = (
    <>
      <CustomLink to="/what-are-axies">Axies</CustomLink> are fascinating,
      adorable creatures that you can collect, battle and breed. In order to
      play the game, you'll need a team of 3 Axies.
    </>
  );

  const axies2 = (
    <>
      <CustomLink to="/starter-axies">Starter Axies</CustomLink> are available
      to all players for free, as soon as you arrive in Lunacia.
      <CustomLink to="/personal-axies">Personal Axies</CustomLink> can be
      acquired on the{" "}
      <CustomLink href={MARKETPLACE_URL} endSpace={false}>
        Marketplace
      </CustomLink>
      .
    </>
  );

  return (
    <Stack>
      {lgDown ? (
        <SmallView bubble1={axies1} bubble2={axies2} />
      ) : (
        <LargeView bubble1={axies1} bubble2={axies2} />
      )}

      <Cards cards={cards} />
    </Stack>
  );
};

const SmallView = ({ bubble1, bubble2 }) => {
  const theme = useTheme();
  return (
    <Stack gap={2} sx={{ position: "relative" }}>
      <Image
        src="/images/pages/gameplay-guide/axies.png"
        alt="Axies"
        maxWidth={400}
        style={{
          mx: "auto",
        }}
        id="axies"
      />

      <Stack
        gap={1}
        alignItems="center"
        sx={{
          [theme.breakpoints.down("md")]: {
            mb: -18,
          },
          [theme.breakpoints.down("sm")]: {
            mb: -4,
          },
        }}
      >
        <TextBubble maxWidth={450}>{bubble1}</TextBubble>
        <TextBubble maxWidth={450}>{bubble2}</TextBubble>
      </Stack>

      <Image
        src="/images/banners/welcome.jpg"
        alt="Axies"
        style={{
          mx: "auto",
        }}
      />

      <Buttons
        style={{
          position: "absolute",
          right: "50%",
          transform: "translateX(50%)",
          img: {
            width: 200,
          },
          [theme.breakpoints.down("sm")]: {
            bottom: "-12%",
          },
        }}
      />
    </Stack>
  );
};

const LargeView = ({ bubble1, bubble2 }) => {
  return (
    <Box
      sx={{
        backgroundImage: "url('/images/banners/welcome.jpg')",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: 800,
        borderTopRightRadius: 24,
        borderTopLeftRadius: 24,
      }}
    >
      <Container fixed sx={{ position: "relative", height: "100%" }}>
        <Image
          src="/images/pages/gameplay-guide/axies.png"
          alt="Axies"
          maxWidth={400}
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            zIndex: 2,
          }}
          id="axies"
        />

        <TextBubble maxWidth={450} left="12%" top="13%">
          {bubble1}
        </TextBubble>

        <TextBubble maxWidth={520} right="27%" bottom="5%">
          {bubble2}
        </TextBubble>

        <Buttons />
      </Container>
    </Box>
  );
};

const Buttons = ({ style }) => (
  <Stack
    gap={2}
    direction={{ xs: "column", sm: "column", md: "row" }}
    sx={{
      position: "absolute",
      bottom: "-4%",
      right: 0,
      width: 500,
      ...style,
    }}
  >
    <Image
      src="/images/pages/gameplay-guide/about-axies.jpg"
      alt="Learn more about Axies"
      scale={1.2}
      borderRadius={1}
      to="/what-are-axies"
    />
    <Image
      src="/images/pages/gameplay-guide/team-builds.jpg"
      alt="Build your first team"
      scale={1.2}
      borderRadius={1}
      to="/guides/builds"
    />
  </Stack>
);

const Cards = ({ cards }) => {
  const theme = useTheme();
  const ids = [
    "eyes-blossom",
    "ears-clover",
    "back-bidens",
    "mouth-zigzag",
    "horn-cactus",
    "tail-hot-butt",
  ];

  return (
    <Box
      sx={{
        background: theme.palette.colors.wood.background,
        py: 8,
        [theme.breakpoints.down("lg")]: {
          py: 16,
        },
        [theme.breakpoints.down("sm")]: {
          pt: 20,
        },
      }}
    >
      <Image
        src="/images/accents/pattern-decorative.png"
        alt="Decorative pattern"
        style={{ mb: -10 }}
      />
      <Container sx={{ position: "relative" }}>
        <TextBubble maxWidth={450}>
          Each Axie has 6 <CustomLink to="/cards">Cards</CustomLink> that
          determine which abilities it can use in battle.
        </TextBubble>

        <Stack gap={{ xs: 10, sm: 10, md: 18 }}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={3}
              sx={{
                mt: 8,
                [theme.breakpoints.down("lg")]: {
                  flexWrap: "wrap",
                  mb: 3,
                },
              }}
            >
              {ids.map((id, i) => {
                const card =
                  cards != null && cards.find((c) => c.partId === id);

                if (card) {
                  return (
                    <Stack gap={4} key={`CARD_${i}`}>
                      <CardTemplate
                        card={card}
                        width={180}
                        style={{
                          transition: "0.2s transform ease",
                          "&:hover": {
                            cursor: "pointer",
                            transform: "scale(1.1)",
                            zIndex: 10,
                          },
                        }}
                      />
                      {i === 4 ? (
                        <Image
                          src="/images/pages/gameplay-guide/charm.jpg"
                          alt="Charm"
                          maxWidth={180}
                          scale={1.1}
                        />
                      ) : (
                        <Image
                          src="/images/pages/gameplay-guide/charm-slot.jpg"
                          alt="Charm slot"
                          maxWidth={180}
                        />
                      )}
                    </Stack>
                  );
                } else {
                  return null;
                }
              })}
            </Stack>

            <TextBubble
              right="40%"
              bottom="-14%"
              maxWidth={419}
              styleSmall={{ position: "static" }}
            >
              Each Card has 1 Charm slot.
              <CustomLink to="/charms">Charms</CustomLink> enhance an Axie's
              abilities.
            </TextBubble>
          </Box>

          <Stack
            direction="row"
            gap={8}
            alignItems="center"
            justifyContent="center"
            sx={{
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
              },
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Image
                src="/images/pages/gameplay-guide/rune-slot.png"
                alt="Rune slot"
                maxWidth={600}
              />
              <Image
                src="/images/pages/gameplay-guide/rune.png"
                alt="Rune"
                scale={1.1}
                style={{
                  position: "absolute",
                  right: "5%",
                  bottom: 0,
                  width: "21%",
                }}
              />
            </Box>

            <TextBubble maxWidth={419}>
              You can also give your Axies special passive powers using
              <CustomLink to="/runes" endSpace={false}>
                Runes
              </CustomLink>
              .
            </TextBubble>
          </Stack>
        </Stack>
      </Container>

      <Image
        src="/images/accents/pattern-decorative.png"
        alt="Decorative pattern"
        style={{ mt: -10 }}
      />
    </Box>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  cards: state.data.cards,
});

export default connect(mapStateToProps, mapDispatchToProps)(Axies);
