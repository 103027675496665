import { Button, Grid, Stack } from "@mui/material";
import React from "react";
import SubTitle from "../../components/Typography/SubTitle";
import Text from "../../components/Typography/Text";
import { Link as RouterLink } from "react-router-dom";
import Card from "../../components/Card/Card";
import { FaPlay } from "react-icons/fa";
import MarketplaceButton from "../../components/MarketplaceButton/MarketplaceButton";
import { useTheme } from "@mui/system";

const PersonalAxies = () => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={6}
      sx={{
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          my: 6,
        },
      }}
    >
      <Card>
        <Stack gap={2}>
          <Stack gap={1}>
            <SubTitle>Personal Axies</SubTitle>
            <Text>
              You can buy your own personal axies on the marketplace. Personal
              axies can be bred to create more axies and can be traded with
              other players. Personal axies will earn more battle rewards than
              starter axies too!
            </Text>
          </Stack>
          <Button
            variant="contained"
            endIcon={<FaPlay />}
            to="/personal-axies"
            component={RouterLink}
            size="small"
            sx={{ alignSelf: "flex-start" }}
          >
            Learn More
          </Button>
        </Stack>
      </Card>
      <Stack
        alignItems="center"
        sx={{
          position: "absolute",
          bottom: -100,
          right: -56,
          "& img": {
            width: 340,
            mb: -2,
          },
          [theme.breakpoints.down("md")]: {
            right: 0,
          },
          [theme.breakpoints.down("sm")]: {
            top: "98%",
          },
        }}
      >
        <img src="images/pages/what-are-axies/team.png" alt="Axie team" />
        <MarketplaceButton />
      </Stack>
    </Grid>
  );
};

export default PersonalAxies;
