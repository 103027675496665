import React from "react";
import Title from "../../../components/Typography/Title";
import Text from "../../../components/Typography/Text";
import Section from "../../../components/Layout/Section";
import { Box, Stack, Button, Container } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { FaPlay } from "react-icons/fa";
import { useTheme } from "@mui/system";
import Image from "../../../components/Image/Image";
import Info from "../../../components/Info/Info";
import CustomLink from "../../../components/CustomLink/CustomLink";

const Welcome = () => {
  const theme = useTheme();

  return (
    <Container>
      <Stack
        gap={6}
        alignItems="center"
        direction="row"
        justifyContent="center"
        sx={{
          maxWidth: 780,
          position: "relative",
          zIndex: 2,
          mb: 3,
          mx: "auto",
        }}
      >
        <Box
          sx={{
            img: {
              width: 210,
            },
            [theme.breakpoints.down("md")]: {
              img: { width: 120 },
            },
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          <img
            src="/images/pages/new-players/stella.png"
            alt="Welcome, friend"
          />
        </Box>

        <Section>
          <Stack gap={1}>
            <Title color="text.darkBlue">Gameplay Guide</Title>

            <Text large={true}>
              Welcome to our comprehensive guide to playing Axie Infinity:
              Origins!
            </Text>
          </Stack>
          <Buttons />
        </Section>
      </Stack>

      <HeaderImage />
    </Container>
  );
};

const Buttons = () => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      gap={2}
      flexWrap="wrap"
      sx={{ [theme.breakpoints.down("sm")]: { mb: -5 } }}
    >
      <Button
        component={HashLink}
        variant="contained"
        endIcon={<FaPlay />}
        size="small"
        to="/guides/gameplay/#axies"
      >
        Axies
      </Button>
      <Button
        component={HashLink}
        variant="contained"
        endIcon={<FaPlay />}
        size="small"
        to="/guides/gameplay/#how-to-play"
      >
        How to Play
      </Button>
      <Button
        component={HashLink}
        variant="contained"
        endIcon={<FaPlay />}
        size="small"
        to="/guides/gameplay/#battle-mechanics"
      >
        Battle Mechanics
      </Button>
    </Stack>
  );
};

const HeaderImage = () => {
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative", maxWidth: "100%" }}>
      <Image
        src="/images/banners/axie-infinity-origin.jpg"
        alt="Axie Infinity New Player Guide"
        borderRadius={3}
        plain={true}
        style={{
          position: "relative",
          left: "50%",
          top: -90,
          transform: "rotate(-4deg) translate(-50%)",
          width: 900,
          zIndex: 1,
          [theme.breakpoints.down("sm")]: {
            transform: "none",
            potision: "initial",
            left: 0,
            top: 0,
          },
        }}
      />

      <Info
        style={{
          position: "absolute",
          zIndex: 2,
          maxWidth: 530,
          right: 0,
          bottom: 13,
          [theme.breakpoints.down("sm")]: {
            bottom: 0,
            position: "relative",
          },
        }}
      >
        New to Axie? Get started with our
        <CustomLink to="/new-players" endSpace={false}>
          New Player Guide
        </CustomLink>
        .
      </Info>
    </Box>
  );
};

export default Welcome;
