import { Grid, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Section from "../../components/Layout/Section";
import Image from "../../components/Image/Image";

const Seasonality = () => (
  <Container>
    <Grid container spacing={7}>
      <Grid item xs={12} sm={12} md={4}>
        <Image
          src="/images/pages/runes/seasonal-runes.png"
          alt="Seasonal Runes"
          maxWidth={420}
          style={{ justifyContent: "center", height: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <Section>
          <Title>Seasonal vs. Non-Seasonal Runes</Title>
          <Text large={true}>
            Some Runes are Seasonal, meaning they are only usable in the current
            Arena Season. After the Season is over, these Runes become
            Collectibles and cannot be used for gameplay.
          </Text>
          <Text large={true}>
            In contrast, non-Seasonal Runes are not deprecated and will be
            usable in future Seasons.
          </Text>
        </Section>
      </Grid>
    </Grid>
  </Container>
);

export default Seasonality;
