import { Stack, Box, Typography } from "@mui/material";
import React from "react";
import PageTitle from "../../components/Typography/PageTitle";
import Paper from "../../components/Paper/Paper";
import { useTheme } from "@mui/system";

const Header = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        px: 2,
        mb: -4,
        [theme.breakpoints.down("sm")]: {
          mb: 0,
        },
      }}
    >
      <Box
        sx={{
          backgroundImage: `url("/images/banners/axie-infinity.jpg")`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 620,
          position: "relative",
          borderRadius: "32px",
          boxShadow: "inset 0px 0px 6px #d2c0b5",
          [theme.breakpoints.down("md")]: {
            height: 400,
          },
          [theme.breakpoints.down("sm")]: {
            height: 300,
          },
        }}
      >
        <Paper
          vertical={true}
          style={{
            maxWidth: 900,
            m: "0 auto",
            left: 16,
            right: 16,
            position: "absolute",
            bottom: "0%",
            boxShadow: `2px 4px rgb(147 110 89 / 42%)`,
            transform: "translateY(50%)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={4}
            sx={{
              pl: 2,
              pr: 8,
              "& img": {
                width: 200,
                transform: "rotate(-8deg)",
                my: -6,
              },
              [theme.breakpoints.down("md")]: {
                img: {
                  width: 140,
                },
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                pl: 1,
                pr: 1,
                img: { display: "none" },
              },
            }}
          >
            <img
              src="/images/accents/axies/buba-right.png"
              alt="Buba Starter Axie"
            />
            <Stack gap={1}>
              <PageTitle
                textAlign="left"
                color="text.brown"
                style={{
                  [theme.breakpoints.down("sm")]: {
                    textAlign: "center",
                  },
                }}
              >
                Welcome to Lunacia
              </PageTitle>
              <Typography
                color="text.darkBrown"
                fontWeight={600}
                variant="span"
                lineHeight={1.45}
                fontSize={18}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    fontSize: 16,
                  },
                  [theme.breakpoints.down("sm")]: {
                    textAlign: "center",
                  },
                }}
              >
                Lunacia is a magical world filled with adorable creatures called
                Axies. An adventure awaits you!
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};

export default Header;
