import { Stack, Box } from "@mui/material";
import React from "react";
import Page from "../../components/Page/Page";
import CardsPowerUps from "./CardsPowerUps";
import BattleModes from "./BattleModes";
import CommunityBanner from "./CommunityBanner";
import Axies from "../../pages/WhatAreAxies/Axies/Axies";
import Header from "./Header";
import { useTheme } from "@mui/system";

const Home = () => {
  const theme = useTheme();
  return (
    <>
      <Header />
      <Page>
        <Stack gap={{ xs: 14, sm: 16, lg: 20 }}>
          <Box
            sx={{
              pt: 8,
              [theme.breakpoints.down("sm")]: {
                pt: 16,
              },
            }}
          >
            <Axies />
          </Box>
          <CardsPowerUps />
          <BattleModes />
        </Stack>
      </Page>
      <CommunityBanner />
    </>
  );
};

export default Home;
