import React from "react";
import { Typography, Chip, Stack } from "@mui/material";

const SubTitle = ({
  children,
  textAlign = "left",
  color = "text.black",
  fontSize = 30,
  icon,
  variant = "h3",
  newTag,
}) => {
  return (
    <Stack
      justifyContent={
        textAlign === "right"
          ? "flex-end"
          : textAlign === "center"
          ? "center"
          : "flex-start"
      }
      direction="row"
      gap={icon == null && !newTag ? 0 : 1}
      alignItems="center"
    >
      {icon != null && <Stack alignItems="center">{icon}</Stack>}
      <Typography
        variant={variant}
        fontSize={fontSize}
        color={color}
        textAlign={textAlign}
      >
        {children}
      </Typography>
      {newTag && <Chip label="New!" color="primary" />}
    </Stack>
  );
};

export default SubTitle;
