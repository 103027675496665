import React from "react";
import { Stack, Typography } from "@mui/material";

const Capsule = ({ children }) => {
  return (
    <Stack
      sx={{
        background: "#EBF5FD",
        p: "5px 10px",
        borderRadius: "20px",
        alignSelf: "flex-start",
      }}
    >
      <Typography
        color="#3571E2"
        fontSize={12}
        fontWeight={600}
        textTransform="lowercase"
      >
        {children}
      </Typography>
    </Stack>
  );
};

export default Capsule;
