import React from "react";
import Section from "components/Layout/Section";
import SubTitle from "components/Typography/SubTitle";
import ListItem from "components/List/ListItem";
import List from "components/List/List";
import Text from "components/Typography/Text";
import CustomLink from "components/CustomLink/CustomLink";

const EnergyBurst = () => {
  return (
    <Section>
      <SubTitle>Energy Burst</SubTitle>

      <List ml={0}>
        <ListItem minimal={true} style={{ fontWeight: 600 }}>
          Energy bursts increase your energy - per turn - for the rest of the
          battle! To trigger an energy burst you must collect energy fragments.{" "}
        </ListItem>
        <List>
          <Text style={{ fontWeight: 600 }}>
            Energy bursts are collected by:
          </Text>
          <ListItem minimal={true}>
            Playing cards! For each energy you spend playing a card, you will
            collect one energy fragment
          </ListItem>
          <ListItem minimal={true}>
            Keeping cards - for every energy you spend keeping a card, outside
            of the free keep, you will earn one energy fragment.
          </ListItem>
          <ListItem minimal={true}>
            Left over energy is also converted into energy fragments! In short,
            for every energy you will earn an energy fragment.
          </ListItem>
          {/* links here */}
          <ListItem minimal={true}>
            Abilities/effects of <CustomLink to="/cards">Cards</CustomLink>,{" "}
            <CustomLink to="/runes">Runes</CustomLink> &{" "}
            <CustomLink to="/charms">Charms</CustomLink> can also earn you
            energy fragments!
          </ListItem>
        </List>
        <ListItem minimal={true}>
          Your 1st energy burst will require 15 energy fragments and increase
          your energy to 4, per turn, for the rest of the battle!
        </ListItem>
        <ListItem minimal={true}>
          Your 2nd energy burst requires 20 energy fragments, +1 energy & +1
          cards drawn! Giving you 5 energy & 6 cards drawn per turn for the rest
          of the battle!
        </ListItem>
      </List>
    </Section>
  );
};

export default EnergyBurst;
