import { Stack, Grid } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Section from "../../components/Layout/Section";
import { AXIE_CLASSES } from "../../config";
import Image from "../../components/Image/Image";
import { useTheme } from "@mui/system";

const Classes = () => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={9}>
        <Section>
          <Title>Classes</Title>
          <Text large={true}>
            Axies are categorized into 9 different Classes based on their body
            type:
          </Text>
          <Stack
            direction="row"
            alignItems="center"
            gap={4}
            flexWrap="wrap"
            sx={{
              [theme.breakpoints.down("md")]: {
                justifyContent: "center",
              },
              [theme.breakpoints.down("sm")]: {
                gap: 3,
                img: {
                  width: 40,
                },
              },
            }}
          >
            {AXIE_CLASSES.map((cls, i) => (
              <AxieClass key={`CLASS_${i}`} cls={cls} />
            ))}
          </Stack>
        </Section>
      </Grid>

      <Grid item xs={12} sm={12} md={3}>
        <Section>
          <Text>
            Look, an Axie! This Axie has Plant type body, so we classify it as a
            Plant Axie.
          </Text>
          <Image
            src="/images/pages/what-are-axies/plant-axie.png"
            alt="Plant axie"
            caption="A Plant Axie"
            maxWidth={240}
            style={{
              m: "0 auto",
              [theme.breakpoints.down("sm")]: {
                gap: 3,
                img: {
                  width: 100,
                },
              },
            }}
          />
        </Section>
      </Grid>
    </Grid>
  );
};

const AxieClass = ({ cls }) => {
  const clsImage = cls.toLowerCase();
  return (
    <Stack
      gap={1}
      alignItems="center"
      sx={{
        img: { width: 50 },
        transition: "0.2s transform ease",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.1)",
        },
      }}
    >
      <img
        src={`/images/class-icons/${clsImage}.png`}
        alt={`Axie ${clsImage} class`}
      />
      <Text fontWeight={800}>{clsImage}</Text>
    </Stack>
  );
};

export default Classes;
