import React from "react";
import { Stack } from "@mui/material";
import Label from "../Typography/Label";
import CustomLink from "../CustomLink/CustomLink";

const Image = ({
  src,
  alt,
  style,
  card = false,
  borderRadius,
  boxShadow = false,
  maxWidth = "100%",
  caption,
  gap = 4,
  to,
  scale,
  id,
  href,
}) => {
  const scaleStyles = scale
    ? {
        "&:hover": {
          cursor: scale ? "pointer" : "normal",
          transform: scale ? "scale(1.08)" : "inherit",
        },
      }
    : {};
  return (
    <Wrapper to={to} href={href} id={id}>
      <Stack
        gap={gap}
        alignItems="center"
        sx={{
          maxWidth,
          transition: "0.2s transform ease",
          borderRadius: card ? "24px" : 0,
          p: card ? 2 : 0,
          background: card ? "#fff" : "transparent",
          boxShadow: card
            ? "4px 4px rgba(0,0,0,0.08), 0 0 10px rgba(240,220,192,0.09)"
            : "none",
          "& img": {
            maxWidth: "100%",
            display: "block",
            borderRadius,
            boxShadow:
              card || !boxShadow
                ? "none"
                : "4px 4px rgba(0,0,0,0.08), 0 0 10px rgba(240,220,192,0.09)",
          },
          ...scaleStyles,
          ...style,
        }}
      >
        <img src={src} alt={alt} id={id} />
        {caption != null && <Label>{caption}</Label>}
      </Stack>
    </Wrapper>
  );
};

const Wrapper = ({ to, href, children }) =>
  to == null && href == null ? (
    <>{children}</>
  ) : to != null ? (
    <CustomLink to={to}>{children}</CustomLink>
  ) : (
    <CustomLink href={href}>{children}</CustomLink>
  );

export default Image;
