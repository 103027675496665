import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { MdInfo } from "react-icons/md";

const Info = ({ children, style }) => {
  return (
    <Box
      sx={{
        position: "relative",
        background: "#F3E9FF",
        p: 3,
        borderRadius: "24px",
        boxShadow: "2px 4px 0px #CFBBE7",
        alignSelf: "flex-start",
        ...style,
      }}
    >
      <Stack
        sx={{
          background: "#fff",
          p: 0.5,
          borderRadius: "50%",
          color: "#CFBBE7",
          alignItems: "center",
          justifyContent: "center",
          width: 40,
          height: 40,
          fontSize: 40,
          position: "absolute",
          left: -15,
          top: -15,
        }}
      >
        <MdInfo />
      </Stack>
      <Typography variant="body2" fontWeight="600">
        {children}
      </Typography>
    </Box>
  );
};

export default Info;
