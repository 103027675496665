import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";

const PageTitle = ({ children, textAlign = "center", color, style }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h1"
      textAlign={textAlign}
      color={color ? color : "text.black"}
      sx={{
        [theme.breakpoints.down("sm")]: {
          fontSize: 42,
        },
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};

export default PageTitle;
