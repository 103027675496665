import React from "react";
import Page from "../../components/Page/Page";
import Classes from "./Classes";
import BodyParts from "./BodyParts";
import Axies from "./Axies/Axies";
import RunesCharms from "./RunesCharms";
import CallToAction from "../../components/CallToAction/CallToAction";
import PageSEO from "../../components/SEO/PageSEO";
import PotentialPoints from "./PotentialPoints";

const WhatAreAxies = () => {
  return (
    <>
      <PageSEO
        title="What are Axies? | Axie.Tech"
        description="Axies are fierce, adorable creatures that love to battle, build and hunt for
        treasure."
      />
      <Page
        headerImg="/images/banners/axies.jpg"
        headerHeight={300}
        title="Axies"
        headerText="Axies are fierce, adorable creatures that love to battle, build and hunt for
        treasure."
      >
        <Classes />
        <BodyParts />
        <PotentialPoints />
        <RunesCharms />
        <Axies title="Types of Axies" />
      </Page>
      <CallToAction
        title="Get your first Axies"
        description="Learn how to acquire and unlock your own Starter Axies."
        buttonText="Starter Axies"
        buttonTo="/starter-axies"
        axie="puffy"
      />
    </>
  );
};

export default WhatAreAxies;
