import { Button, Container, Link, Stack } from "@mui/material";
import React from "react";
import Text from "../../components/Typography/Text";
import Title from "../../components/Typography/Title";
import SubTitle from "../../components/Typography/SubTitle";
import List from "../../components/List/List";
import ListItem from "../../components/List/ListItem";
import Bold from "../../components/Typography/Bold";
import {
  AXIE_CODE_OF_CONDUCT,
  AXIE_INFINITY_DISCORD,
  AXIE_INFINITY_TWITTER,
  AXIE_TECH_GUILD_DISCORD,
  SCHOLARSHIP_CALCULATOR_URL,
} from "../../config";
import IconSection from "../../components/Layout/IconSection";
import CustomLink from "../../components/CustomLink/CustomLink";
import Image from "../../components/Image/Image";
import { FaGraduationCap } from "react-icons/fa";

const GetAScholarship = () => {
  return (
    <Container>
      <Stack gap={4} id="get-a-scholarship">
        <Stack
          sx={{
            backgroundImage: "url('/images/accents/blue-banner.jpg')",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            p: 4,
            borderRadius: 4,
          }}
        >
          <Title color="text.white">
            Finding a Scholarship in the Community
          </Title>
        </Stack>

        <Text large={true}>
          Scholarships enable new players to rent Axies from other players. This
          is great way to battle and earn with Personal Axies with no upfront
          cost. You can estimate your Scholarship earnings using the
          <CustomLink href={SCHOLARSHIP_CALCULATOR_URL} endSpace={false}>
            Scholarship Calculator
          </CustomLink>
          .
        </Text>

        <IconSection
          img="/images/accents/axies/axie-with-letter.png"
          alt="Axie holding a letter"
        >
          <SubTitle>Scholarships for Beginners</SubTitle>

          <Text>
            Before looking for a Scholarship, it's important to understand the
            basics of a how a Scholarship works:
          </Text>
          <List>
            <ListItem title="Onboarding">
              A player with Axies (the Manager) decides to lend these Axies to
              another player (the Scholar).
            </ListItem>
            <ListItem title="Revenue Sharing">
              The Manager sets the % split of earnings that will be shared with
              the Scholar.
            </ListItem>
            <ListItem title="Earning">
              The Scholar battles with the Axies and earns in-game tokens called
              Smooth Love Potion (SLP).
            </ListItem>
            <ListItem title="Payout">
              The Manager transfers the Scholar's % of SLP on a set schedule,
              typically every 2 weeks or once per month.
            </ListItem>
            <ListItem title="Graduation">
              The Scholar can save some or all of her SLP and use it to purchase
              her own Axies. When this happens, we say that the Scholar has
              "graduated" from the Scholarship.
            </ListItem>
          </List>
        </IconSection>

        <IconSection
          img="/images/accents/axies/axie-with-scroll.png"
          alt="Axie holding a scroll"
        >
          <SubTitle>Rules</SubTitle>

          <Text>
            Every scholarship will have its own rules set by the Manager, so be
            sure to ask about the rules before you apply.
          </Text>

          <Text>Here are a few basic questions to get you started:</Text>

          <List>
            <ListItem>What is the Scholar's % share of SLP?</ListItem>
            <ListItem>How often will the Scholar be paid out?</ListItem>
            <ListItem>
              Is there a minimum amount of SLP that must be earned per
              day/week/month in order to keep the Scholarship?
            </ListItem>
            <ListItem>Are there any other Rules of the Scholarship?</ListItem>
          </List>

          <Text>
            The most important rule that applies to every scholar is{" "}
            <Bold>No Multi-Accounting.</Bold> Multi-Accounting is when you play
            two or more different accounts on the same device. This will result
            in your Axies getting permanently banned.
          </Text>

          <Text>
            Lastly, all players must adhere to the rules of Axie Infinity. Be
            sure to read and fully understand the{" "}
            <Link href="https://axieinfinity.com/terms/" target="_blank">
              Axie Infinity Terms of Use
            </Link>{" "}
            and the{" "}
            <Link href={AXIE_CODE_OF_CONDUCT} target="_blank">
              Axie Infinity Code of Conduct
            </Link>
            .
          </Text>
        </IconSection>

        <IconSection
          img="/images/accents/axies/axie-with-turnip.png"
          alt="Axie catching a turnip"
        >
          <SubTitle>Where to Find a Scholarship</SubTitle>

          <Text>
            To find a Scholarship, you'll need to connect with a Manager or
            Guild within the Axie Infinity community.
          </Text>

          <Text>
            One option is to join the
            <CustomLink href={AXIE_TECH_GUILD_DISCORD} endSpace={false}>
              Axie.Tech Scholar Discord
            </CustomLink>
            , where you can sign up for the Axie.Tech Scholarship waitlist:
          </Text>

          <Button
            variant="contained"
            size="small"
            sx={{ alignSelf: "flex-start" }}
            href={AXIE_TECH_GUILD_DISCORD}
            target="_blank"
            endIcon={<FaGraduationCap />}
          >
            Join Axie Tech
          </Button>

          <Text>
            You can also check out the official Axie Infinity Discord server and
            Twitter page:
          </Text>

          <Stack direction="row" alignItems="center" flexWrap="wrap" gap={4}>
            <CustomLink href={AXIE_INFINITY_DISCORD}>
              <Image
                src="/images/pages/personal-axies/discord.png"
                alt="Axie Infinity Discord"
                scale={1.05}
                maxWidth={300}
              />
            </CustomLink>
            <CustomLink href={AXIE_INFINITY_TWITTER}>
              <Image
                src="/images/pages/personal-axies/twitter.png"
                alt="Axie Infinity Twitter"
                scale={1.05}
                maxWidth={300}
              />
            </CustomLink>
          </Stack>
        </IconSection>
      </Stack>
    </Container>
  );
};

export default GetAScholarship;
