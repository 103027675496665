import { Button, Container, Grid, Stack } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Info from "../../components/Info/Info";
import Section from "../../components/Layout/Section";
import Image from "../../components/Image/Image";
import { HashLink } from "react-router-hash-link";
import { FaPlay } from "react-icons/fa";

const WhatIsArena = () => {
  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={7}>
          <Section>
            <Title>What is Arena Mode?</Title>
            <Text large={true}>
              Arena Mode is the player vs. player (PvP) gameplay mode in Axie
              Infinity. Instead of fighting bosses, you'll be matched against
              other players and their Axies.
            </Text>
            <Text large={true}>
              Arena will challenge all your skills as an Axie trainer. You'll
              need knowledge of battle mechanics plus the right combination of
              Axies, Cards, Runes and Charms. And a little luck never hurt!
            </Text>

            <Button
              to="#arena-modes"
              component={HashLink}
              variant="contained"
              sx={{ alignSelf: "flex-start" }}
              endIcon={<FaPlay />}
            >
              Arena Modes
            </Button>
          </Section>
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <Stack gap={4} alignItems="center">
            <Image
              src="/images/pages/arena/looking-for-opponent.jpg"
              alt="Looking for opponent"
              maxWidth={400}
              plain={true}
            />
            <Info style={{ m: "0 auto" }}>
              There are currently 3 modes in Arena: Practice, Ranked and
              Tournament. <br /> You may also encounter limited-time modes such
              as Blitz mode- with fast-paced games at a speeded up pace!
            </Info>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhatIsArena;
