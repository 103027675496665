import { Container, Grid, Stack, Button } from "@mui/material";
import React from "react";
import Title from "components/Typography/Title";
import Text from "components/Typography/Text";
import Image from "components/Image/Image";
import Section from "components/Layout/Section";
import { FaPlay } from "react-icons/fa";
import { ESPORTS_SCHEDULE_URL } from "config";

const TournamentMode = () => {
  return (
    <Container id="tournament-mode">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={3}>
          <Stack gap={4}>
            <Image
              src="/images/pages/arena/dragon-rank.png"
              alt="Dragon Rank"
              maxWidth={320}
              style={{ m: "0 auto" }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={9}>
          <Section>
            <Stack gap={3} direction="row" alignItems="center">
              <Image
                src="/images/pages/arena/icons/tournament.png"
                alt="Tournament Mode"
                maxWidth={100}
              />
              <Title newTag={true}>Tournament Mode</Title>
            </Stack>
            <Text large={true}>
              Tournaments are held periodically and are an opportunity for
              players to test their Arena skills, compete, and win prizes.
            </Text>
            <Text large={true}>
              If you're not ready to compete, then you can watch the gameplay to
              learn new strategies, builds, and battle mechanics to help take
              your game to the next level.
            </Text>
            <Text large={true}>Check out upcoming Esports Tournaments!</Text>
            <Button
              href={ESPORTS_SCHEDULE_URL}
              target="_blank"
              variant="contained"
              sx={{ alignSelf: "flex-start" }}
              endIcon={<FaPlay />}
              size="small"
            >
              Axie Esports Schedule
            </Button>
          </Section>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TournamentMode;
