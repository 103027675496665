import React from "react";
import { Stack } from "@mui/material";
import DecorativeList from "../../../components/DecorativeList/DecorativeList";
import SubTitle from "../../../components/Typography/SubTitle";
import Text from "../../../components/Typography/Text";
import CustomLink from "../../../components/CustomLink/CustomLink";
import Image from "../../../components/Image/Image";

const BattleBasics = ({ id }) => {
  const steps = [
    {
      title: "Cards",
      icon: "new-players/steps/cards",
      description:
        "Every turn you draw 5 cards from your deck of 18. 6 cards from each axie, 1 per part.",
    },
    {
      title: "Energy",
      icon: "new-players/steps/energy",
      description:
        "Each turn you have 3 energy, you can spend this energy to play cards. Most cards cost 1 energy, though some will cost 0 or even 2!",
    },
    {
      title: "Turns",
      icon: "new-players/steps/turns",
      description:
        "Once you end your turn, all cards played/unplayed will enter your discard pile. These cards cannot be drawn again until all of the remaining cards in your draw pile have entered your hand and been discarded. ",
    },
    {
      title: "Battle",
      icon: "new-players/steps/battle",
      description:
        "The bars floating above your Axies' heads show how much health they have left. The goal is to reduce the health of your opponent's 3 Axies to 0 before they can do the same to you.",
    },
    {
      title: "Rewards",
      icon: "new-players/steps/rewards",
      description:
        "The winner receives rewards, ranging from power-ups to crafting materials. In Ranked Arena Mode, you can collect Victory Stars and climb to new ranks!",
    },
  ];

  return (
    <Stack gap={4}>
      <Text large={true}>
        Axie Infinity is a turn-based card game where you and your Axies battle
        against fierce foes and other players.
      </Text>
      <Text large={true}>
        Arena mode gives you the option of choosing different kinds of gameplay,
        from Ranked mode to fast-paced Blitz mode! Find out more about{" "}
        <CustomLink to="/arena" endSpace={false}>
          Arena Mode
        </CustomLink>
        .
      </Text>

      <Text large={true}>Let's dive into the key parts of Axie combat.</Text>

      <Image
        src="/images/pages/gameplay-guide/battle.jpg"
        alt="Axie battle"
        borderRadius={3}
      />
      <Stack gap={2}>
        <SubTitle textAlign="center">Battle Basics</SubTitle>
        <DecorativeList items={steps} />
      </Stack>
    </Stack>
  );
};

export default BattleBasics;
