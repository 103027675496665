import React from "react";
import Title from "../Typography/Title";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";

const DecorativeBanner = ({
  bannerSlug,
  titleSlug,
  title,
  titleMaxWidth,
  newMessage,
  id,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundImage: `url('/images/banners/${bannerSlug}.jpg')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        height: 300,
        width: "calc(100% - 32px)",
        borderRadius: "24px",
        position: "relative",
        maxWidth: 1600,
        m: "0 auto",
        [theme.breakpoints.down("md")]: {
          height: 200,
        },
        [theme.breakpoints.down("sm")]: {
          height: 160,
        },
        img: {
          width: "96%",
          maxWidth: titleMaxWidth,
        },
      }}
    >
      <Title style={{ height: 0, visibility: "hidden" }}>{title}</Title>
      <Stack
        alignItems="center"
        sx={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%) translateY(50%)",
        }}
      >
        <img src={`/images/pages/${titleSlug}.png`} alt={title} id={id} />
        {newMessage && (
          <Typography color="text.black" variant="h3" fontSize={20}>
            What's New in Origins
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default DecorativeBanner;
