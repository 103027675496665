import React from "react";
import CallToAction from "../../../components/CallToAction/CallToAction";
import Page from "../../../components/Page/Page";
import PageSEO from "../../../components/SEO/PageSEO";
import Intro from "./Intro";
import ListOfAxies from "./ListOfAxies";

const Collectors = () => {
  return (
    <>
      <PageSEO
        title="Axie Collector's Guide | Axie.Tech"
        description="Rare Axies are collectible NFTs with unique genetics and aesthetics and a capped supply."
      />
      <Page
        headerImg="/images/banners/axies.jpg"
        headerHeight={360}
        title="Collector's Guide"
        headerText="A beginner's guide to Axie Infinity collectibles."
      >
        <Intro />
        <ListOfAxies />
      </Page>
      <CallToAction
        title="Grow Your Collection"
        description="Head over to the Marketplace to look for your next special Axie!"
        marketplaceButton={true}
        axie="puffy"
      />
    </>
  );
};

export default Collectors;
