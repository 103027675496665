import { Stack, Tooltip, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { connect } from "react-redux";
import ClassIcon from "../ClassIcon/ClassIcon";
import CardTemplate from "./CardTemplate";
import { removeSpecialCharactersInDescription } from "helpers/cleaning";

const TextWithHighlights = ({
  text,
  abilities,
  cards,
  toolCards,
  curseCards,
}) => {
  const regex = /([<{[][^<>{}[\]]+[>\]}])/g;
  const matches = text.match(regex);

  return (
    <>
      {text.split(regex).reduce((prev, current, i) => {
        if (!i) return [current];

        if (matches.includes(current)) {
          const cleanedWord = removeSpecialCharactersInDescription(current);
          return prev.concat(
            <YellowTextWithTooltip
              word={cleanedWord}
              abilities={abilities}
              toolCards={toolCards}
              curseCards={curseCards}
              cards={cards}
              key={i + current}
            >
              {removeSpecialCharactersInDescription(current)}
            </YellowTextWithTooltip>
          );
        } else {
          return prev.concat(current);
        }
      }, [])}
    </>
  );
};

const YellowTextWithTooltip = ({
  children,
  word,
  abilities,
  toolCards,
  curseCards,
  cards,
}) => {
  return (
    <CustomTooltip
      word={word}
      abilities={abilities}
      toolCards={toolCards}
      curseCards={curseCards}
      cards={cards}
    >
      <Typography
        variant="span"
        sx={{
          color: "#F9DE4B",
          fontWeight: 700,
          fontSize: "inherit",
          "&:hover": { cursor: "pointer" },
        }}
      >
        {children}
      </Typography>
    </CustomTooltip>
  );
};

const CustomTooltip = ({
  word,
  abilities,
  cards,
  children,
  toolCards,
  curseCards,
}) => {
  if (word == null) {
    return <>{children}</>;
  }

  const property = abilities.find((ability) => ability.name === word);

  if (property != null) {
    return (
      <PropertyTooltip
        word={word}
        definition={property?.description}
        children={children}
      />
    );
  }

  const curse = curseCards.find((card) => card.name === word);

  if (curse != null) {
    return <CardTooltip card={curse} children={children} />;
  }

  const tool = toolCards.find((card) => card.name === word);

  if (tool != null) {
    return <CardTooltip card={tool} children={children} />;
  }

  const summon = cards.find((card) => card.summon?.name === word);

  if (summon != null) {
    return <SummonTooltip summon={summon.summon} children={children} />;
  }

  const card = cards.find((card) => card.cardName === word);

  if (card != null) {
    return <CardTooltip card={card} children={children} />;
  }

  return <>{children}</>;
};

const CardTooltip = ({ card, children }) => {
  return (
    <Tooltip
      placement="bottom"
      title={<CardTemplate card={card} width={150} />}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "transparent",
            boxShadow: "none",
            border: "none",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

const SummonTooltip = ({ summon, children }) => {
  const theme = useTheme();
  if (summon?.effect == null) {
    return <>{children}</>;
  }
  return (
    <Tooltip
      title={
        <Stack gap={0.5} sx={{ color: "#fff", fontSize: 13 }}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <YellowText text={summon.name} />
            <ClassIcon width={16} axieClass={summon.class} />
            <Box sx={{ whiteSpace: "nowrap" }}>{summon.hp} HP</Box>
          </Stack>
          {summon.effect.replace(/{|\[|<|}|\]>/g, "")}
        </Stack>
      }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: theme.palette.colors.wood.input.background,
            border: `1px solid ${theme.palette.colors.wood.input.border}`,
            fontFamily: "'Roboto', sans serif",
            fontSize: 18,
            boxShadow: "none",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

const PropertyTooltip = ({ definition, children, word }) => {
  const theme = useTheme();
  if (definition == null) {
    return <>{children}</>;
  }
  return (
    <Tooltip
      title={<StyledDefinition word={word} definition={definition} />}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: theme.palette.colors.wood.input.background,
            border: `1px solid ${theme.palette.colors.wood.input.border}`,
            fontFamily: "'Roboto', sans serif",
            fontSize: 13,
            boxShadow: "none",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

const YellowText = ({ text }) => (
  <Typography
    variant="span"
    sx={{
      color: "#F9DE4B",
      fontWeight: 700,
      fontSize: 13,
    }}
  >
    {text}:
  </Typography>
);

const StyledDefinition = ({ word, definition }) => (
  <Typography variant="span" color="#fff" fontSize={13}>
    <YellowText text={word} /> {definition}
  </Typography>
);

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  abilities: state.data.abilities,
  curseCards: state.data.curseCards,
  toolCards: state.data.toolCards,
  cards: state.data.cards,
});

export default connect(mapStateToProps, mapDispatchToProps)(TextWithHighlights);
