import { axies } from "../components/ExploreAxies/axieData";
import { AXIE_CLASS_COLORS } from "../config";
import { getRandomIntFromInclusiveInterval } from "./display";
import * as classIcons from "../icons/classIcons";

export const getRandomNStarterAxies = (n, excluding) => {
  const keys = Object.keys(axies);
  const numStarterAxies = keys.length;
  let randomAxies = [];
  let randomAxieNames = [];
  let numToReturn = n;
  if (numToReturn > numStarterAxies - 1) {
    numToReturn = numStarterAxies - 1;
  }

  while (randomAxies.length < numToReturn) {
    const axie =
      axies[keys[getRandomIntFromInclusiveInterval(0, numStarterAxies - 1)]];

    if (axie.name !== excluding && !randomAxieNames.includes(axie.name)) {
      randomAxies.push(axie);
      randomAxieNames.push(axie.name);
    }
  }

  return randomAxies;
};

export const makeCardSummary = (axie, allCards) => {
  if (axie == null || allCards == null) {
    return [];
  }
  const mainTags = [
    "attack",
    "banish",
    "ethereal",
    "innate",
    "power",
    "retain_effect",
    "secret",
    "skill",
  ];
  const cardTags = {};
  const cardStats = {
    cardEnergy: 0,
    cardDamage: 0,
    cardHeal: 0,
    cardShield: 0,
  };
  const potentialPoints = [];
  const classCounts = {};

  axie.cards.forEach((card) => {
    const cardData =
      allCards != null && allCards.find((c) => c.partId === card);

    if (cardData != null) {
      const cardClass = cardData.class.toLowerCase();

      ["cardEnergy", "cardDamage", "cardShield", "cardHeal"].forEach((type) => {
        const amount = parseInt(cardData[type]);
        if (!isNaN(amount)) {
          cardStats[type] += amount;
        }
      });

      if (cardClass in classCounts) {
        classCounts[cardClass] += 2;
      } else {
        classCounts[cardClass] = 2;
      }

      const tags = cardData.tags.split(",");

      tags.forEach((tag) => {
        if (mainTags.includes(tag)) {
          if (tag in cardTags) {
            cardTags[tag] += 1;
          } else {
            cardTags[tag] = 1;
          }
        }
      });
    }
  });

  if (axie.class.toLowerCase() in classCounts) {
    classCounts[axie.class.toLowerCase()] += 3;
  } else {
    classCounts[axie.class.toLowerCase()] = 3;
  }

  Object.keys(classCounts).forEach((key) => {
    const forClass = classCounts[key];
    potentialPoints.push({ class: key, amount: forClass });
  });
  //   if (classCounts[key] === 3) {
  //     forClass.amount += 1;
  //   } else if (classCounts[key] === 4) {
  //     forClass.amount += 2;
  //   } else if (classCounts[key] === 5) {
  //     forClass.amount += 4;
  //   } else if (classCounts[key] === 6) {
  //     forClass.amount += 6;
  //   } else if (classCounts[key] === 7) {
  //     forClass.amount += 8;
  //   }
  //   potentialPoints.push(forClass);
  // });

  return {
    cardStats,
    cardTags,
    potentialPoints: potentialPoints.sort((a, b) =>
      a.amount < b.amount ? 1 : a.amount > b.amount ? -1 : 0
    ),
  };
};

export const getColorForAxieClass = (axieClass) => {
  if (axieClass != null && AXIE_CLASS_COLORS[axieClass.toLowerCase()] != null) {
    return AXIE_CLASS_COLORS[axieClass.toLowerCase()];
  }
};

export const getIconForAxieClass = (axieClass, color) => {
  if (axieClass != null) {
    const cls = axieClass.toLowerCase();
    if (cls in classIcons) {
      if (color) {
        return classIcons[cls](color);
      }
      return classIcons[cls]();
    }
  } else {
    if (color) {
      return classIcons.unknown(color);
    }
    return classIcons.unknown();
  }
};
