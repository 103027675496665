import React from "react";
import Text from "components/Typography/Text";
import Section from "components/Layout/Section";
import CustomLink from "components/CustomLink/CustomLink";
import { MARKETPLACE_URL } from "config";
import { Stack, Button, Grid } from "@mui/material";
import CenteredContent from "components/Layout/CenteredContent";
import SubTitle from "components/Typography/SubTitle";
import { Link as RouterLink } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import DecorativeBanner from "components/DecorativeBanner/DecorativeBanner";
import Image from "components/Image/Image";
import { connect } from "react-redux";
import {
  commonClasses,
  rareClasses,
  secretClasses,
} from "pages/NewPlayers/data/data";
import AxieCards from "pages/NewPlayers/molecules/AxieCards";
import AxieIntro from "pages/NewPlayers/molecules/AxieIntro";
import AxieClasses from "pages/NewPlayers/molecules/AxieClasses";
import RunesCharms from "pages/NewPlayers/molecules/RunesCharms";

const Axies = () => {
  return (
    <Stack gap={{ xs: 6, sm: 8, md: 10 }} id="axies">
      <DecorativeBanner
        bannerSlug="welcome"
        titleSlug="new-players/axies"
        title="Axies"
        titleMaxWidth={240}
      />

      <CenteredContent>
        <Text large={true}>
          Axies are fascinating, fierce and adorable creatures that you can
          collect, battle and breed.
        </Text>

        <AxieIntro />
        <AxieCards />
        <RunesCharms />
        <AxieClasses
          commonClasses={commonClasses}
          rareClasses={rareClasses}
          secretClasses={secretClasses}
        />

        <Text large={true}>
          There are two different types of Axies that you can play with:
          <CustomLink to="/starter-axies">Starter Axies</CustomLink> and
          <CustomLink to="/personal-axies">Personal Axies</CustomLink>. Don't
          forget - you'll need a team of 3 Axies in order to play!
        </Text>
        <StarterAxies />
        <PersonalAxies />
      </CenteredContent>
    </Stack>
  );
};

const StarterAxies = () => (
  <Grid container spacing={8}>
    <Grid item xs={12} sm={8} md={8} order={{ xs: 1, sm: 1, md: 1, lg: 0 }}>
      <Section>
        <SubTitle>Starter Axies</SubTitle>
        <Text>
          As a new player you’ll receive 3 free starter axies named Buba, Olek &
          Puffy. These axies are soulbound axies. Meaning they are not NFT’s,
          therefore cannot be traded with other players or bred to create more
          axies on the marketplace. You can unlock more Starter axies by playing
          adventure mode!
        </Text>
        <Button
          endIcon={<FaPlay />}
          to="/starter-axies"
          component={RouterLink}
          variant="contained"
          size="small"
          sx={{ alignSelf: "flex-start" }}
        >
          Learn More
        </Button>
      </Section>
    </Grid>

    <Grid item xs={12} sm={4} md={4} order={{ xs: 0, sm: 0, md: 0, lg: 1 }}>
      <Image
        src="/images/pages/new-players/starter-axies.png"
        alt="Starter Axies"
        caption="Olek, Puffy &amp; Buba"
      />
    </Grid>
  </Grid>
);

const PersonalAxies = () => {
  return (
    <Grid container spacing={8} alignItems="center">
      <Grid item xs={12} sm={4} md={6}>
        <CustomLink href={MARKETPLACE_URL}>
          <Image
            src="/images/pages/new-players/marketplace.jpg"
            alt="Starter Axies"
            borderRadius={3}
            boxShadow={true}
          />
        </CustomLink>
      </Grid>

      <Grid item xs={12} sm={8} md={6}>
        <Section>
          <SubTitle>Personal Axies</SubTitle>
          <Text>
            You can buy your own personal axies on the marketplace. Personal
            axies can be bred to create more axies and can be traded with other
            players. Personal axies will earn more battle rewards than starter
            axies too!
          </Text>
          <Button
            endIcon={<FaPlay />}
            to="/personal-axies"
            component={RouterLink}
            variant="contained"
            size="small"
            sx={{ alignSelf: "flex-start" }}
          >
            Learn More
          </Button>
        </Section>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({ cards: state.data.cards });

export default connect(mapStateToProps, mapDispatchToProps)(Axies);
