import { Button, Grid } from "@mui/material";
import React from "react";
import Page from "../../components/Page/Page";
import Section from "../../components/Layout/Section";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Image from "../../components/Image/Image";
import Info from "../../components/Info/Info";
import { FaPlay } from "react-icons/fa";
import {
  AXIE_INFINITY_ESPORTS_TWITTER,
  ESPORTS_SCHEDULE_URL
} from "../../config";
import PageSEO from "../../components/SEO/PageSEO";

const Esports = () => {
  return (
    <>
      <PageSEO
        title="Esports | Axie.Tech"
        description="The Axie Infinity Esports scene boasts major prizes and worldwide events hosted in multiple languages."
      />
      <Page
        headerImg="/images/banners/sky.jpg"
        title="Esports"
        headerText="Top Axie Infinity players compete in tournaments and live events to win major prizes."
        headerHeight={320}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={7}>
            <Section>
              <Title>Competitive Gameplay</Title>
              <Text large={true}>
                Calling all Axie players! If you consider yourself to be a top
                player, why not register to compete in an upcoming Esports
                event? In addition to eternal glory, you might take home a
                sizable prize ranging from bags of crypto to rare Axies!
              </Text>

              <Text large={true}>
                The 2023 calendar is absolutely packed with exciting Axie
                Infinity Esports action from all around the globe.
              </Text>

              <Text large={true}>
                Still learning the ropes? Esports events are a great way to
                learn new strategies and connect with the community.
              </Text>
              <Text large={true}>Join the community, and join the action!</Text>
              <Button
                href={ESPORTS_SCHEDULE_URL}
                target="_blank"
                variant="contained"
                endIcon={<FaPlay />}
                sx={{ alignSelf: "flex-start" }}
              >
                Axie Esports Schedule
              </Button>
            </Section>
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <Section
              info={
                <Info>
                  Be sure to bookmark the schedules and check back often for new
                  events!
                </Info>
              }
            >
              <Image
                src="/images/pages/esports/axieEsports.webp"
                alt="Axie Infinity Esports 2023"
                borderRadius={3}
                boxShadow={true}
                scale={1.06}
                href={ESPORTS_SCHEDULE_URL}
              />
              <Image
                src="/images/pages/esports/twitter.png"
                alt="Axie Infinity Esports Twitter"
                scale={1.06}
                href={AXIE_INFINITY_ESPORTS_TWITTER}
              />
            </Section>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default Esports;
