import React from "react";
import { Stack, useTheme } from "@mui/material";
import Label from "components/Typography/Label";
import BubbleText from "pages/NewPlayers/molecules/BubbleText";

const RunesCharms = () => {
  const theme = useTheme();
  return (
    <Stack
      id="runes and charms"
      direction="row"
      alignItems="center"
      sx={{
        position: "relative",
        mt: 4,
        mb: 8,
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          gap: 2,
          mb: 12,
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          gap: 2,
          mb: 15,
        },
      }}
    >
      <Stack
        maxWidth="100%"
        gap={2}
        alignItems="center"
        sx={{
          position: "relative",
          left: 10,
          top: 61,
          img: { width: 200 },
          [theme.breakpoints.down("md")]: {
            position: "absolute",
            right: 0,
            top: "initial",
            bottom: 0,
            transform: "translateY(90%)",
          },
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
            position: "absolute",
            right: 0,
            top: 210,
          },
        }}
      >
        <img src="/images/pages/new-players/charms.png" alt="Charms" />
        <Label>Charms</Label>
      </Stack>
      <BubbleText>
        You can customize and enhance your Axie's abilities using special items
        called Runes and Charms.
      </BubbleText>

      <Stack
        id="runes"
        gap={2}
        alignItems="center"
        sx={{
          position: "relative",
          left: -26,
          top: -38,
          ml: -4,
          img: { width: 220 },
          [theme.breakpoints.down("md")]: {
            position: "absolute",
            left: 0,
            top: "initial",
            bottom: 0,
            ml: 0,
            transform: "translateY(90%)",
          },
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            top: -2030,
            right: 170,
          },
        }}
      >
        <img src="/images/pages/new-players/runes.png" alt="Runes" />
        <Label>Runes</Label>
      </Stack>
    </Stack>
  );
};

export default RunesCharms;
