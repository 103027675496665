import React from "react";
import { Stack } from "@mui/material";
import SubTitle from "../../components/Typography/SubTitle";
import CapsLabel from "../../components/Typography/CapsLabel";
import Label from "../../components/Typography/Label";
import { useTheme } from "@mui/system";
import { getColorForAxieClass, getIconForAxieClass } from "../../helpers/axie";

const NameAndCreated = ({ item }) => {
  const theme = useTheme();
  return (
    <Stack
      gap={1}
      sx={{
        minWidth: 160,
        width: 160,
        [theme.breakpoints.down("lg")]: {
          pt: 4,
        },
        [theme.breakpoints.down("md")]: {
          alignItems: "center",
          width: "100%",
        },
      }}
    >
      <SubTitle>{item.name}</SubTitle>
      {(item.spawnedFromCards != null && item.spawnedFromCards.length > 0) ||
        (item.spawnedFromRunes != null && item.spawnedFromRunes.length > 0 && (
          <CapsLabel>Created by:</CapsLabel>
        ))}
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        flexWrap="wrap"
        sx={{
          [theme.breakpoints.down("md")]: {
            justifyContent: "center",
          },
        }}
      >
        {item.spawnedFromCards.map((c, i) => (
          <Label
            key={`SPAWN_CARD_${i}`}
            color={getColorForAxieClass(c.class)}
            icon={getIconForAxieClass(c.class, "#fff")}
            style={{
              maxWidth: 180,
            }}
            textStyle={{
              [theme.breakpoints.down("md")]: {
                textAlign: "center",
              },
            }}
            whiteSpace="wrap"
          >
            {c.cardName}
          </Label>
        ))}
        {item.spawnedFromRunes != null &&
          item.spawnedFromRunes.map((r, i) => (
            <Label
              key={`SPAWN_RUNE_${i}`}
              style={{
                maxWidth: 180,
              }}
              textStyle={{
                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                },
              }}
              whiteSpace="wrap"
            >
              {r.name}
            </Label>
          ))}
        {item.spawnedFromSpecial != null &&
          item.spawnedFromSpecial.map((t, i) => (
            <Label
              key={`SPAWN_SPECIAL_${i}`}
              style={{
                maxWidth: 180,
                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                },
              }}
              textStyle={{
                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                },
              }}
              whiteSpace="wrap"
            >
              {t}
            </Label>
          ))}
      </Stack>
    </Stack>
  );
};

export default NameAndCreated;
