import React from "react";
import Text from "../../components/Typography/Text";
import Title from "../../components/Typography/Title";
import { Box, Button, Stack } from "@mui/material";
import { useTheme } from "@mui/system";
import { FaDiscord, FaTwitch, FaTwitter } from "react-icons/fa";
import {
  AXIE_INFINITY_DISCORD,
  AXIE_INFINITY_TWITCH,
  AXIE_INFINITY_TWITTER,
} from "../../config";

const Community = () => {
  const theme = useTheme();

  return (
    <Box sx={{ px: 2 }}>
      <Box
        sx={{
          backgroundImage: `url('/images/banners/workshop.jpg')`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 500,
          position: "relative",
          borderRadius: "32px",
          mb: 2,
        }}
      >
        <Box sx={{ p: 4, position: "relative" }}>
          <Stack
            gap={3}
            sx={{
              background: "#fff",
              borderRadius: 4,
              py: 2,
              px: 4,
              left: 0,
              right: 0,
              maxWidth: 400,
              width: "80%",
              m: "0 auto",
              position: "absolute",
              boxShadow: `0 4px ${theme.palette.colors.shadow}`,
            }}
          >
            <Stack gap={1}>
              <Title textAlign="center">Join the Community</Title>
              <Text textAlign="center">
                Connect with other Axie Trainers during your adventures in
                Lunacia.
              </Text>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              justifyContent="space-between"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                },
              }}
            >
              <Button
                variant="contained"
                size="small"
                href={AXIE_INFINITY_DISCORD}
                endIcon={<FaDiscord />}
                target="_blank"
              >
                Discord
              </Button>

              <Button
                variant="contained"
                size="small"
                href={AXIE_INFINITY_TWITTER}
                endIcon={<FaTwitter />}
                target="_blank"
              >
                Twitter
              </Button>

              <Button
                variant="contained"
                size="small"
                href={AXIE_INFINITY_TWITCH}
                endIcon={<FaTwitch />}
                target="_blank"
              >
                Twitch
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Community;
