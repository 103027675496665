import React from "react";
import { Grid, Link, Stack, Button } from "@mui/material";
import {
  axiesDropdownItems,
  gameDropdownItems,
} from "../../Navigation/navigation.links";
import { Link as RouterLink } from "react-router-dom";
import {
  AXIE_TECH_GUILD_DISCORD,
  CARD_EXPLORER_URL,
  CHARM_EXPLORER_URL,
  INSPECTOR_URL,
  RUNE_EXPLORER_URL,
  TEAM_BUILDER_URL,
} from "config";
import { FaGraduationCap, FaPlay } from "react-icons/fa";
import TitledLinks from "../molecules/TitledLinks";
import FooterColumn from "../atoms/FooterColumn";

const FooterLinks = () => {
  return (
    <Grid container spacing={4}>
      <FooterColumn>
        <Link
          component={RouterLink}
          to="/"
          sx={{ img: { width: "80%", maxWidth: 200 } }}
        >
          <img src="/images/logo.png" alt="Axie Tech Logo" />
        </Link>

        <Stack gap={2}>
          <Button
            variant="contained"
            to="/guides"
            size="small"
            endIcon={<FaPlay />}
            sx={{ alignSelf: "flex-start" }}
            component={RouterLink}
          >
            View Guides
          </Button>
          <Button
            variant="contained"
            href={AXIE_TECH_GUILD_DISCORD}
            target="_blank"
            size="small"
            endIcon={<FaGraduationCap />}
            sx={{ alignSelf: "flex-start" }}
          >
            Join Guild
          </Button>
        </Stack>
      </FooterColumn>

      <FooterColumn>
        <TitledLinks
          title="Getting Started"
          links={[{ text: "New Players", to: "/new-players" }]}
        />
        <TitledLinks title="Axies" links={axiesDropdownItems} />
      </FooterColumn>

      <FooterColumn>
        <TitledLinks title="Game" links={gameDropdownItems} />
      </FooterColumn>

      <FooterColumn>
        <TitledLinks
          title="More Tools"
          links={[
            {
              text: "Card Explorer",
              href: CARD_EXPLORER_URL,
            },
            {
              text: "Rune Explorer",
              href: RUNE_EXPLORER_URL,
            },
            {
              text: "Charm Explorer",
              href: CHARM_EXPLORER_URL,
            },
            {
              text: "Axie Inspector",
              href: INSPECTOR_URL,
            },
            {
              text: "Team Builder",
              href: TEAM_BUILDER_URL,
            },
          ]}
        />
      </FooterColumn>
    </Grid>
  );
};

export default FooterLinks;
