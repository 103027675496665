export const questions = [
  {
    question: "What are Runes?",
    answer:
      "Runes are items that give Axies unique passive effects, which activate automatically in battle.",
  },
  {
    question: "Where can I get Runes?",
    answer:
      "Many Runes can be crafted using in-game resources such as Moon Shards. Other Runes can only be obtained from in-game achievements and events.",
  },
  {
    question: "How many Runes can I equip per Axie?",
    answer: "You can only equip 1 Rune per Axie.",
  },
  {
    question: "Can I equip the same rune on more than one Axie?",
    answer:
      "Yes, the same Rune can be installed on more than one Axie as long as you have more than one copy in your Inventory.",
  },
  {
    question: "Can I trade Runes on the Marketplace?",
    answer:
      "Runes come in two varieties: NFT and non-NFT. NFT Runes can be traded on the Marketplace.",
  },
  {
    question: "What's the difference between NFT and non-NFT Runes?",
    answer:
      "NFT Runes can be traded on the Marketplace. NFT Runes have specific Classes, while non-NFT Runes are Neutral.",
  },
  {
    question: "What are Seasonal Runes?",
    answer:
      "With every Season, new Seasonal Runes will be added, while some older Seasonal Runes will be deprecated. Deprecated Runes cannot be crafted or used in battle, but can still be kept in your collection.",
  },
  {
    question: "What are non-Seasonal Runes?",
    answer: "Non-Seasonal Runes will not be deprecated in future Seasons.",
  },
];
