import { Grid, Button } from "@mui/material";
import React from "react";
import Page from "../../components/Page/Page";
import Section from "../../components/Layout/Section";
import Title from "../../components/Typography/Title";
import SubTitle from "../../components/Typography/SubTitle";
import Text from "../../components/Typography/Text";
import Image from "../../components/Image/Image";
import Info from "../../components/Info/Info";
import {
  AXIE_CODE_OF_CONDUCT,
  AXIE_INFINITY_DISCORD,
  AXIE_INFINITY_TWITTER,
  BUILDERS_PROGRAM_URL,
  BUILDERS_PROGRAM_APPLICATION,
} from "../../config";
import CustomLink from "../../components/CustomLink/CustomLink";
import CommunityBanner from "../Home/CommunityBanner";
import PageSEO from "../../components/SEO/PageSEO";
import { FaPlay } from "react-icons/fa";

const Community = () => {
  return (
    <>
      <PageSEO
        title="Community | Axie.Tech"
        description="By joining the Axie Infinity Community, you join a digital nation of builders and dreamers who believe in a better future."
      />
      <Page
        headerImg="/images/banners/sky.jpg"
        title="Community"
        headerText="Community engagement and co-creation are the foundation of the Axie Infinity ecosystem."
        headerHeight={320}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8}>
            <Section>
              <Title>Engagement &amp; Co-Creation</Title>

              <Text large={true}>
                Axie Infinity is a groundbreaking experiment in gaming,
                blockchain technology and community-building. By joining the
                Axie Community, you join a digital nation of builders and
                dreamers who believe in a better future. Collectively, we are
                forging a new path and changing the course of gaming – and the
                world – forever.
              </Text>
              <Text large={true}>
                If you have technical or creative skills and passions, consider
                applying to one of the Axie community programs:
              </Text>

              <SubTitle>Community Developers</SubTitle>
              <Text large={true}>
                The
                <CustomLink href={BUILDERS_PROGRAM_URL}>
                  Axie Infinity Builders Program
                </CustomLink>
                launched in January, 2022 to encourage the development of gaming
                experiences and tools within the Axie universe.
              </Text>
              <Button
                href={BUILDERS_PROGRAM_APPLICATION}
                variant="contained"
                endIcon={<FaPlay />}
                sx={{ maxWidth: "470px" }}
              >
                apply to the builders program
              </Button>

              <SubTitle>Community Creators</SubTitle>

              <Text large={true}>
                The Axie Infinity Creator's Program will reward content creators
                for their contributions to the network. This program is still
                under development to ensure that it is beneficial to to the
                long-term success of both individual creators and the ecosystem
                as a whole.
              </Text>
            </Section>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Section
              info={
                <Info>
                  Everyone is welcome within the Axie Infinity Twitter and
                  Discord communities. Before joining, be sure to read our
                  <CustomLink href={AXIE_CODE_OF_CONDUCT} endSpace={false}>
                    Code of Conduct
                  </CustomLink>
                  , which represents the Axie Infinity core ethos and the values
                  we wish to promote in this space.
                </Info>
              }
            >
              <SubTitle>Join the Community:</SubTitle>
              <Image
                src="/images/pages/community/twitter.png"
                alt="Axie Infinity Twitter"
                scale={1.06}
                href={AXIE_INFINITY_TWITTER}
              />
              <Image
                src="/images/pages/community/discord.png"
                alt="Axie Infinity Discord"
                scale={1.06}
                href={AXIE_INFINITY_DISCORD}
              />
            </Section>
          </Grid>
        </Grid>
      </Page>
      <CommunityBanner />
    </>
  );
};

export default Community;
