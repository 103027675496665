import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import ClassIcon from "../ClassIcon/ClassIcon";
import Paper from "../Paper/Paper";
import Text from "../Typography/Text";
import Title from "../Typography/Title";
import NamePlate from "./NamePlate";

const ExploreAxies = ({ axies, description }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        mx: 8,
        [theme.breakpoints.down("sm")]: {
          mx: 0,
        },
      }}
      id="explore"
    >
      <Paper>
        <Stack
          justifyContent="center"
          alignItems="center"
          gap={8}
          sx={{
            pt: 6,
            pb: 8,
            px: 4,
            [theme.breakpoints.down("sm")]: {
              px: 1,
            },
          }}
        >
          <Stack gap={2} sx={{ maxWidth: 500, m: "0 auto" }}>
            <Title color="text.brown" textAlign="center" accent={true}>
              Explore Starter Axies
            </Title>
            {description != null && (
              <Text textAlign="center" fontWeight={500}>
                {description}
              </Text>
            )}
          </Stack>

          <Stack
            direction="row"
            gap={8}
            flexWrap="wrap"
            justifyContent="center"
            alignItems="flex-end"
          >
            {axies.map((axie, i) => (
              <Axie
                name={axie.name}
                axieClass={axie.class}
                key={`STARTER_AXIE_${i}`}
              />
            ))}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

const Axie = ({ name, axieClass }) => {
  const slug = name.toLowerCase().replace(" ", "-");

  return (
    <Link to={`/starter-axie/${slug}`} sx={{}}>
      <Stack
        gap={2}
        sx={{
          position: "relative",
          transition: "0.2s transform ease",
          "&:hover": { transform: "scale(1.05)" },
        }}
      >
        <ClassIcon
          axieClass={axieClass}
          style={{ position: "absolute", right: -8, top: -6 }}
        />
        <Box
          sx={{
            minHeight: 135,
            "& img": {
              width: 200,
            },
          }}
        >
          <img
            src={`/images/starter-axies/${slug}.png`}
            alt={`Starter Axie - ${name}`}
          />
        </Box>

        <NamePlate>
          <Typography variant="h3">{name}</Typography>
        </NamePlate>
      </Stack>
    </Link>
  );
};

export default ExploreAxies;
