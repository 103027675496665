import React from "react";
import Helmet from "react-helmet";

const SEO = (props) => {
  const title = "Welcome | Axie.Tech";
  const description =
    "Welcome to Axie Infinity: Origins! Check out our guides and resources for new and returning players.";
  const url = "https://welcome.axie.tech";
  const siteName = "Axie.Tech";

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="axie infinity, axie infinity origin, axie tech, metaverse, play and earn gaming, crypto gaming, guides, resources, new player, guides, how to, walkthrough"
      />

      {/* OpenGraph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta
        property="og:image"
        content="https://welcome.axie.tech/images/social/axie-tech-facebook.jpg"
      />
      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@axie_tech" />
      <meta
        name="twitter:image"
        content="https://welcome.axie.tech/images/social/axie-tech-twitter.jpg"
      />
      <meta name="twitter:creator" content="@axie_tech" />
    </Helmet>
  );
};

export default SEO;
