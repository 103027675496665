import React from "react";
import { Typography } from "@mui/material";

const CapsLabel = ({ children }) => {
  return (
    <Typography
      variant="span"
      fontWeight={800}
      color="text.muted"
      fontSize={17}
    >
      {children}
    </Typography>
  );
};

export default CapsLabel;
