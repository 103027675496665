import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Title from "../../components/Typography/Title";
import { FaPlay } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import MarketplaceButton from "../MarketplaceButton/MarketplaceButton";
import { useTheme } from "@mui/system";

const CallToAction = ({
  title,
  description,
  buttonText,
  buttonTo,
  buttonHref,
  children,
  marketplaceButton,
  axie = "olek",
}) => {
  const theme = useTheme();
  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          backgroundImage: "url('/images/banners/background-footer.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          pt: 40,
          pb: 60,
          px: 2,
          position: "relative",
          borderRadius: "24px",
          mt: -16,
          zIndex: 1,
          [theme.breakpoints.down("md")]: {
            pb: 40,
          },
          [theme.breakpoints.down("sm")]: {
            pb: 14,
            backgroundPosition: "bottom right",
          },
        }}
      >
        <Stack
          direction="row"
          gap={4}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 560,
            m: "0 auto",
            [theme.breakpoints.down("sm")]: {
              width: "auto",
              flexWrap: "wrap",
              px: 1,
              gap: 2,
            },
          }}
        >
          {axie != null && (
            <Box
              sx={{
                img: {
                  width: 150,
                  transform: "rotate(-8deg)",
                  [theme.breakpoints.down("sm")]: {
                    width: 120,
                  },
                },
              }}
            >
              <img
                src={`/images/accents/axies/${axie}-right.png`}
                alt={`${axie}`}
              />
            </Box>
          )}
          <Stack
            alignItems="flex-start"
            gap={4}
            sx={{
              [theme.breakpoints.down("sm")]: {
                alignItems: "center",
                gap: 2,
                "> *": {
                  textAlign: "center",
                },
              },
            }}
          >
            <Stack gap={1}>
              <Title
                style={{
                  [theme.breakpoints.down("sm")]: {
                    justifyContent: "center",
                    "> *": {
                      fontSize: 32,
                      textAlign: "center",
                    },
                  },
                }}
              >
                {title}
              </Title>
              <Typography
                fontSize={20}
                fontWeight={500}
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    fontSize: 17,
                    textAlign: "center",
                  },
                }}
              >
                {description}
              </Typography>
            </Stack>
            {marketplaceButton != null ? (
              <MarketplaceButton />
            ) : buttonTo != null ? (
              <Button
                to={buttonTo}
                variant="contained"
                endIcon={<FaPlay />}
                component={RouterLink}
              >
                {buttonText}
              </Button>
            ) : (
              <Button
                href={buttonHref}
                variant="contained"
                endIcon={<FaPlay />}
                target="_blank"
              >
                {buttonText}
              </Button>
            )}
            {children}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default CallToAction;
