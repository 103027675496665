import { Stack, Grid, Container, Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { axies } from "components/ExploreAxies/axieData";
import ExploreAxies from "components/ExploreAxies/ExploreAxies";
import { makeCardSummary, getRandomNStarterAxies } from "helpers/axie";
import NotFound from "./NotFound";
import Info from "./Info";
import Cards from "./Cards";
import Axie from "./Axie";
import Story from "./Story";
import { connect } from "react-redux";

const StarterAxie = ({ cardData, fetchingCards }) => {
  const theme = useTheme();
  const [axie, setAxie] = useState(undefined);
  const { slug } = useParams();
  const cardSummary = makeCardSummary(axie, cardData);

  useEffect(() => {
    if (slug in axies) {
      setAxie(axies[slug]);
    } else {
      setAxie(undefined);
    }
  }, [slug]);

  return axie == null ? (
    <NotFound />
  ) : (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          opacity: 0.3,
          backgroundImage: "url('/images/banners/background-forest.jpg')",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          width: "100%",
          height: "80vh",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          background:
            "linear-gradient(180deg, rgba(255, 252, 249, 0) 80%, #FFFCF9 100%)",
          width: "100%",
          height: "80vh",
          zIndex: 2,
        }}
      />
      <Stack
        gap={20}
        sx={{
          pt: 20,
          position: "relative",
          zIndex: 2,
          [theme.breakpoints.down("sm")]: {
            pt: 10,
          },
        }}
      >
        <Stack gap={12}>
          <Container fixed>
            <Grid container spacing={8}>
              <Info
                axie={axie}
                potentialPoints={cardSummary?.potentialPoints}
                fetchingCards={fetchingCards}
              />
              <Axie axie={axie} />
              {axie.story === null ? null : <Story axie={axie} />}
            </Grid>
          </Container>

          <Cards
            cards={axie.cards}
            cardData={cardData}
            fetchingCards={fetchingCards}
            cardStats={cardSummary?.cardStats}
            cardTags={cardSummary?.cardTags}
          />
        </Stack>

        <ExploreAxies axies={getRandomNStarterAxies(3, axie.name)} />
      </Stack>
    </Box>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  cardData: state.data.cards,
  fetchingCards: state.data.fetchingCards,
});

export default connect(mapStateToProps, mapDispatchToProps)(StarterAxie);
