import React from "react";
import Welcome from "./Welcome";
import Axies from "./Axies";
import BattleMechanics from "./BattleMechanics";
import InfinitePossibilities from "../../../components/InfinitePossibilities/InfinitePossibilities";
import HowToPlay from "./HowToPlay";
import { Stack, Box } from "@mui/material";
import PageSEO from "../../../components/SEO/PageSEO";

const GameplayGuide = () => {
  return (
    <>
      <PageSEO
        title="Gameplay Guide | Axie.Tech"
        description="A comprehensive guide to playing Axie Infinity: Origins, explaining Axies, How to Play and detailed Battle Mechanics."
      />
      <Box
        sx={{
          backgroundImage:
            "url('/images/accents/pattern-decorative-light.png')",
          backgroundPosition: "center center",
          backgroundRepeat: "repeat-x",
          backgroundSize: "contain",
          height: 100,
          width: "100%",
        }}
      />
      <Stack gap={{ xs: 10, sm: 10, md: 16 }} sx={{ pt: 8 }}>
        <Welcome />
        <Stack>
          <Stack
            sx={{
              maxWidth: 1600,
              m: "0 auto",
              overFlow: "hidden",
            }}
          >
            <Axies />
            <HowToPlay />
          </Stack>
        </Stack>
        <BattleMechanics />

        <InfinitePossibilities />
      </Stack>
    </>
  );
};

export default GameplayGuide;
