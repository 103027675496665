import { Typography } from "@mui/material";
import React from "react";
import ListItem from "../../../../components/List/ListItem";
import List from "../../../../components/List/List";

const EarlyRounds = () => {
  return (
    <>
      <ListItem minimal={true}>
        Players choose to go 1st or 2nd. If both players select the same option
        they will play Rock, Paper, Scissors to decide who goes first.
      </ListItem>
      <ListItem minimal={true}>
        As Origins is a turn based game, the player who goes first is at an
        advantage. To offset this, energy and cards scale as follows:
      </ListItem>
      <ListItem minimal={true}>
        <Typography variant="span" fontWeight={800}>
          Player 1:
        </Typography>{" "}
        <List>
          <ListItem>1st Turn - 1 energy & 3 cards</ListItem>
          <ListItem>2 & 3rd Turns - 2 energy & 4 cards</ListItem>
        </List>
        <Typography variant="span" fontWeight={800}>
          Player 2:
        </Typography>{" "}
        <List>
          <ListItem>1st Turn - 2 energy & 4 cards</ListItem>
          <ListItem>2 & 3rd Turns - 3 energy & 5 cards</ListItem>
        </List>
      </ListItem>
      <ListItem minimal={true}>
        <Typography variant="span" fontWeight={800}>
          Turn 2:
        </Typography>{" "}
        1st player to go gains 2 Energy and draws 4 Cards | 2nd player to go
        gains 3 Energy and draws 4 Cards
      </ListItem>
      <ListItem minimal={true}>
        <Typography variant="span" fontWeight={800}>
          Onwards:
        </Typography>{" "}
        From this point on both players get 3 energy & 5 cards to play with.
      </ListItem>
    </>
  );
};

export default EarlyRounds;
