import { Button, Link, Stack, Box, Container } from "@mui/material";
import React from "react";
import Info from "../../components/Info/Info";
import SubTitle from "../../components/Typography/SubTitle";
import Text from "../../components/Typography/Text";
import Title from "../../components/Typography/Title";
import { RiMovieFill } from "react-icons/ri";
import { FaStar } from "react-icons/fa";
import { MARKETPLACE_URL } from "../../config";
import CustomLink from "../../components/CustomLink/CustomLink";
import { useTheme } from "@mui/system";

const BuyAxies = () => {
  const theme = useTheme();
  return (
    <>
      <Container>
        <Stack gap={4} id="buy-axies">
          <Stack
            sx={{
              backgroundImage: "url('/images/accents/blue-banner.jpg')",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              p: 4,
              borderRadius: 4,
            }}
          >
            <Title color="text.white">Buying Axies on the Marketplace</Title>
          </Stack>

          <Text large={true}>
            The Marketplace is where you can purchase Axies with a
            cryptocurrency called Ether (ETH). Let's learn how to create your
            wallet, add Ether and buy your first Axies!
          </Text>

          <Stack gap={2}>
            <SubTitle>1. Create your Wallet</SubTitle>

            <Text>
              First, you'll need to create a Ronin Wallet. Your Ronin Wallet
              will store your crypto funds and NFT items – including Axies!
            </Text>

            <Text>
              Follow the steps in the official{" "}
              <Link
                href="https://welcome.skymavis.com/create-wallet/"
                target="_blank"
              >
                Axie Infinity Ronin Wallet tutorial
              </Link>{" "}
              to get set up.
            </Text>

            <Info small={true}>
              Think of your Ronin Wallet like a boarding pass to the digital
              Axie nation.
            </Info>
          </Stack>

          <Stack gap={2}>
            <SubTitle>2. Get ETH</SubTitle>

            <Text>
              Next, you'll need to deposit some Ether (ETH) into your Ronin
              Wallet.
            </Text>

            <Text>
              You can either buy ETH on the Ronin network or transfer ETH from the Ethereum
              network:
            </Text>

            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap={4}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                },
              }}
            >
              <Stack
                gap={2}
                sx={{ p: 2, background: "#FFF3C3", borderRadius: "16px" }}
              >
                <SubTitle icon={<FaStar />} variant="h4" fontSize={20}>
                  Buy ETH
                </SubTitle>
                <Text>
                  Buy ETH directly on Ronin using the{" "}
                  <Link href="https://purchase.roninchain.com/" target="_blank">
                    Ramp Network
                  </Link>
                  . You can choose to pay via bank transfer or credit card.
                </Text>

                <Button
                  href="https://youtu.be/mVgwiAPTMT0?t=77"
                  target="_blank"
                  variant="contained"
                  size="small"
                  endIcon={<RiMovieFill />}
                >
                  Watch Tutorial
                </Button>
              </Stack>

              <Stack
                gap={2}
                sx={{ p: 2, background: "#FFF3C3", borderRadius: "16px" }}
              >
                <SubTitle icon={<FaStar />} variant="h4" fontSize={20}>
                  Transfer ETH
                </SubTitle>

                <Text>
                  If you already have ETH on the Ethereum network, you can use
                  the{" "}
                  <Link href="https://bridge.roninchain.com/" target="_blank">
                    Ronin Bridge
                  </Link>{" "}
                  to deposit your ETH onto Ronin.
                </Text>
                <Button
                  href="https://youtu.be/Y_7KZJyjG8k?t=746"
                  target="_blank"
                  variant="contained"
                  size="small"
                  endIcon={<RiMovieFill />}
                >
                  Watch Tutorial
                </Button>
              </Stack>
            </Box>
          </Stack>

          <Stack gap={2}>
            <SubTitle>3. Choose your Axies</SubTitle>

            <Text>
              Visit the
              <CustomLink href={MARKETPLACE_URL}>
                Official Axie Marketplace
              </CustomLink>
              and pick out your Axies! Use the filters on the left to narrow
              down your search.
            </Text>

            <Info>
              Not sure which Axies to buy? Check out our
              <CustomLink to="/guides/builds">Builds Guide</CustomLink>
              to learn more about commonly used Axies.
            </Info>
          </Stack>

          <Stack gap={2}>
            <SubTitle>4. Sync &amp; Play!</SubTitle>
            <Text>
              Once you've purchased your 3 Axies, you'll need to sync them so
              that they appear in game.
            </Text>

            <Text>
              Open the Axie Infinity game application (either on your computer
              or your phone), click Axies, then click Sync.
            </Text>

            <Text>Now, you're ready to battle!</Text>
          </Stack>
        </Stack>
      </Container>
      <Box
        sx={{
          backgroundImage: "url('/images/banners/personal-axies.png')",
          backgroundPosition: "center center",
          backgroundSize: "contain",
          backgroundRepeat: "repeat-x",
          mt: -10,
          mb: -8,
          height: 80,
        }}
      />
    </>
  );
};

export default BuyAxies;
