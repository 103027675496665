import React, { useState } from "react";
import { Stack, Box } from "@mui/material";
import Page from "../../components/Page/Page";
import Effect from "./Effect";
import { useTheme } from "@mui/system";
import { connect } from "react-redux";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import PageSEO from "../../components/SEO/PageSEO";
import Search from "./Search";
import DecorativeBackground from "./DecorativeBackground";
import { getStatusEffects } from "../../store/statusEffects/statusEffectsSelectors";
import { updateSelected } from "../../store/statusEffects/statusEffectsActions";
import PageTitle from "../../components/Typography/PageTitle";
import SectionHeader from "../../components/Layout/SectionHeader";

const StatusEffects = ({ statusEffects, updateSelected, selectedValues }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");

  if (statusEffects.loading) {
    return (
      <PageWrapper>
        <CustomLoader centered={true} />
      </PageWrapper>
    );
  }
  return (
    <>
      <PageSEO
        title="Status Effects | Axie.Tech"
        description="Axie Infinity: Origins introduces new buffs and debuffs that can effect Axies during battle."
      />
      <PageWrapper>
        <Stack
          gap={12}
          sx={{
            minHeight: "80vh",
          }}
        >
          <SectionHeader
            style={{
              mt: -9,
              [theme.breakpoints.down("lg")]: {
                mt: -4,
              },
            }}
          >
            <PageTitle>Status Effects</PageTitle>
            <Text large={true} textAlign="center">
              Look out! Floating icons mean that positive or negative status
              effects have been applied to your Axies.
            </Text>
            <Search
              selectedValues={selectedValues}
              setSelectedValues={updateSelected}
              inputValue={inputValue}
              setInputValue={setInputValue}
              options={statusEffects.all}
            />
          </SectionHeader>
          <Effects
            effects={statusEffects.buffs}
            title="Buffs"
            description="Positive status effects."
            id="buffs"
          />
          <Effects
            effects={statusEffects.debuffs}
            title="Debuffs"
            description="Negative status effects."
            id="debuffs"
          />
          <Effects
            effects={statusEffects.neutral}
            title="Neutral"
            description="Neutral status effects."
            id="neutral"
          />
        </Stack>
      </PageWrapper>

      <DecorativeBackground />
    </>
  );
};

const PageWrapper = ({ children }) => (
  <Page headerImg="/images/banners/status-effects.jpg" headerHeight={280}>
    {children}
  </Page>
);

const Effects = ({ effects, title, description, id }) => {
  return effects.length === 0 ? (
    <></>
  ) : (
    <Stack gap={4} id={id}>
      <Stack gap={1}>
        <Title>{title}</Title>
        <Text large={true}>{description}</Text>
      </Stack>
      <Gallery>
        {effects.map((effect, i) => {
          return (
            <Effect
              key={`BUFF_${i}`}
              name={effect.name}
              description={effect.description}
              icon={`/images/status-effects/${effect.image}`}
            />
          );
        })}
      </Gallery>
    </Stack>
  );
};

const Gallery = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: 32,
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
      }}
    >
      {children}
    </Box>
  );
};

const mapDispatchToProps = { updateSelected };

const mapStateToProps = (state) => ({
  statusEffects: getStatusEffects(state),
  selectedValues: state.statusEffects.selectedValues,
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusEffects);
