import { Button, Container, Stack, useTheme } from "@mui/material";
import React from "react";
import FAQ from "components/FAQ/FAQ";
import PageTitle from "components/Typography/PageTitle";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { questions } from "./questions";
import CallToAction from "components/CallToAction/CallToAction";
import { HashLink } from "react-router-hash-link";
import { FaPlay } from "react-icons/fa";
import ExploreAxies from "components/ExploreAxies/ExploreAxies";
import { axies } from "components/ExploreAxies/axieData";
import Page from "components/Page/Page";
import PageSEO from "components/SEO/PageSEO";
import Intro from "./Intro";
import "./video.css";

const StarterAxies = () => {
  const theme = useTheme();
  return (
    <>
      <PageSEO
        title="Starter Axies | Axie.Tech"
        description="All players receive free Starter Axies to battle with during their adventures in Lunacia."
      />
      <Page container={false}>
        <Container>
          <Stack sx={{ pb: 2 }}>
            <Stack
              gap={1}
              sx={{
                maxWidth: 600,
                mb: 5,
                position: "relative",
                zIndex: 2,
                [theme.breakpoints.down("sm")]: {
                  mb: -2,
                },
              }}
            >
              <PageTitle textAlign="left">Starter Axies</PageTitle>

              <Stack gap={3} alignItems="flex-start">
                <Text large={true}>
                  All players receive free Starter Axies to battle with during
                  their adventures in Lunacia. More Starter Axies are unlocked
                  after specific quests or achievemenets are completed in-game.
                </Text>

                <Button
                  variant="contained"
                  to="#explore"
                  component={HashLink}
                  endIcon={<FaPlay />}
                >
                  Explore Starter Axies
                </Button>
              </Stack>
            </Stack>

            <Intro />
            <Stack gap={5} paddingTop={15}>
              <Stack gap={1}>
                <Title>Starter Axie Upgrades</Title>
                <Text large={true}>
                  As you play, you will earn rewards which allow you to upgrade
                  the parts of your Starter Axies enhancing their skills. Once
                  you have upgraded all 6 parts, you will have the option to
                  promote your Starter Axie!
                </Text>
              </Stack>
              <img
                src="/images/starter-axies/upgrades/tripp-upgrade.png"
                alt="Starter Axie Upgrades"
              />
            </Stack>
          </Stack>
        </Container>
        <ExploreAxies
          axies={Object.keys(axies).map((key) => ({
            name: axies[key].name,
            class: axies[key].class,
          }))}
          description="As you progress in Adventure Mode, you will unlock more Starter
              Axies, each with different traits and abilities. Which one is your
              favorite?"
        />

        <FAQ questions={questions} />
      </Page>
      <CallToAction
        title="New to Axie?"
        description="Check out our gameplay guide and win your first battle!"
        buttonText="Gameplay Guide"
        buttonTo="/guides/gameplay"
      />
    </>
  );
};

export default StarterAxies;
