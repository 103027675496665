import { createSelector } from "reselect";

const getSelectedValues = (state) => state.statusEffects.selectedValues || [];
const getStatusEffectsRaw = (state) => state.data.abilities || [];
const getCardsLoading = (state) => state.data.fetchingCards;

const sortByName = (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

export const getCardProperties = createSelector(
  [getStatusEffectsRaw],
  (statusEffects) => {
    return statusEffects.filter((effect) => effect.type === "Card Property");
  }
);

export const getStatusEffects = createSelector(
  [getSelectedValues, getStatusEffectsRaw, getCardsLoading],
  (selectedValues, statusEffects, loading) => {
    const buffs = [];
    const debuffs = [];
    const neutral = [];
    const all = [];

    const selectedNames = selectedValues.map((e) => e.name);

    statusEffects.forEach((effect) => {
      if (selectedNames.includes(effect.name) || selectedValues.length === 0) {
        if (effect.type === "Buff" || effect.type === "Transform/Buff") {
          buffs.push(effect);
        } else if (effect.type === "Debuff") {
          debuffs.push(effect);
        } else if (effect.type === "Neutral") {
          neutral.push(effect);
        }
      }

      if (effect.type !== "Card Property") {
        if (effect.type === "Transform/Buff") {
          all.push({ ...effect, type: "Buff" });
        } else {
          all.push(effect);
        }
      }
    });

    return {
      buffs: buffs.sort((a, b) => sortByName(a, b)),
      debuffs: debuffs.sort((a, b) => sortByName(a, b)),
      neutral: neutral.sort((a, b) => sortByName(a, b)),
      all: all.sort((a, b) => sortByName(a, b)),
      loading,
    };
  }
);
