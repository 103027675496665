import React from "react";
import Text from "../../../components/Typography/Text";
import Title from "../../../components/Typography/Title";
import { Button, Grid, Box, Stack } from "@mui/material";
import Section from "../../../components/Layout/Section";
import Image from "../../../components/Image/Image";
import SubTitle from "../../../components/Typography/SubTitle";
import {
  BREEDING_CALCULATOR_URL,
  BREEDING_COSTS_URL,
  BREEDING_SIMULATOR_URL,
} from "../../../config";
import { FaPlay } from "react-icons/fa";
import { useTheme } from "@mui/system";

const HelpfulTools = () => {
  const theme = useTheme();
  const tools = [
    {
      title: "Breeding Costs Table",
      description:
        "The Breeding Costs Table shows exactly what resources you need to breed virgin Axies. The total costs automatically update to reflect live ETH, AXS and SLP prices.",
      url: BREEDING_COSTS_URL,
      img: "table",
    },
    {
      title: "Breeding Calculator",
      description:
        "The Breeding Calculator is a more in-depth version of the Breeding Costs table. It allows you to calculate total costs and estimated ROI for complex breeds, accounting for SLP and AXS in your inventory and expected Parent/Offspring sales.",
      url: BREEDING_CALCULATOR_URL,
      img: "calculator",
    },
    {
      title: "Breeding Simulator",
      description:
        "Before you breed, use a Breeding Simulator to check the possible genetic makeup of the offspring. Breeding Sims are a great way to select parent Axies with the highest chance of producing offspring that have the desired genes.",
      url: BREEDING_SIMULATOR_URL,
      img: "simulator",
    },
  ];
  return (
    <Stack gap={8}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4}>
          <Stack gap={1} alignItems="center">
            <Image
              src="/images/pages/guides/breeding/tools.jpg"
              alt="An happy and excited Axie"
              maxWidth={500}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Section>
            <Title>Helpful Tools</Title>
            <Text large={true}>
              Breeding can be complex, particularly if you are trying to create
              offspring with a very specific set of body parts. You also have to
              account for changing AXS and SLP prices, stay on top of the
              current meta builds and track Axie availabilty on the Marketplace.
              On top of all of this, you have to deal with random mutations that
              can sneak into your otherwise perfect lineage!
            </Text>

            <Text large={true}>
              To make your life a bit easier, the Community has created a
              variety of tools that you can use to help plan your breeds.
            </Text>
          </Section>
        </Grid>
      </Grid>

      <Stack gap={4}>
        {tools.map((tool, i) => (
          <Box
            key={`TOOL_${i}`}
            sx={{
              display: "grid",
              gridTemplateColumns: "80px 1fr 220px",
              gridGap: "32px",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "flex-start",
              },
            }}
          >
            <Image
              src={`/images/pages/guides/breeding/${tool.img}.png`}
              alt={tool.title}
              href={tool.url}
              scale={1.1}
              style={{ width: 80 }}
            />
            <Stack gap={1}>
              <SubTitle>{tool.title}</SubTitle>
              <Text large={true}>{tool.description}</Text>
            </Stack>

            <Button
              variant="contained"
              target="_blank"
              href={tool.url}
              size="small"
              endIcon={<FaPlay />}
            >
              {tool.title}
            </Button>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default HelpfulTools;
