import * as types from "./statusEffectsActionTypes";
import produce from "immer";

export const initialState = {
  selectedValues: [],
};

export const statusEffectsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.STATUS_EFFECTS_UPDATE_SELECTED:
        draft.selectedValues = action.values;
        break;

      default:
        break;
    }
  });

export default statusEffectsReducer;
