import { Container, Stack } from "@mui/material";
import React from "react";
import Title from "../../../components/Typography/Title";
import Text from "../../../components/Typography/Text";
import Image from "../../../components/Image/Image";
import Section from "../../../components/Layout/Section";
import CustomLink from "../../../components/CustomLink/CustomLink";
import RankingSystem from "./RankingSystem";
import VictoryStars from "./VictoryStars";
import Stats from "./Stats";

const RankedMode = () => {
  return (
    <Container id="ranked-mode">
      <Stack gap={8}>
        <Section>
          <Stack gap={3} direction="row" alignItems="center">
            <Image
              src="/images/pages/arena/icons/egg.png"
              alt="Ranked Mode"
              maxWidth={100}
            />
            <Title>Ranked Mode</Title>
          </Stack>
          <Text large={true}>
            Ranked Mode has the same battle system as
            <CustomLink hashTo="#practice-mode">Practice Mode</CustomLink>
            plus a ranking system.
          </Text>
        </Section>

        <RankingSystem />

        <VictoryStars />

        <Stats />
      </Stack>
    </Container>
  );
};

export default RankedMode;
