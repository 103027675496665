import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import Image from "../../../components/Image/Image";
import { useTheme } from "@mui/system";
import SubTitle from "../../../components/Typography/SubTitle";
import Text from "../../../components/Typography/Text";

const RankingSystem = () => {
  return (
    <Stack gap={1}>
      <SubTitle>Ranking System</SubTitle>
      <Text large={true}>
        The Ranked Mode ranking system is divided into 8 Ranks, each of which is
        subdivided into 4 Tiers. Beyond these Ranks is the Challenger Sphere.
      </Text>

      <Stack direction="row" gap={1} flexWrap="wrap" sx={{ mt: 2 }}>
        <Rank title="Egg" slug="egg" tiers={4} starsPerTier={40} bonus={0} />
        <Rank
          title="Chick"
          slug="chick"
          tiers={4}
          starsPerTier={40}
          bonus={0}
        />
        <Rank title="Hare" slug="hare" tiers={4} starsPerTier={50} bonus={0} />
        <Rank title="Boar" slug="boar" tiers={4} starsPerTier={50} bonus={0} />
        <Rank title="Wolf" slug="wolf" tiers={4} starsPerTier={60} bonus={10} />
        <Rank title="Bear" slug="bear" tiers={4} starsPerTier={60} bonus={20} />
        <Rank
          title="Tiger"
          slug="tiger"
          tiers={4}
          starsPerTier={70}
          bonus={30}
        />
        <Rank
          title="Dragon"
          slug="dragon"
          tiers={4}
          starsPerTier={80}
          bonus={40}
        />
        <Rank title="Challenger" slug="challenger" bonus={50} />
      </Stack>
    </Stack>
  );
};

const Rank = ({ title, slug, tiers, starsPerTier, bonus }) => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      sx={{
        transition: "0.2s transform ease",
        py: 1,
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.1)",
          "& .seasonalBonus": {
            opacity: 1,
          },
        },
      }}
    >
      <Image
        src={`/images/pages/arena/icons/${slug}.png`}
        alt={title}
        style={{ maxWidth: 116 }}
      />
      <Typography
        fontSize={24}
        fontWeight={500}
        variant="h4"
        color="text.regular"
        sx={{ my: -0.5 }}
      >
        {title}
      </Typography>

      {tiers != null && (
        <>
          <Tooltip
            title={`Collect ${starsPerTier} Victory Stars to climb to the next Tier in Rank ${title}.`}
          >
            <Stack sx={{ p: 1 }}>
              <Stack
                gap={0.5}
                direction="row"
                alignItems="center"
                sx={{
                  borderRadius: 3,
                  p: "4px 8px",
                  background: theme.palette.colors.wood.background,
                }}
              >
                <Label color={theme.palette.colors.star}>{starsPerTier}</Label>
                <Image
                  src={`/images/pages/arena/star.png`}
                  alt={title}
                  maxWidth={15}
                />
                <Label color={theme.palette.colors.star} fontSize={13}>
                  per tier
                </Label>
              </Stack>
            </Stack>
          </Tooltip>

          <Stack alignItems="center" gap={0.5}>
            <Typography
              fontSize={14}
              fontWeight={800}
              fontFamily="'Changa One', sans-serif"
              variant="span"
              color="text.regular"
              textTransform="uppercase"
              letterSpacing="0.5px"
            >
              Tiers:
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridGap: "4px",
                gridTemplateColumns: "1fr",
              }}
            >
              <Label>I</Label>
              <Label>II</Label>
              <Label>III</Label>
              <Label>IV</Label>
            </Box>
          </Stack>
        </>
      )}

      <Tooltip
        title={`If you reach Rank ${title}, you'll receive ${bonus} bonus Victory Star${
          bonus !== 1 ? "s" : ""
        } for each win in the next Season. This bonus is reduced by 1 each time you're promoted to a new Rank.`}
      >
        <Stack sx={{ py: 1 }}>
          <Stack
            className="seasonalBonus"
            sx={{
              borderRadius: 3,
              p: "4px 8px",
              background: theme.palette.colors.primary,
              opacity: 0.8,
            }}
          >
            <Stack
              gap={0.5}
              sx={{ filter: "drop-shadow(0 0 0.5px #000)" }}
              direction="row"
            >
              <Label color="#fff" fontSize={13}>
                Bonus: {bonus}
              </Label>
              <Image
                src={`/images/pages/arena/star.png`}
                alt={title}
                maxWidth={12}
              />
            </Stack>
          </Stack>
        </Stack>
      </Tooltip>
    </Stack>
  );
};

const Label = ({ children, color, fontSize = 16 }) => (
  <Typography
    fontSize={fontSize}
    fontWeight={500}
    variant="span"
    color={color || "text.regular"}
    fontFamily="'Changa One', sans-serif"
    textAlign="center"
  >
    {children}
  </Typography>
);

export default RankingSystem;
