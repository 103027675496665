import React from "react";
import Title from "../../components/Typography/Title";
import Section from "../../components/Layout/Section";
import { Box, Stack, Button, Container } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { FaPlay } from "react-icons/fa";
import { useTheme } from "@mui/system";
import Image from "../../components/Image/Image";
import Info from "../../components/Info/Info";

const PostcardSection = ({
  title,
  info,
  buttons,
  children,
  img,
  alt,
  reverse,
}) => {
  const theme = useTheme();

  return (
    <Container>
      <Stack
        gap={6}
        alignItems="center"
        direction={reverse ? "row-reverse" : "row"}
        justifyContent="center"
        sx={{
          maxWidth: 780,
          position: "relative",
          zIndex: 2,
          mb: 3,
          mx: "auto",
        }}
      >
        <Box
          sx={{
            img: {
              width: 210,
            },
            [theme.breakpoints.down("md")]: {
              img: { width: 120 },
            },
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          <img
            src="/images/pages/new-players/stella.png"
            alt="Welcome, friend"
          />
        </Box>

        <Section>
          <Stack paddingBottom={10} gap={1}>
            <Title color="text.darkBlue" fontSize={48}>
              {title}
            </Title>
            {children}
          </Stack>

          <Stack direction="row" gap={2} flexWrap="wrap">
            {buttons?.map((button, i) => (
              <Button
                component={HashLink}
                variant="contained"
                endIcon={<FaPlay />}
                size="small"
                to={button.to}
                key={`BUTON_${i}`}
              >
                {button.text}
              </Button>
            ))}
          </Stack>
        </Section>
      </Stack>

      <HeaderImage info={info} img={img} alt={alt} reverse={reverse} />
    </Container>
  );
};

const HeaderImage = ({ info, img, alt, reverse }) => {
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      <Image
        src={img}
        alt={alt}
        borderRadius={3}
        plain={true}
        style={{
          position: "relative",
          left: "50%",
          top: reverse ? -10 : -90,
          transform: `${
            reverse ? "rotate(4deg)" : "rotate(-4deg)"
          } translateX(-50%)`,
          width: 900,
          zIndex: 1,
          [theme.breakpoints.down("md")]: {
            top: reverse ? 0 : -50,
          },
          [theme.breakpoints.down("sm")]: {
            transform: "none",
            left: 0,
            top: 0,
          },
        }}
      />

      <Info
        style={{
          position: "absolute",
          zIndex: 2,
          maxWidth: 530,
          right: "5%",
          bottom: 0,
          [theme.breakpoints.down("sm")]: {
            position: "relative",
            top: -10,
            right: 0,
          },
        }}
      >
        {info}
      </Info>
    </Box>
  );
};

export default PostcardSection;
