import { Link, Stack, Box, Button, Container } from "@mui/material";
import React from "react";
import Info from "../../components/Info/Info";
import Text from "../../components/Typography/Text";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Image from "../../components/Image/Image";
import { FaPlay } from "react-icons/fa";
import Title from "../../components/Typography/Title";
import { useTheme } from "@mui/system";

const Obtaining = () => {
  const theme = useTheme();

  return (
    <Container>
      <Stack
        gap={1}
        sx={{
          maxWidth: 600,
          mb: 5,
          position: "relative",
          zIndex: 2,
        }}
      >
        <Title>Your First Personal Axies</Title>
        <Stack gap={3}>
          <Text large={true}>
            You will need at least 3 Personal Axies to play the game. You can
            either:
          </Text>

          <Stack
            direction="row"
            alignItems="center"
            gap={{ xs: 2, sm: 2, md: 4 }}
            flexWrap="wrap"
            sx={{
              [theme.breakpoints.down("sm")]: {
                mb: -7,
              },
            }}
          >
            <Button
              variant="contained"
              to="#buy-axies"
              component={HashLink}
              endIcon={<FaPlay />}
            >
              Buy Axies
            </Button>
            <Button
              variant="contained"
              to="#get-a-scholarship"
              component={HashLink}
              endIcon={<FaPlay />}
            >
              Find a Scholarship
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <HeaderImage />
    </Container>
  );
};

const HeaderImage = () => {
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative" }}>
      <Image
        src="/images/pages/personal-axies/personal-axies.jpg"
        alt="Personal Axies"
        borderRadius={3}
        boxShadow={true}
        style={{
          position: "relative",
          left: "50%",
          top: -90,
          transform: "rotate(-4deg) translate(-50%)",
          width: 900,
          zIndex: 1,
          [theme.breakpoints.down("sm")]: {
            transform: "none",
            position: "initial",
          },
        }}
      />

      <Info
        style={{
          position: "absolute",
          zIndex: 2,
          maxWidth: 530,
          right: 0,
          bottom: -20,
          [theme.breakpoints.down("sm")]: {
            position: "relative",
            bottom: 0,
          },
        }}
      >
        If you're not ready to get your own Personal Axies, don't worry.{" "}
        <Link to="/starter-axies" component={RouterLink}>
          Starter Axies
        </Link>{" "}
        are available for all players to start playing for free.
      </Info>
    </Box>
  );
};

export default Obtaining;
