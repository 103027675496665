import { Box, Tooltip } from "@mui/material";
import React from "react";

const ClassIcon = ({ axieClass, width = 40, style }) => {
  return axieClass == null ? null : (
    <Tooltip title={axieClass}>
      <Box
        sx={{
          "& img": {
            width,
          },
          ...style,
        }}
      >
        <img
          src={`/images/class-icons/${axieClass.toLowerCase()}.png`}
          alt={`Axie ${axieClass} Class`}
        />
      </Box>
    </Tooltip>
  );
};

export default ClassIcon;
