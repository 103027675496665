import MarketplaceButton from "../../components/MarketplaceButton/MarketplaceButton";
import { Link } from "@mui/material";
import { MARKETPLACE_URL } from "../../config";
import { Link as RouterLink } from "react-router-dom";

export const questions = [
  {
    question: "Where do I get Personal Axies?",
    answer: (
      <>
        Personal Axies can be obtained from the{" "}
        <Link href={MARKETPLACE_URL} target="_blank">
          Marketplace
        </Link>
        .
      </>
    ),
    button: <MarketplaceButton />,
  },
  {
    question: "What can I do with my Personal Axies?",
    answer:
      "Personal Axies can be used to battle in Adventure Mode and Arena Mode. They are also able to breed and can be traded with other players on the Marketplace.",
  },
  {
    question: "What’s the difference between Personal Axies and Starter Axies?",
    answer: (
      <>
        Personal Axies are your own unique NFTs (Non-Fugible Tokens) that live
        on the blockchain. They are able to breed, can be traded and can be
        upgraded further than{" "}
        <Link to="/starter-axies" component={RouterLink}>
          Starter Axies
        </Link>
        . While Starter Axies are unlocked in-game, if you want to obtain
        Personal Axies, you'll need to visit the{" "}
        <Link href={MARKETPLACE_URL} target="_blank">
          Marketplace
        </Link>
        .
      </>
    ),
  },
];
