import React from "react";
import Page from "../../components/Page/Page";
import EquippingCharms from "./EquippingCharms";
import PotentialPoints from "./PotentialPoints";
import CharmBasics from "./CharmBasics";
import Seasonality from "./Seasonality";
import FAQ from "../../components/FAQ/FAQ";
import { questions } from "./questions";
import CallToAction from "../../components/CallToAction/CallToAction";
import Diagram from "../../components/Diagram/Diagram";
import NFTvsNonNFT from "./NFTvsNonNFT";
import PageSEO from "../../components/SEO/PageSEO";
import StarterCharms from "./StarterCharms";

const Charms = () => {
  return (
    <>
      <PageSEO
        title="Charms | Axie.Tech"
        description="Charms are Special Items that can be installed into an Axie's Card slots to enhance its abilities."
      />
      <Page
        headerImg="/images/banners/charms.jpg"
        title="Charms"
        headerHeight={300}
        headerText="Charms provide bonus effects to Axie abilities."
        container={false}
      >
        <CharmBasics />
        <EquippingCharms />
        <PotentialPoints />
        <Diagram type="charm" />
        <Seasonality />
        <NFTvsNonNFT />
        <StarterCharms />
        <FAQ questions={questions} />
      </Page>
      <CallToAction
        title="More Power-Ups!"
        description="Learn how to give your Axies special passive effects using Runes."
        buttonText="Runes"
        buttonTo="/runes"
      />
    </>
  );
};

export default Charms;
