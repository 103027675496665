import React from "react";
import CardTemplate from "../../components/GameTemplates/CardTemplate";
import RuneTemplate from "../../components/GameTemplates/RuneTemplate";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/system";

const CreatedWith = ({ item, itemType }) => {
  const theme = useTheme();
  const colorMapping = {
    curse: "linear-gradient(180deg, #730000, #211815)",
    tool: "linear-gradient(180deg, #1b3f30, #1a1c19)",
  };

  return item.spawnedFromCards.length === 0 &&
    (item?.spawnedFromRunes == null || item.spawnedFromRunes.length === 0) ? (
    <></>
  ) : (
    <Stack
      gap={1}
      justifyContent="center"
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      sx={{
        flexGrow: 1,
        background: colorMapping[itemType],
        borderRadius: 3,
        px: 6,
        pt: 3,
        pb: 2,
        ml: -8,
        alignSelf: "stretch",
        [theme.breakpoints.down("lg")]: {
          ml: 0,
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      }}
    >
      {item.spawnedFromCards.map((c, i) => (
        <CardTemplate
          card={c}
          width={180}
          key={`SPAWN_${i}`}
          style={{
            transition: "0.2s transform ease",
            "&:hover": { transform: "scale(1.2)", zIndex: 10 },
          }}
        />
      ))}
      {item.spawnedFromRunes != null &&
        item.spawnedFromRunes.map((r, i) => (
          <RuneTemplate
            rune={r}
            width={180}
            key={`SPAWN_RUNE_${i}`}
            style={{
              transition: "0.2s transform ease",
              "&:hover": { transform: "scale(1.2)", zIndex: 10 },
            }}
          />
        ))}
    </Stack>
  );
};

export default CreatedWith;
