import React from "react";
import Text from "../Typography/Text";
import CustomLink from "../CustomLink/CustomLink";
import { Stack, Box, Grid, Typography } from "@mui/material";
import CenteredContent from "../Layout/CenteredContent";
import CommunityBanner from "../../pages/Home/CommunityBanner";
import DecorativeBanner from "../DecorativeBanner/DecorativeBanner";
import { useTheme } from "@mui/system";

const InfinitePossibilities = () => {
  return (
    <Stack gap={{ xs: 6, sm: 8, md: 10 }} id="infinite-possibilities">
      <DecorativeBanner
        bannerSlug="sky"
        titleSlug="new-players/infinite-possibilities"
        title="Infinite Possibilities"
        titleMaxWidth={600}
      />
      <CenteredContent>
        <Text large={true} textAlign="center">
          Axies are your ticket to endless experiences in the Axie Infinity
          Universe.
        </Text>
        <Stack gap={6}>
          <Battle />
          <Theorycraft />
          <InfiniteExperiences />
        </Stack>
      </CenteredContent>
      <Box sx={{ mt: 10 }}>
        <CommunityBanner />
      </Box>
    </Stack>
  );
};

const Feature = ({ children, to, img, title }) => (
  <CustomLink to={`/${to}`} style={{ "&:hover": { textDecoration: "none" } }}>
    <Stack gap={0.25}>
      <Stack alignItems="center" justifyContent="space-between" gap={0.5}>
        <FeaturedImage img={img} title={title} />
        <Box
          sx={{
            img: {
              height: 30,
            },
          }}
        >
          <img
            src={`/images/pages/new-players/features/${img}-title.png`}
            alt={title}
          />
        </Box>
      </Stack>
      <Text textAlign="center">{children}</Text>
    </Stack>
  </CustomLink>
);

const FeatureHorizontal = ({ children, to, img, title }) => {
  return (
    <CustomLink to={`/${to}`} style={{ "&:hover": { textDecoration: "none" } }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <FeaturedImage img={img} title={title} />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Stack gap={0.5}>
            <Box
              sx={{
                img: {
                  height: 30,
                },
              }}
            >
              <img
                src={`/images/pages/new-players/features/${img}-title.png`}
                alt={title}
              />
            </Box>
            <Text>{children}</Text>
          </Stack>
        </Grid>
      </Grid>
    </CustomLink>
  );
};

const FeaturedImage = ({ img, title }) => (
  <>
    <Box
      sx={{
        flexShrink: 0,
        img: {
          maxWidth: "100%",
          transition: "0.2s transform ease",
          borderRadius: "16px",
          "&:hover": {
            transform: "scale(1.03)",
          },
        },
      }}
    >
      <img src={`/images/pages/new-players/features/${img}.jpg`} alt={title} />
    </Box>
    <Typography variant="h3" sx={{ height: 0, width: 0, visibility: "hidden" }}>
      {title}
    </Typography>
  </>
);

const FeatureTitle = ({ img, alt }) => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      sx={{
        img: {
          height: 60,
          [theme.breakpoints.down("sm")]: {
            height: 50,
          },
        },
      }}
    >
      <img src={`/images/pages/new-players/${img}.png`} alt={alt} />
    </Stack>
  );
};

const Battle = () => (
  <Stack gap={4}>
    <FeatureTitle img="battle" alt="Battle" />
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <Feature img="arena" to="arena" title="Arena">
          Battle against other players for a spot on the leaderboard.
        </Feature>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Feature img="esports" to="esports" title="ESports">
          Compete in an eSports event to win even greater prizes!
        </Feature>
      </Grid>
    </Grid>
  </Stack>
);

const Theorycraft = () => (
  <Stack gap={4}>
    <FeatureTitle img="theorycraft" alt="Theorycraft" />

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <Feature img="axie-builds" to="guides/builds" title="Axie Builds">
          Assemble next Season's strongest Axie builds and team compositions.
        </Feature>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Feature img="breeding" to="guides/breeding" title="Breeding">
          Experiment with Axie genetics to breed and sell desirable Axies on the
          Marketplace.
        </Feature>
      </Grid>
    </Grid>
  </Stack>
);

const InfiniteExperiences = () => (
  <Stack gap={4}>
    <FeatureTitle img="infinite-experiences" alt="Infinite Experiences" />

    <FeatureHorizontal img="collect" to="guides/collectors" title="Collect">
      Explore the Marketplace in search of rare Axies to add to your collection.
      Origin and Mystic Axies are some of the most collectible NFTs thanks to
      their unique aesthetics and capped supply.
    </FeatureHorizontal>

    <FeatureHorizontal img="create" to="guides/creators" title="Create">
      Creators are the backbone of the Axie Infinity community. Whether you
      produce YouTube videos, make art, write guides or host live streams, you
      play a vital role in attracting and onboarding new players to our
      ecosystem.
    </FeatureHorizontal>

    <FeatureHorizontal img="connect" to="community" title="Connect">
      Join your Axie regional server and make local connections and help build
      the future of the Axie universe, together.
    </FeatureHorizontal>
  </Stack>
);

export default InfinitePossibilities;
