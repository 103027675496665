import React from "react";
import { Stack } from "@mui/material";
import Text from "./Text";
import { useTheme } from "@mui/system";

const Label = ({
  children,
  fontSize,
  color,
  icon,
  style,
  whiteSpace = "nowrap",
  textStyle,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      gap={0.5}
      alignItems="center"
      sx={{
        background: color || theme.palette.colors.black,
        borderRadius: "4px",
        p: "2px 6px",
        ...style,
      }}
    >
      {icon}
      <Text
        color="text.white"
        fontWeight={600}
        style={{
          fontSize: fontSize ? fontSize : undefined,
          whiteSpace,
          lineHeight: 1.4,
          ...textStyle,
        }}
      >
        {children}
      </Text>
    </Stack>
  );
};

export default Label;
