import * as types from "./dataActionTypes";
import produce from "immer";

export const initialState = {
  cards: [],
  toolCards: [],
  abilities: [],
  runes: [],
  charms: [],
};

export const dataReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_DATA_CARDS_STARTED:
        draft.fetchingCards = true;
        break;
      case types.FETCH_DATA_CARDS_SUCCEEDED:
        draft.cards = action.data.cards;
        draft.toolCards = action.data.toolCards;
        draft.curseCards = action.data.curseCards;
        draft.abilities = action.data.abilities;
        draft.runes = action.data.runes;
        draft.charms = action.data.charms;
        draft.fetchingCards = false;
        break;
      case types.FETCH_DATA_CARDS_FAILED:
        draft.fetchingCards = false;
        break;

      default:
        break;
    }
  });

export default dataReducer;
