import { Stack, Grid } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Info from "../../components/Info/Info";
import Image from "../../components/Image/Image";
import Section from "../../components/Layout/Section";

const MilestoneRewards = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Section>
          <Title newTag={true}>Milestone Rewards</Title>
          <Text large={true}>
            Earn enough Stars in an Adventure Mode Chapter and you'll be able to
            collect Milestone Rewards.
          </Text>
          <Text large={true}>
            Tap on the small treasure chests below the map to claim your
            rewards!
          </Text>
        </Section>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Stack gap={4}>
          <Image
            src="/images/pages/adventure/milestone-rewards.jpg"
            alt="Milestone Rewards"
            plain={true}
            maxWidth={600}
          />
          <Info>
            Milestone Rewards rewards are highly valuable, but can only be
            claimed once.
          </Info>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MilestoneRewards;
