import { Grid, Stack } from "@mui/material";
import React from "react";
import Page from "../../../components/Page/Page";
import Section from "../../../components/Layout/Section";
import Title from "../../../components/Typography/Title";
import Text from "../../../components/Typography/Text";
import Image from "../../../components/Image/Image";
import { CREATORS_PROGRAM } from "../../../config";
import PageSEO from "../../../components/SEO/PageSEO";
import CustomLink from "../../../components/CustomLink/CustomLink";

const Creators = () => {
  return (
    <>
      <PageSEO
        title="Axie Creator Program | Axie.Tech"
        description="The Axie Creator Program is a new concept that rewards creators for their contributions to the Axie Infinity ecosystem."
      />
      <Page
        headerImg="/images/banners/axies.jpg"
        headerHeight={320}
        title="Creator's Guide"
        headerText="A beginner's guide to creating content in the Axie Infinity ecosystem."
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={7}>
            <Stack gap={4}>
              <Section>
                <Title>Axie Creator Program</Title>
                <Text large={true}>
                  The Creator's Program is a new concept that rewards creators
                  for their contributions to the Axie Infinity ecosystem.
                </Text>

                <Text large={true}>
                  We are currently in Phase 1 of the Program, which involves
                  establishing processes to collect feedback and listen to
                  participants.
                </Text>

                <Text large={true}>
                  To learn more or get involved, check out{" "}
                  <CustomLink endSpace={false} href={CREATORS_PROGRAM}>
                    The Axie Creator Program blog post
                  </CustomLink>.
                </Text>
              </Section>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <Section>
              <Image
                src="/images/pages/guides/creators/axies.jpg"
                alt="Axies"
                borderRadius={3}
                boxShadow={true}
                scale={1.06}
                href={CREATORS_PROGRAM}
              />
            </Section>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default Creators;
