import React from "react";
import Page from "../../components/Page/Page";
import Foraging from "./Foraging";
import MilestoneRewards from "./MilestoneRewards";
import WhatIsAdventure from "./WhatIsAdventure";
import CallToAction from "../../components/CallToAction/CallToAction";
import PageSEO from "../../components/SEO/PageSEO";

const Adventure = () => {
  return (
    <>
      <PageSEO
        title="Adventure | Axie.Tech"
        description="In Adventure, one of the two gameplay modes in Axie Infinity, you'll fight against waves of Chimera as the main storyline unfolds."
      />
      <Page
        headerImg="/images/banners/adventure.jpg"
        headerHeight={500}
        title="Adventure"
        headerText="In Adventure mode, you'll fight against waves of Chimera as the main game
        storyline unfolds."
      >
        <WhatIsAdventure />
        <Foraging />
        <MilestoneRewards />
      </Page>
      <CallToAction
        title="Compete in the Arena"
        description="Test your skills by battling against other players and their Axies."
        buttonText="Learn More"
        buttonTo="/arena"
      />
    </>
  );
};

export default Adventure;
