import { Stack } from "@mui/material";
import React from "react";
import { FaPlay } from "react-icons/fa";
import Text from "../Typography/Text";
import Bold from "../Typography/Bold";

const ListItem = ({ title, children, minimal, fontWeight = 500 }) => {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{
        p: minimal ? 0 : 2,
        background: minimal ? "transparent" : "#FFF3C3",
        borderRadius: minimal ? 0 : "16px",
      }}
    >
      <Stack fontSize={10}>
        <FaPlay />
      </Stack>
      <Text fontWeight={fontWeight}>
        {title != null && <Bold>{title} - </Bold>}
        {children}
      </Text>
    </Stack>
  );
};

export default ListItem;
