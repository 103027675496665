import React from "react";
import { CircularProgress, Stack } from "@mui/material";
import { useTheme } from "@mui/system";

const CustomLoader = ({
  width = 70,
  color,
  marginTop = 8,
  marginBottom = 8,
  centered = true,
  style,
}) => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        mx: centered ? "auto" : 0,
        textAlign: "center",
        mt: marginTop,
        mb: marginBottom,
      }}
    >
      <CircularProgress
        size={width}
        sx={{ color: color ? color : theme.palette.colors.secondary, ...style }}
      />
    </Stack>
  );
};

export default CustomLoader;
