import { Stack, Box, useTheme } from "@mui/material";
import Label from "components/Typography/Label";
import BubbleText from "pages/NewPlayers/molecules/BubbleText";

const AxieIntro = () => {
  const theme = useTheme();
  return (
    <Stack
      id="axies-intro"
      direction="row"
      alignItems="center"
      sx={{
        [theme.breakpoints.down("md")]: {
          mb: -4,
        },
        [theme.breakpoints.down("sm")]: {
          mb: 8,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          bottom: -35,
          left: 0,
          mr: -5,
          img: { width: 170 },
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        <img
          src="/images/pages/new-players/axies/axie-plant-yak.png"
          alt="Big Yak Plant Axie"
        />
      </Box>

      <BubbleText>
        Each Axie has 6 body parts: eyes, ears, horn, mouth, back, and tail.
      </BubbleText>

      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 30,
            left: 50,
            zIndex: -1,
            mb: -2,
            img: {
              width: 120,
              [theme.breakpoints.down("sm")]: {
                width: 90,
              },
            },
            [theme.breakpoints.down("sm")]: {
              bottom: -24,
              left: 21,
            },
          }}
        >
          <img
            src="/images/pages/new-players/axies/axie-reptile-2.png"
            alt="Reptile Axie"
          />
        </Box>

        <Stack
          alignItems="center"
          sx={{
            position: "relative",
            left: -45,
            top: 58,
            img: {
              width: 180,
              [theme.breakpoints.down("sm")]: {
                width: 120,
              },
            },
          }}
        >
          <img
            src="/images/pages/new-players/axies/dusk-axie.png"
            alt="Dusk Axie"
          />
          <Label>Axies</Label>
        </Stack>
      </Box>
    </Stack>
  );
};

export default AxieIntro;
