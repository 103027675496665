import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CustomLink from "components/CustomLink/CustomLink";
import { MARKETPLACE_URL } from "config";

export const questions = [
  {
    question: "Which Starter Axies can I play with?",
    answer: (
      <>
        You will receive free Starter Axies when you begin playing Axie Infinity
        Origins: <CustomLink to="/starter-axie/buba">Buba</CustomLink>,
        <CustomLink to="/starter-axie/olek">Olek</CustomLink> and{" "}
        <CustomLink to="/starter-axie/puffy">Puffy</CustomLink>.
      </>
    ),
  },
  {
    question: "How can I unlock more Starter Axies?",
    answer:
      "As you complete daily and weekly quests and activities, you'll unlock more Starter Axies to add to your team.",
  },
  {
    question: "What can I do with my Starter Axies?",
    answer: "Starter Axies can be added to your team and used in battles.",
  },
  {
    question: "What’s the difference between Starter Axies and Personal Axies?",
    answer: (
      <>
        Starter Axies are unable to breed, cannot be traded and have less
        upgradability than{" "}
        <Link to="/personal-axies" component={RouterLink}>
          Personal Axies
        </Link>
        .
      </>
    ),
  },
  {
    question: "Where do I get Personal Axies?",
    answer: (
      <>
        Personal Axies can be obtained from the{" "}
        <Link href={MARKETPLACE_URL} target="_blank">
          Marketplace
        </Link>
        .
      </>
    ),
  },
];
