import * as types from "./dataActionTypes";
import { put, takeEvery, retry } from "redux-saga/effects";
import { requestCards } from "../api";
import * as actions from "./dataActions";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* makeFetchCardsRequest() {
  try {
    yield put(actions.fetchCardsStarted());
    const data = yield retry(RETRY_TIMES, RETRY_DELAY, requestCards);
    yield put(actions.fetchCardsSucceeded(data));
  } catch (e) {
    yield put(actions.fetchCardsFailed());
  }
}

export default function* cardsSaga() {
  yield takeEvery(types.FETCH_DATA_CARDS, makeFetchCardsRequest);
}
