import { Stack, Container, Box } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import CardTemplate from "../../components/GameTemplates/CardTemplate";
import { connect } from "react-redux";
import { getSummonCards } from "../../store/data/dataSelectors";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import ClassIcon from "../../components/ClassIcon/ClassIcon";
import Label from "../../components/Typography/Label";
import CapsLabel from "../../components/Typography/CapsLabel";
import SectionHeader from "../../components/Layout/SectionHeader";
import { useTheme } from "@mui/system";
import { removeSpecialCharactersInDescription } from "helpers/cleaning";

const Summons = ({ summonsData }) => {
  return (
    <Container id="summons">
      <Stack gap={4}>
        <SectionHeader>
          <Title newTag={true}>Summons</Title>
          <Text large={true} textAlign="center">
            Summon Cards call upon allies that can assist you in battle.
          </Text>
        </SectionHeader>
        {summonsData.loading ? (
          <CustomLoader />
        ) : (
          <CardGallery>
            {summonsData.summons.map((summon, i) => {
              return (
                <SummonCard
                  key={`SUMMON_${i}`}
                  title={summon.summon.name}
                  card={summon}
                  imgSlug={summon}
                  summonClass={summon.summon.class}
                  hp={summon.summon.hp}
                  effect={summon.summon.effect}
                />
              );
            })}
          </CardGallery>
        )}
      </Stack>
    </Container>
  );
};

const SummonCard = ({ title, card, hp, effect, summonClass }) => {
  return (
    <CardGalleryItem>
      <CardTemplate width={240} card={card} />
      <Stack gap={1} alignItems="center">
        <CapsLabel>Summoned Ally:</CapsLabel>
        <Stack direction="row" gap={1} alignItems="center">
          <ClassIcon axieClass={summonClass} width={30} />
          <Label>{title}</Label>
          <Text fontWeight={600}>{hp} HP</Text>
        </Stack>
        <Text textAlign="center">
          {removeSpecialCharactersInDescription(effect)}
        </Text>
      </Stack>
    </CardGalleryItem>
  );
};

const CardGallery = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: "32px",
        [theme.breakpoints.down("lg")]: {
          gridTemplateColumns: "1fr 1fr",
          gridGap: "16px",
        },
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      {children}
    </Box>
  );
};

const CardGalleryItem = ({ children }) => {
  const theme = useTheme();

  return (
    <Stack
      gap={4}
      sx={{
        background: "#fff",
        borderRadius: 3,
        px: 2,
        pt: 4,
        pb: 1,
        alignItems: "center",
        transition: "0.2s all ease",
        boxShadow: "0px 0px 8px #f0dcc0a8",
        "&:hover": {
          transform: "scale(1.06)",
        },
        [theme.breakpoints.down("sm")]: {
          pt: 5,
          pb: 3,
        },
      }}
    >
      {children}
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  summonsData: getSummonCards(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Summons);
