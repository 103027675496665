import React from "react";
import { Container, Stack } from "@mui/material";
import FooterLinks from "./organisms/FooterLinks";
import BottomBar from "./molecules/BottomBar";
import TriballyBadge from "components/TriballyBadge/TriballyBadge";
import { TRIBALLY_SLOGAN } from "config";

const Footer = () => {
  return (
    <Container
      sx={{
        borderTop: "2px solid #f6efe5",
        pt: 8,
        mt: 8,
        pb: { xs: 6, sm: 6, md: 12 },
      }}
    >
      <Stack gap={{ xs: 8, sm: 8, md: 12 }}>
        <FooterLinks />
        <Stack
          direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
          spacing={2}
          justifyContent="space-between"
        >
          <BottomBar />
          <TriballyBadge
            marginTop={2}
            width={230}
            title={TRIBALLY_SLOGAN}
            alt={TRIBALLY_SLOGAN}
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default Footer;
