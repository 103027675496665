import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import SubTitle from "../../components/Typography/SubTitle";
import Text from "../../components/Typography/Text";
import Title from "../../components/Typography/Title";
import SectionHeader from "../../components/Layout/SectionHeader";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/system";
import CustomLink from "../../components/CustomLink/CustomLink";

const BattleModes = () => {
  return (
    <Stack gap={6} sx={{ mb: 4 }}>
      <SectionHeader>
        <Title>Battle Modes</Title>
        <Text large={true} textAlign="center">
          Assemble your team of Axies and prepare to battle!
        </Text>
      </SectionHeader>

      <Stack alignItems="center" justifyContent="center" padding={4}>
        <Mode
          title="Arena"
          description="In Arena Mode , you’ll fight against other players and their teams of Axies. Climb the leaderboard and win prizes!"
          slug="arena"
          rotate="6"
        />
      </Stack>

      <Stack direction="row" gap={4} justifyContent="center" flexWrap="wrap">
        <CustomLink to="/new-players">
          <ImageButton slug="new-player-guide-button" alt="New Player Guide" />
        </CustomLink>
        <CustomLink to="/guides/gameplay">
          <ImageButton slug="gameplay-guide-button" alt="Gameplay Guide" />
        </CustomLink>
      </Stack>
    </Stack>
  );
};

const ImageButton = ({ slug, alt }) => (
  <Box
    sx={{
      transition: "0.2s transform ease",
      img: { width: 260, borderRadius: "16px" },
      "&:hover": { transform: "scale(1.1)" },
    }}
  >
    <img src={`/images/pages/home/${slug}.jpg`} alt={alt} />
  </Box>
);

const Mode = ({ title, description, slug, align, rotate }) => {
  const theme = useTheme();

  return (
    <Grid item md={12} lg={6}>
      <RouterLink to={`/${slug}`}>
        <Box
          sx={{
            p: 1,
            background: theme.palette.colors[slug],
            height: "100%",
            borderRadius: "24px",
            transition: "0.2s transform ease",
            transform: `rotate(${rotate}deg)`,
            "&:hover": {
              transform: "scale(1.03)",
            },
            [theme.breakpoints.down("sm")]: {
              transform: "none",
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url('/images/banners/${slug}.jpg')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "100%",
              borderRadius: "20px",
              height: 400,
              [theme.breakpoints.down("md")]: {
                height: 300,
              },
              [theme.breakpoints.down("sm")]: {
                height: 240,
              },
            }}
          />
          <Stack
            direction="row"
            gap={4}
            alignItems="center"
            justifyContent={align === "right" ? "flex-end" : "flex-start"}
            sx={{
              p: 1,
              [theme.breakpoints.down("sm")]: {
                flexDirection: align === "right" ? "column-reverse" : "column",
                gap: 1,
              },
            }}
          >
            {align !== "right" && (
              <SubTitle color="text.white">{title}</SubTitle>
            )}
            <Typography
              variant="body2"
              fontSize={18}
              fontWeight={500}
              color="text.white"
              display="inline-block"
              sx={{
                lineHeight: 1.3,
                textAlign: align === "right" ? "right" : "left",
                [theme.breakpoints.down("sm")]: {
                  textAlign: "center",
                },
              }}
            >
              {description}
            </Typography>
            {align === "right" && (
              <SubTitle color="text.white">{title}</SubTitle>
            )}
          </Stack>
        </Box>
      </RouterLink>
    </Grid>
  );
};

export default BattleModes;
