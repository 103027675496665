import React from "react";
import Page from "components/Page/Page";
import { Typography, Button, Stack } from "@mui/material";
import { FaPlay } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";

const NotFound = () => {
  return (
    <Page>
      <Stack gap={4} alignItems="flex-start">
        <Stack gap={2}>
          <Typography variant="h1" color="text.bright">
            Wer Axie?
          </Typography>
          <Typography variant="body1" color="text.regular">
            We searched high and low but couldn't find the axie you were looking
            for.
          </Typography>
        </Stack>
        <Button
          component={HashLink}
          to="/starter-axies/#explore"
          variant="contained"
          endIcon={<FaPlay />}
        >
          Explore Starter Axies
        </Button>
      </Stack>
    </Page>
  );
};

export default NotFound;
