import React, { useState } from "react";
import Text from "components/Typography/Text";
import Section from "components/Layout/Section";
import Title from "components/Typography/Title";
import SubTitle from "components/Typography/SubTitle";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import List from "components/List/List";
import ListItem from "components/List/ListItem";
import SectionHeader from "components/Layout/SectionHeader";
import Image from "components/Image/Image";
import { connect } from "react-redux";
import CardTemplate from "components/GameTemplates/CardTemplate";
import * as bodyPartIcons from "icons/bodyPartIcons";

const ListOfTeams = ({ teams, cards }) => {
  return (
    <Stack gap={8}>
      <SectionHeader>
        <Title>Team Build Examples</Title>
      </SectionHeader>

      <Stack gap={{ xs: 10, sm: 10, md: 16 }}>
        {teams.map((team, i) => (
          <Team key={`BUILD_${i}`} team={team} teams={teams} cards={cards} />
        ))}
      </Stack>
    </Stack>
  );
};

const Team = ({ team, teams, cards }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const frontAxieCards = team.axies.front.parts.map((part) =>
    cards.find((c) => c.partId === part)
  );
  const midAxieCards = team.axies.mid.parts.map((part) =>
    cards.find((c) => c.partId === part)
  );
  const backAxieCards = team.axies.back.parts.map((part) =>
    cards.find((c) => c.partId === part)
  );
  const axieParts = [
    { name: "Front Axie", cards: frontAxieCards },
    { name: "Mid Axie", cards: midAxieCards },
    { name: "Back Axie", cards: backAxieCards },
  ];
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={6} id={team.id}>
        <Section>
          {mdDown && <TeamImage id={team.id} name={team.name} />}
          <Stack
            direction={{
              xs: "column-reverse",
              sm: "column-reverse",
              md: "row",
            }}
            gap={{ xs: 2, sm: 2, md: 3 }}
            alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
          >
            <SubTitle>{team.name}</SubTitle>
            {/* <Button
              endIcon={<FaExternalLinkSquareAlt />}
              href={team.url}
              variant="contained"
              size="small"
              target="_blank"
              sx={{ whiteSpace: "nowrap" }}
            >
              View Team
            </Button> */}
          </Stack>

          <Text large={true}>{team.strategy}</Text>

          <Text fontWeight={800}>Rationale</Text>
          <List>
            {team.rationale.map((r, i) => {
              return <ListItem key={`RATIONALE_${i}`}>{r}</ListItem>;
            })}
          </List>

          <Text fontWeight={800}>Strong Against</Text>
          <List>
            {team.strongAgainst.map((t, i) => {
              return (
                <ListItem key={`TEAM_${i}`} minimal={true}>
                  {t.description}
                </ListItem>
              );
            })}
          </List>

          <Text fontWeight={800}>Weak Against</Text>
          <List>
            {team.weakAgainst.map((t, i) => {
              return (
                <ListItem key={`TEAM_${i}`} minimal={true}>
                  {t.description}
                </ListItem>
              );
            })}
          </List>
        </Section>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Stack gap={4}>
          {!mdDown && <TeamImage id={team.id} name={team.name} />}
          {cards.length > 0 && <AxiesWithParts axieParts={axieParts} />}
        </Stack>
      </Grid>
    </Grid>
  );
};

const TeamImage = ({ id, name }) => {
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative" }}>
      <Image
        src={`/images/pages/guides/builds/${id}.jpg`}
        alt={`${name}`}
        borderRadius={3}
      />

      <Typography
        fontFamily="'Changa One', sans serif"
        color={theme.palette.colors.wood.text}
        style={{
          position: "absolute",
          bottom: "3%",
          left: "50%",
          transform: "translateX(-50%)",
          fontSize: 24,
          whiteSpace: "nowrap",
          [theme.breakpoints.down("md")]: {
            fontSize: 20,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 28,
          },
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

const AxiesWithParts = ({ axieParts }) => (
  <Stack gap={4}>
    {axieParts.map((axie, i) => (
      <Axie axie={axie} key={`AXIE_${i}`} />
    ))}
  </Stack>
);

const Axie = ({ axie }) => {
  const theme = useTheme();
  const [viewCards, setViewCards] = useState(false);

  return (
    <Stack
      gap={1}
      sx={{
        p: 1,
        border: "1px solid #eee",
        borderRadius: 2,
        position: "relative",
      }}
    >
      <Text fontWeight={800} style={{ alignSelf: "center" }}>
        {axie.name}
      </Text>
      <Button
        variant="text"
        onClick={() => setViewCards(!viewCards)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          opacity: 0.5,
          "&:hover": { opacity: 1 },
        }}
        size="small"
      >
        {viewCards ? "Hide Cards" : "View Cards"}
      </Button>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3,1fr)",
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "repeat(2,1fr)",
          },
        }}
      >
        {axie.cards.map((card, i) => {
          if (card == null) {
            return null;
          }
          return (
            <Part
              cls={card.class}
              name={card.cardName}
              key={`CARD_${i}`}
              bodyPart={card.partType}
            />
          );
        })}
      </Box>

      {viewCards && (
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={1}
          justifyContent="center"
          sx={{ mt: 4 }}
        >
          {axie.cards.map((card, i) => {
            if (card == null) {
              return null;
            }
            return (
              <CardTemplate
                card={card}
                key={`CARD_${i}`}
                width={150}
                style={{
                  transition: "0.2s transform ease",
                  "&:hover": { transform: "scale(1.08)" },
                }}
              />
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};

const Part = ({ cls, bodyPart, name }) => (
  <Stack
    direction="row"
    alignItems="center"
    gap={0.5}
    sx={{
      p: "2px 6px",
      borderRadius: "8px",
      svg: {
        width: 36,
        height: "auto",
      },
    }}
  >
    {bodyPartIcons[`${bodyPart}_${cls.toLowerCase()}`]}
    <Text fontWeight={600} style={{ fontSize: 13 }}>
      {name}
    </Text>
  </Stack>
);

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  cards: state.data.cards,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListOfTeams);
