import { Stack, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Section from "../../components/Layout/Section";
import CharmTemplate from "components/GameTemplates/CharmTemplate";
import { connect } from "react-redux";

const StarterCharms = ({ charms }) => {
  const starterCharmIds = [
    "ecard_aquatic_puffy_1",
    "ecard_beast_tripp_1",
    "ecard_beast_buba_1",
    "ecard_bird_momo_1",
    "ecard_bug_pomodoro_1",
    "ecard_plant_olek_1",
    "ecard_plant_ena_1",
    "ecard_reptile_venoki_1",
    "ecard_beast_xia_1",
    "ecard_beast_bing_1",
    "ecard_aquatic_noir_1",
    "ecard_aquatic_rouge_1",
  ];

  const starterCharms = charms.filter(
    (charm) =>
      starterCharmIds.includes(charm.itemId) && charm.season === "Unknown"
  );

  return (
    <Container>
      <Stack gap={4}>
        <Section>
          <Title>Starter Charms</Title>
          <Text large={true}>
            Starter Charms are non-NFT runes. They can be earned by making
            progress in the game, such as playing ranked Arena games, or through
            daily login rewards. Equip your Starter Charms to your Starter Axies
            parts and see what effects you can create!{" "}
          </Text>
        </Section>

        <Stack
          direction="row"
          alignItems="center"
          gap={{ xs: 2, sm: 2, md: 4 }}
          flexWrap="wrap"
          justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
        >
          {starterCharms.map((charm, i) => (
            <CharmTemplate width={180} key={`CHARM_${i}`} charm={charm} />
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  charms: state.data.charms,
});

export default connect(mapStateToProps, mapDispatchToProps)(StarterCharms);
