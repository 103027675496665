import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import Card from "../../../components/Card/Card";

const TextBubble = ({
  children,
  maxWidth,
  right,
  left,
  top,
  bottom,
  styleSmall,
}) => {
  const theme = useTheme();
  return (
    <Card
      style={{
        position:
          right == null && left == null && top == null && bottom == null
            ? "static"
            : "absolute",
        right,
        bottom,
        left,
        top,
        maxWidth,
        zIndex: 2,
        [theme.breakpoints.down("lg")]: {
          py: 1.5,
          px: 3,
          ...styleSmall,
        },
      }}
    >
      <Typography
        variant="body1"
        fontWeight={600}
        sx={{
          [theme.breakpoints.down("lg")]: {
            fontSize: 16,
          },
        }}
      >
        {children}
      </Typography>
    </Card>
  );
};

export default TextBubble;
