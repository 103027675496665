import { Stack, Grid, Box } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import CustomLink from "../../components/CustomLink/CustomLink";
import Section from "../../components/Layout/Section";
import { BODY_PARTS } from "../../config";
import CardTemplate from "../../components/GameTemplates/CardTemplate";
import Image from "../../components/Image/Image";
import Card from "../../components/Card/Card";
import { useTheme } from "@mui/system";
import { connect } from "react-redux";

const BodyParts = ({ cards }) => {
  return (
    <Stack gap={6}>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={12} md={5}>
          <Section>
            <Section>
              <Title>Body Parts</Title>
              <Text large={true}>
                Each Axie has 6 different body parts that determine which
                <CustomLink to="/cards">Cards</CustomLink> it can use in battle.
                In other words, each body part corresponds to one unique
                ability.
              </Text>
            </Section>
            <BodyPartsIcons />
            <Text large={true}>
              Each body part has its own class. The body class of an axie does
              not determine what class of parts it can have. Notice that our
              plant axie has a reptile, and a bird body part, as well as plant
              parts.
            </Text>

            <Text large={true}>
              There are over 200 different body parts and nearly an infinite
              number of ways to combine them to build your next favorite Axie!
            </Text>

            <Card
              style={{
                ml: "auto",
                py: 0,
                px: 2,
              }}
            >
              <Stack direction="row" alignItems="center" gap={2} sx={{ my: 1 }}>
                <Image
                  src="/images/pages/what-are-axies/plant-axie-right.png"
                  alt="Plant axie"
                  maxWidth={100}
                  card={false}
                  boxShadow={false}
                />
                <Text fontWeight={800} style={{ fontSize: 14 }}>
                  Hey, those are my Cards!
                </Text>
              </Stack>
            </Card>
          </Section>
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          {cards != null && (
            <Section>
              <Text large={true}>
                Remember that Plant Axie we encountered earlier? Let's see what
                Cards it has!
              </Text>
              <Cards cards={cards} />
            </Section>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

const BodyPartsIcons = () => (
  <Box
    sx={{
      display: "grid",
      gridGap: "24px",
      gridTemplateColumns: "1fr 1fr 1fr",
    }}
  >
    {BODY_PARTS.map((part, i) => (
      <BodyPart key={`BODY_PART_${i}`} part={part} />
    ))}
  </Box>
);

const Cards = ({ cards }) => {
  const ids = [
    "eyes-blossom",
    "ears-clover",
    "back-pumpkin",
    "mouth-serious",
    "horn-wing-horn",
    "tail-tiny-dino",
  ];
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={2}
      flexWrap="wrap"
      sx={{
        mt: 1,
      }}
    >
      {ids.map((id, i) => {
        const card = cards != null && cards.find((c) => c.partId === id);

        if (card) {
          return (
            <CardTemplate
              card={card}
              key={`CARD_${i}`}
              width={180}
              style={{
                transition: "0.2s transform ease",
                "&:hover": {
                  cursor: "pointer",
                  transform: "scale(1.1)",
                },
              }}
            />
          );
        } else {
          return null;
        }
      })}
    </Stack>
  );
};

const BodyPart = ({ part }) => {
  const partImageName = part.toLowerCase();
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1.5}
      sx={{
        my: 2,
        img: { width: 40 },
        transition: "0.2s transform ease",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.1)",
        },
        [theme.breakpoints.down("md")]: {
          my: 1,
        },
      }}
    >
      <img
        src={`/images/templates/card/body-parts/${partImageName}.png`}
        alt={`Axie ${part}`}
      />
      <Text fontWeight={800}>{part}</Text>
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({ cards: state.data.cards });

export default connect(mapStateToProps, mapDispatchToProps)(BodyParts);
