import { useTheme } from "@mui/system";
import React, { useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { List, ListItem, Typography } from "@mui/material";
import styles from "./navigation.module.scss";

const DropdownItem = ({
  item,
  index,
  pathname,
  setClickDropdown,
  children,
  colorOverrides,
}) => {
  const theme = useTheme();
  const isActive = pathname.includes(item.to);
  return (
    <ListItem key={index} sx={{ whiteSpace: "nowrap", p: 0 }}>
      <NavLink
        to={item.to}
        onClick={() => setClickDropdown(false)}
        className={styles.dropdownItemLink}
      >
        <Typography
          fontSize="14px"
          sx={{
            color: colorOverrides?.navigation?.dropdown?.text
              ? colorOverrides?.navigation?.dropdown?.text
              : isActive
              ? theme.palette.text.primary
              : theme.palette.text.regular,
            "&:hover": {
              color: colorOverrides?.navigation?.dropdown?.textHover
                ? colorOverrides?.navigation?.dropdown?.textHover
                : theme.palette.text.primary,
            },
            fontWeight: isActive ? 700 : 600,
            transition: "0.2s all ease",
          }}
        >
          {children}
        </Typography>
      </NavLink>
    </ListItem>
  );
};

const NavigationDropdown = ({ menuItems, alignRight, colorOverrides }) => {
  const [clickDropdown, setClickDropdown] = useState(false);
  const { pathname } = useLocation();

  return (
    <List
      onClick={() => setClickDropdown(!clickDropdown)}
      className={`${styles.dropdown} ${alignRight ? styles.alignRight : ""} ${
        menuItems.length > 5 ? styles.twoColumn : ""
      }`}
      sx={{
        backgroundColor: "#fff",
        boxShadow: `0px 1px 20px rgba(147, 110, 89, 0.2)`,
        "&:before": {
          borderBottomColor: `#fff !important`,
        },
        "&:after": {
          borderBottomColor: `#fff !important`,
        },
        "& .MuiListItem-root": {
          justifyContent: alignRight ? "flex-end" : "flex-start",
        },
      }}
    >
      {menuItems.map((item, index) => (
        <DropdownItem
          item={item}
          index={index}
          setClickDropdown={setClickDropdown}
          pathname={pathname}
          key={`DROPDOWN_ITEM_${index}`}
          colorOverrides={colorOverrides}
        >
          {item.text}
        </DropdownItem>
      ))}
    </List>
  );
};

export default NavigationDropdown;
