import { Grid, Stack } from "@mui/material";
import React from "react";
import Page from "../../../components/Page/Page";
import Title from "../../../components/Typography/Title";
import Text from "../../../components/Typography/Text";
import Image from "../../../components/Image/Image";
import { AXIE_INFINITY_SCATTER } from "../../../config";
import PageSEO from "../../../components/SEO/PageSEO";
import CustomLink from "../../../components/CustomLink/CustomLink";

const Scatter = () => {
  return (
    <>
      <PageSEO
        title="Scatter Guide | Axie.Tech"
        description="The Creator's Program is a new concept that rewards creators for their contributions to the Axie Infinity ecosystem."
      />
      <Page
        headerImg="/images/banners/axies.jpg"
        headerHeight={320}
        title="Scatter Guide"
        headerText="How to send tokens to multiple Ronin addresses using Scatter."
      >
        <Stack gap={6}>
          <Stack gap={2}>
            <Title>What is Scatter?</Title>

            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={6}>
                <Text large={true}>
                  <CustomLink href={AXIE_INFINITY_SCATTER}>Scatter</CustomLink>{" "}
                  is a tool that allows you to send tokens to more than one
                  Ronin address at the same time. It currently supports RON,
                  AXS, SLP, WETH and USDC. Scatter is particularly useful for
                  Scholarship Managers who need to distribute tokens to their
                  scholars.
                </Text>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Text large={true}>
                  In this tutorial, you will learn how to send RON tokens to
                  multiple ronin addresses. The same process applies for AXS,
                  SLP, WETH or USDC – just don't forget to select the correct
                  token from the Assets dropdown!
                </Text>
              </Grid>
            </Grid>
          </Stack>

          <Stack>
            <Image
              src="/images/pages/guides/scatter/scatter-1.jpg"
              alt="Scatter Guide"
            />
            <Image
              src="/images/pages/guides/scatter/scatter-2.jpg"
              alt="Scatter Guide"
            />
            <Text large={true}>
              For ease, here is the formula to paste into Cell C1:
            </Text>
            <Text large={true} fontWeight={700}>
              =CONCATENATE(A1,", ",B1)
            </Text>
            <Image
              src="/images/pages/guides/scatter/scatter-3.jpg"
              alt="Scatter Guide"
            />
            <Image
              src="/images/pages/guides/scatter/scatter-4.jpg"
              alt="Scatter Guide"
            />
          </Stack>

          <Text large={true}>
            Ready to get started? Head over to
            <CustomLink href={AXIE_INFINITY_SCATTER}>Scatter</CustomLink> to
            start transferring!
          </Text>
        </Stack>
      </Page>
    </>
  );
};

export default Scatter;
