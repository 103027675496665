import React from "react";
import Page from "../../components/Page/Page";
import Summons from "./Summons";
import TagsSpecialWords from "./TagsSpecialWords";
import CallToAction from "../../components/CallToAction/CallToAction";
import Intro from "./Intro";
import Diagram from "../../components/Diagram/Diagram";
import { CARD_EXPLORER_URL } from "../../config";
import PageSEO from "../../components/SEO/PageSEO";
import Curses from "./Curses";
import Tools from "./Tools";

const Cards = () => {
  return (
    <>
      <PageSEO
        title="Cards | Axie.Tech"
        description="Each Axie has 6 different Cards that determine its abilities in Battle."
      />
      <Page
        container={false}
        hidePattern={true}
        style={{
          pt: "0 !important",
        }}
      >
        <Intro />
        <Diagram type="card" />
        <TagsSpecialWords />
        <Curses />
        <Tools />
        <Summons />
      </Page>
      <CallToAction
        title="Ready to Explore?"
        description="Jump into the Axie Infinity: Origins Card Explorer and start picking
        out your abilities!"
        buttonText="Card Explorer"
        buttonHref={CARD_EXPLORER_URL}
        axie="buba"
      />
    </>
  );
};

export default Cards;
