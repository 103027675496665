import React from "react";
import { Stack, Box, useTheme } from "@mui/material";
import Label from "components/Typography/Label";
import ClassChip from "pages/NewPlayers/molecules/ClassChip";
import BubbleText from "pages/NewPlayers/molecules/BubbleText";

const AxieClasses = ({ commonClasses, rareClasses, secretClasses }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{ mt: 4 }}
      id="classes-container"
      alignItems="center"
      justifyContent="center"
      gap={6}
    >
      <Stack position="relative" alignItems="center" justifyContent="center">
        <Box
          sx={{
            position: "absolute",
            top: -45,
            left: -20,
            zIndex: 2,
            img: {
              width: 120,
              [theme.breakpoints.down("sm")]: {
                width: 90,
              },
            },
            [theme.breakpoints.down("sm")]: {
              top: -25,
            },
          }}
        >
          <img
            src="/images/pages/new-players/axies/axie-reptile.png"
            alt="Reptile Axie"
          />
        </Box>

        <Stack alignItems="flex-end" justifyContent="center" gap={2}>
          <Label>Classes</Label>
          <BubbleText>Each Axie is one of 9 classes.</BubbleText>
        </Stack>

        <Box
          sx={{
            position: "absolute",
            top: 40,
            left: 226,
            zIndex: 2,

            img: {
              width: 120,
              [theme.breakpoints.down("sm")]: {
                left: 220,
                width: 100,
              },
            },
          }}
        >
          <img
            src="/images/pages/new-players/axies/axie-pink.png"
            alt="Bug Axie"
          />
        </Box>
      </Stack>

      <Stack
        sx={{
          mb: 4,
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
        }}
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={6}
      >
        <Stack alignItems="flex-start" justifyContent="flex-start" gap={1}>
          <Label>Common Classes</Label>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            {commonClasses.map((item, i) => (
              <ClassChip
                key={`${i}${item.class}`}
                axieClass={item.class}
                alt={item.alt}
                image={item.image}
              />
            ))}
          </Stack>
        </Stack>
        <Stack alignItems="flex-start" justifyContent="flex-start" gap={1}>
          <Label>Rare Classes</Label>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={0.5}
          >
            {rareClasses.map((item, i) => (
              <ClassChip
                key={`${i}${item.class}`}
                axieClass={item.class}
                alt={item.alt}
                image={item.image}
              />
            ))}
          </Stack>
        </Stack>
        <Stack alignItems="flex-start" justifyContent="flex-start" gap={1}>
          <Label>Secret Classes</Label>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            {secretClasses.map((item, i) => (
              <ClassChip
                key={`${i}${item.class}`}
                axieClass={item.class}
                alt={item.alt}
                image={item.image}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AxieClasses;
