import React from "react";
import { Stack, Typography } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import Copyright from "../atoms/Copyright";

import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
  alignItems: "center",
  fontSize: 12,
  flexWrap: "wrap",

  ".subtleLink, .subtleLink:visited": {
    color: theme.palette.colors.typography.muted,
    opacity: 0.5,
    "& > *": {
      fontSize: 12,
      fontWeight: 600,
    },
    "&:hover": {
      opacity: 1,
    },
  },
}));

const BottomBar = () => {
  return (
    <Wrapper>
      <Copyright />

      <CustomLink
        to="/terms-and-conditions"
        underline="none"
        className="subtleLink"
      >
        <Typography variant="span">Terms and Conditions</Typography>
      </CustomLink>

      <CustomLink to="/privacy-policy" underline="none" className="subtleLink">
        <Typography variant="span">Privacy Policy</Typography>
      </CustomLink>
    </Wrapper>
  );
};

export default BottomBar;
