import React from "react";
import { Stack, useTheme } from "@mui/material";
import Label from "components/Typography/Label";
import BubbleText from "pages/NewPlayers/molecules/BubbleText";

const AxieCards = () => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        mb: 2,
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          mb: 14,
        },
      }}
    >
      <Stack
        alignItems="center"
        sx={{
          maxWidth: "100%",
          position: "relative",
          left: -36,
          top: 0,
          mr: -6,
          img: { width: 400, mb: 2 },
          [theme.breakpoints.down("sm")]: {
            img: {
              width: "100%",
            },
          },
        }}
      >
        <img src="/images/pages/new-players/cards.png" alt="Axie Cards" />
        <Label>Cards</Label>
      </Stack>
      <BubbleText>Cards give Axies unique abilities in battle!</BubbleText>
    </Stack>
  );
};

export default AxieCards;
