import React from "react";
import { Routes, Route } from "react-router";
import { Box, Stack } from "@mui/material";
import Home from "pages/Home/Home";
import Page404 from "pages/Page404/Page404";
import Navigation from "./components/Navigation/Navigation";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import StatusEffects from "pages/StatusEffects/StatusEffects";
import StarterAxies from "pages/StarterAxies/StarterAxies";
import StarterAxie from "pages/StarterAxies/StarterAxie";
import PersonalAxies from "pages/PersonalAxies/PersonalAxies";
import Adventure from "pages/Adventure/Adventure";
import Arena from "pages/Arena/Arena";
import Runes from "pages/Runes/Runes";
import Charms from "pages/Charms/Charms";
import Cards from "pages/Cards/Cards";
import Footer from "./components/Footer/Footer";
import WhatAreAxies from "pages/WhatAreAxies/WhatAreAxies";
import Builds from "pages/Guides/Builds/Builds";
import NewPlayers from "pages/NewPlayers/NewPlayers";
import GameplayGuide from "pages/Guides/GameplayGuide/GameplayGuide";
import Community from "pages/Community/Community";
import Esports from "pages/Esports/Esports";
import Guides from "pages/Guides/Guides";
import Breeding from "pages/Guides/Breeding/Breeding";
import Collectors from "pages/Guides/Collectors/Collectors";
import Creators from "pages/Guides/Creators/Creators";
import Scatter from "pages/Guides/Scatter/Scatter";
import TermsAndConditions from "pages/About/TermsAndConditions";
import PrivacyPolicy from "pages/About/PrivacyPolicy";

const SitePage = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <Stack>
        <Navigation />
        <Box
          sx={{
            position: "relative",
            zIndex: "2",
            minHeight: "calc(100vh - 60px)",
            backgroundImage: "url('/images/accents/pattern-light.png')",
          }}
        >
          {children}
        </Box>
        <Footer />
      </Stack>
    </>
  );
};

const CustomRoutes = () => (
  <Routes>
    <Route
      exact
      path="/"
      element={
        <SitePage>
          <Home />
        </SitePage>
      }
    />
    <Route
      exact
      path="/community"
      element={
        <SitePage>
          <Community />
        </SitePage>
      }
    />
    <Route
      exact
      path="/new-players"
      element={
        <SitePage>
          <NewPlayers />
        </SitePage>
      }
    />
    <Route
      exact
      path="/runes"
      element={
        <SitePage>
          <Runes />
        </SitePage>
      }
    />
    <Route
      exact
      path="/charms"
      element={
        <SitePage>
          <Charms />
        </SitePage>
      }
    />
    <Route
      exact
      path="/cards"
      element={
        <SitePage>
          <Cards />
        </SitePage>
      }
    />
    <Route
      exact
      path="/status-effects"
      element={
        <SitePage>
          <StatusEffects />
        </SitePage>
      }
    />
    <Route
      exact
      path="/guides"
      element={
        <SitePage>
          <Guides />
        </SitePage>
      }
    />
    <Route
      exact
      path="/guides/gameplay"
      element={
        <SitePage>
          <GameplayGuide />
        </SitePage>
      }
    />
    <Route
      exact
      path="/guides/builds"
      element={
        <SitePage>
          <Builds />
        </SitePage>
      }
    />
    <Route
      exact
      path="/guides/collectors"
      element={
        <SitePage>
          <Collectors />
        </SitePage>
      }
    />
    <Route
      exact
      path="/guides/creators"
      element={
        <SitePage>
          <Creators />
        </SitePage>
      }
    />
    <Route
      exact
      path="/guides/breeding"
      element={
        <SitePage>
          <Breeding />
        </SitePage>
      }
    />
    <Route
      exact
      path="/guides/scatter"
      element={
        <SitePage>
          <Scatter />
        </SitePage>
      }
    />
    <Route
      exact
      path="/what-are-axies"
      element={
        <SitePage>
          <WhatAreAxies />
        </SitePage>
      }
    />
    <Route
      exact
      path="/starter-axies"
      element={
        <SitePage>
          <StarterAxies />
        </SitePage>
      }
    />
    <Route
      exact
      path="/starter-axie/:slug"
      element={
        <SitePage>
          <StarterAxie />
        </SitePage>
      }
    />
    <Route
      exact
      path="/personal-axies"
      element={
        <SitePage>
          <PersonalAxies />
        </SitePage>
      }
    />
    <Route
      exact
      path="/adventure"
      element={
        <SitePage>
          f
          <Adventure />
        </SitePage>
      }
    />
    <Route
      exact
      path="/arena"
      element={
        <SitePage>
          <Arena />
        </SitePage>
      }
    />
    <Route
      exact
      path="/esports"
      element={
        <SitePage>
          <Esports />
        </SitePage>
      }
    />
    <Route
      exact
      path="/terms-and-conditions"
      element={
        <SitePage>
          <TermsAndConditions />
        </SitePage>
      }
    />
    <Route
      exact
      path="/privacy-policy"
      element={
        <SitePage>
          <PrivacyPolicy />
        </SitePage>
      }
    />
    <Route
      path="*"
      element={
        <SitePage>
          <Page404 />
        </SitePage>
      }
    />
  </Routes>
);

export default CustomRoutes;
