import { Button } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { MARKETPLACE_URL } from "../../config";
import { marketplace } from "../../icons/icons";

const MarketplaceButton = ({ style }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      size="small"
      href={MARKETPLACE_URL}
      target="_blank"
      endIcon={marketplace()}
      sx={{
        background: "#189ECF",
        color: "#fff",
        "&:hover": {
          background: "#5B6ED6",
          boxShadow: `3px 3px 0px ${theme.palette.colors.shadow}, inset 0px -4px 0px rgba(27, 25, 124, 0.24), inset 0px 3px 0px rgba(255, 255, 255, 0.17)`,
        },
        ...style,
      }}
    >
      Visit Marketplace
    </Button>
  );
};

export default MarketplaceButton;
