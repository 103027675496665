import React from "react";
import { Grid, Container } from "@mui/material";
import Title from "components/Typography/Title";
import Text from "components/Typography/Text";
import Info from "components/Info/Info";
import CardTags from "components/CardTags/CardTags";
import Section from "components/Layout/Section";
import CardSpecialWords from "components/CardSpecialWords/CardSpecialWords";

const TagsSpecialWords = () => {
  return (
    <Container>
      <Grid container spacing={12}>
        <Grid item xs={12} sm={12} md={5} id="tags">
          <Section
            info={
              <Info>
                Card tags appear below the Card name and above the Card
                description.
              </Info>
            }
          >
            <Title newTag={true}>Tags</Title>
            <Text large={true}>
              Card Tags indicate how Cards behave in battle:
            </Text>

            <CardTags />
          </Section>
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          <Section
            info={
              <Info>
                Special words can appear anywhere in a Card's description. Hover
                over them for more info!
              </Info>
            }
          >
            <Title newTag={true}>Special Words</Title>
            <Text large={true}>Special words appear in yellow:</Text>

            <CardSpecialWords />
          </Section>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TagsSpecialWords;
