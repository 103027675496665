import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import TextWithHighlights from "./TextWithHighlights";
import { connect } from "react-redux";
import { getOriginCardArtUrl } from "helpers/card";

const CardTemplate = ({ data, onClick, card, width = 1181, style }) => {
  if (card == null) return null;

  const getCardClass = () => {
    if (data.curseCards.find((c) => c.name === card.name)) {
      return "curse";
    } else if (data.toolCards.find((c) => c.name === card.name)) {
      return "tool";
    } else return "revenge";
  };

  let cardClass = card.class ? card.class.toLowerCase() : getCardClass();

  // replace -1 values with X
  const cardEnergy =
    card.cardEnergy === "-1"
      ? card.cardEnergy.replace("-1", "X")
      : card.cardEnergy || "X";

  return (
    <Box
      onClick={onClick}
      sx={{
        display: "inline-block",
        pt: (22 / 1181) * width,
        pl: (16 / 1181) * width,
        userSelect: "none",
        ...style,
      }}
    >
      <Box
        sx={{
          width,
          height: (1814 / 1181) * width,
          position: "relative",
          "&::before": {
            content: "''",
            background: "#39241a78",
            filter: `blur(${(28 / 1181) * width}px)`,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: `${(130 / 1181) * width}px`,
          },
        }}
      >
        <CardBackground cardClass={cardClass} width={width} />
        <CardArt
          cardName={card.cardName}
          cardImage={card.cardImage}
          cardClass={cardClass}
        />
        <CardGradient cardName={card.cardName} cardClass={cardClass} />
        <CardFrame />

        <Stats card={card} width={width} />
        <Energy energy={cardEnergy} width={width} />
        <BodyPart part={card.partType} width={width} />
        <CardName
          name={card.cardName || card.name}
          width={width}
          centered={card.cardName == null}
        />
        <Tags tags={card.tags} width={width} cardClass={cardClass} />
        <CardText text={card.cardText || card.description} width={width} />
        <ClassIcon cardClass={cardClass} />
      </Box>
    </Box>
  );
};

const CardBackground = ({ cardClass, width }) => (
  <Box
    sx={{
      position: "absolute",
      top: "2.8%",
      left: 0,
      right: 0,
      zIndex: 1,
      "& img": {
        width: "92.1%",
        m: "0 auto",
        display: "block",
        borderRadius: `${(80 / 1181) * width}px`,
      },
    }}
  >
    <img
      src={`/images/templates/card/backgrounds/${cardClass}.jpg`}
      alt={`${cardClass} Axie Card Background`}
    />
  </Box>
);

const CardArt = ({ cardName, cardImage, cardClass }) => {
  if (cardImage == null) return null;

  let imageUrl = getOriginCardArtUrl(cardImage);
  return (
    <Box
      sx={{
        position: "absolute",
        top: "2.5%",
        left: 0,
        right: 0,
        zIndex: 2,
        "& img": {
          width: "92.1%",
          m: "0 auto",
          display: "block",
        },
      }}
    >
      <img src={imageUrl} alt={cardName} />
    </Box>
  );
};

const CardGradient = ({ cardClass }) => (
  <Box
    sx={{
      position: "absolute",
      top: "24.6%",
      left: 0,
      right: 0,
      overflow: "hidden",
      textAlign: "center",
      zIndex: 3,
      "& img": {
        width: "136%",
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)",
      },
    }}
  >
    <img
      src={`/images/templates/card/gradients/${cardClass}.png`}
      alt={`${cardClass} Axie Card`}
    />
  </Box>
);

const CardFrame = () => (
  <Box
    sx={{
      zIndex: 4,
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url('/images/templates/card/frame/frame.png')`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    }}
  />
);

const ClassIcon = ({ cardClass }) => {
  if (
    cardClass === "curse" ||
    cardClass === "tool" ||
    cardClass === "revenge"
  ) {
    return null;
  }
  return (
    <Stack
      sx={{
        zIndex: 5,
        position: "absolute",
        bottom: "4%",
        height: "5%",
        left: "50%",
        transform: "translateX(-50%)",
        m: "0 auto",
        "& img": {
          height: "100%",
          width: "auto",
        },
      }}
    >
      <img
        src={`/images/templates/card/class-icons/${cardClass}.png`}
        alt={`${cardClass} class card`}
      />
    </Stack>
  );
};

const Stats = ({ card, width }) => {
  return (
    <>
      {card.cardDamage != null && card.cardDamage !== 0 && (
        <Damage card={card} width={width} />
      )}

      {card.cardShield != null && card.cardShield !== 0 && (
        <Shield card={card} width={width} />
      )}

      {card.cardHeal != null && card.cardHeal !== 0 && (
        <Heal card={card} width={width} />
      )}
    </>
  );
};

const Damage = ({ width, card }) => (
  <Box
    sx={{
      zIndex: 5,
      width: "37.2%",
      position: "absolute",
      top: "-4.5%",
      left: "14.5%",
      "& img": {
        width: "100%",
      },
    }}
  >
    <img src="/images/templates/card/stats/damage.png" alt="Damage Card" />
    <Typography
      variant="span"
      textAlign="center"
      fontSize={(150 / 1181) * width}
      fontFamily="'Changa One', sans serif"
      color="#fff"
      sx={{
        width: "100%",
        position: "absolute",
        left: "1.7%",
        lineHeight: 1,
      }}
    >
      {card.cardDamage}
    </Typography>
  </Box>
);

const Shield = ({ width, card }) => (
  <Box
    sx={{
      zIndex: 5,
      width: "39.7%",
      position: "absolute",
      top: "-4.5%",
      left: card.cardDamage === 0 || card.cardDamage == null ? "13%" : "43%",
      "& img": {
        width: "100%",
      },
    }}
  >
    <img src="/images/templates/card/stats/shield.png" alt="Shield Card" />
    <Typography
      variant="span"
      textAlign="center"
      fontSize={(150 / 1181) * width}
      fontFamily="'Changa One', sans serif"
      color="#fff"
      sx={{
        width: "100%",
        position: "absolute",
        left: "1.7%",
        lineHeight: 1,
      }}
    >
      {card.cardShield}
    </Typography>
  </Box>
);

const Heal = ({ width, card }) => (
  <Box
    sx={{
      zIndex: 5,
      width: "39.7%",
      position: "absolute",
      top: "-4.5%",
      left:
        (card.cardDamage === 0 || card.cardDamage == null) &&
        (card.cardShield === 0 || card.cardShield == null)
          ? "13%"
          : (card.cardDamage !== 0 || card.cardDamage != null) &&
            (card.cardShield === 0 || card.cardShield == null)
          ? "43%"
          : (card.cardDamage === 0 || card.cardDamage == null) &&
            (card.cardShield !== 0 || card.cardShield != null)
          ? "45%"
          : "75%",
      "& img": {
        width: "100%",
      },
    }}
  >
    <img src="/images/templates/card/stats/heal.png" alt="Heal Card" />
    <Typography
      variant="span"
      textAlign="center"
      fontSize={(150 / 1181) * width}
      fontFamily="'Changa One', sans serif"
      color="#fff"
      sx={{
        width: "100%",
        position: "absolute",
        left: "1.7%",
        lineHeight: 1,
      }}
    >
      {card.cardHeal}
    </Typography>
  </Box>
);

const Tags = ({ tags, width, cardClass }) => {
  if (tags == null) {
    return <></>;
  }
  const tagImages = [
    "attack",
    "banish",
    "ethereal",
    "innate",
    "power",
    "retain_effect",
    "secret",
    "skill",
    "unplayable",
  ];
  const tagArray = tags.split(",").filter((tag) => tagImages.includes(tag));
  const numTags = tagArray.length;
  const tagHeight = (126 / 1181) * width;
  const primaryTags = ["attack", "skill", "secret", "power"];

  if (numTags === 0) {
    return <></>;
  }

  if (numTags <= 2) {
    return (
      <TagWrapper tagHeight={tagHeight} cardClass={cardClass}>
        {tagArray.map((tag, i) => {
          return (
            <img
              src={`/images/templates/card/tags/${tag}.png`}
              alt={`${tag} card tag`}
              key={`CARD_${i}`}
            />
          );
        })}
      </TagWrapper>
    );
  } else {
    let primary = tagArray.filter((tag) => primaryTags.includes(tag));

    return (
      <TagWrapper tagHeight={tagHeight} cardClass={cardClass}>
        {primary.length > 0 && (
          <img
            src={`/images/templates/card/tags/${primary[0]}.png`}
            alt={`${primary[0]} card tag`}
          />
        )}
        {tagArray.map((tag, i) => {
          if (!primary.includes(tag)) {
            return (
              <img
                src={`/images/templates/card/tags/${tag}-icon.png`}
                alt={`${tag} card tag`}
                key={`TAG_${i}`}
              />
            );
          }
          return <></>;
        })}
      </TagWrapper>
    );
  }
};

const TagWrapper = ({ children, tagHeight, cardClass }) => (
  <Stack
    direction="row"
    alignItems="center"
    gap="4px"
    sx={{
      zIndex: 5,
      position: "absolute",
      bottom: "30%",
      left:
        cardClass === "curse" || cardClass === "tool" || cardClass === "revenge"
          ? "50%"
          : "31%",
      transform:
        cardClass === "curse" || cardClass === "tool" || cardClass === "revenge"
          ? "translateX(-50%)"
          : "none",
      "& img": { height: tagHeight },
    }}
  >
    {children}
  </Stack>
);

const BodyPart = ({ part }) => {
  if (part == null) {
    return null;
  }
  const top = {
    eyes: "21%",
    ears: "21%",
    mouth: "24%",
    back: "26%",
    horn: "21%",
    tail: "25%",
  };
  const left = {
    eyes: "20.5%",
    ears: "20.5%",
    mouth: "20.5%",
    back: "20.5%",
    horn: "20.5%",
    tail: "20%",
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "49.4%",
        left: "-0.55%",
        zIndex: 5,
        width: "31.2%",
      }}
    >
      <Box
        sx={{
          "& img": {
            width: "100%",
          },
        }}
      >
        <img
          src={`/images/templates/card/frame/frame-owner-plate.png`}
          alt={`Axie card frame`}
        />
      </Box>

      <Box
        sx={{
          zIndex: 5,
          "& img": {
            width: "48.9%",
            left: left[part],
            position: "absolute",
            top: top[part],
          },
        }}
      >
        <img
          src={`/images/templates/card/body-parts/${part}.png`}
          alt={`Axie card - ${part} body part`}
        />
      </Box>
    </Box>
  );
};

const Energy = ({ energy, width }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "-9.4%",
        left: "-10.7%",
        zIndex: 5,
        width: "38.4%",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          "& img": {
            width: "100%",
          },
        }}
      >
        <img
          src={`/images/templates/card/frame/frame-energy.png`}
          alt={`Card frame`}
        />
      </Box>
      <Box
        sx={{
          zIndex: 5,
          "& img": {
            width: "71.6%",
            left: "8%",
            top: "6%",
            position: "absolute",
          },
        }}
      >
        <img
          src={`/images/templates/card/frame/energy-icon.png`}
          alt={`Energy icon`}
        />
      </Box>
      <Typography
        textAlign="center"
        fontSize={(86 / 510) * width}
        fontFamily="'Changa One', sans serif"
        color="#fff"
        variant="span"
        sx={{
          position: "absolute",
          left: 0,
          top: "14.5%",
          width: "86.6%",
          lineHeight: 1,
        }}
      >
        {energy}
      </Typography>
    </Box>
  );
};

const CardName = ({ name, width, centered }) => {
  return (
    <Typography
      fontSize={(94 / 1181) * width}
      fontFamily="'Changa One', sans serif"
      variant="span"
      fontWeight={500}
      sx={{
        position: "absolute",
        bottom: "39.5%",
        left: centered ? "50%" : "31%",
        transform: centered ? "translateX(-50%)" : "none",
        textAlign: centered ? "center" : "left",
        color: "#fff",
        zIndex: 5,
        maxWidth: centered ? "none" : "63%",
        width: centered ? "90%" : "auto",
      }}
    >
      {name}
    </Typography>
  );
};

const CardText = ({ text, width }) => {
  if (text == null) {
    return <></>;
  }

  let fontSize = 76;
  if (text.length > 146) {
    fontSize = 65;
  }

  return (
    <Typography
      variant="span"
      textAlign="center"
      fontSize={(fontSize / 1181) * width}
      fontWeight={400}
      fontFamily="'Roboto', sans serif"
      sx={{
        position: "absolute",
        top: "72%",
        color: "#DBDBDB",
        maxWidth: "84%",
        left: 0,
        right: 0,
        m: "0 auto",
        lineHeight: 1.2,
        zIndex: 6,
        textShadow: "0px 0px 2px rgba(0,0,0,.3)",
      }}
    >
      <TextWithHighlights text={text} />
    </Typography>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(CardTemplate);
