import React from "react";
import Text from "../../../components/Typography/Text";
import Title from "../../../components/Typography/Title";
import { Grid, Stack } from "@mui/material";
import Section from "../../../components/Layout/Section";
import Image from "../../../components/Image/Image";

const Intro = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={12} md={8}>
        <Section>
          <Title>Rare Axies</Title>
          <Text large={true}>
            Rare Axies are collectible NFTs with unique genetics and aesthetics
            and a capped supply. They may also have different breeding abilities
            or reduced breeding requirements.
          </Text>
          <Text large={true}>
            In this Guide, you'll discover different types of Rare Axies, how
            they originated and their total supply.
          </Text>
        </Section>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Stack gap={1} alignItems="center">
          <Image
            src="/images/pages/guides/collectors/collecting.jpg"
            alt="Collecting Rare Axies"
            maxWidth={450}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Intro;
