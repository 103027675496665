import React from "react";
import { Stack } from "@mui/material";
import Label from "../Typography/Label";
import Text from "../Typography/Text";

const DecorativeList = ({ items }) => {
  return (
    <Stack gap={{ xs: 3, sm: 3, md: 2 }}>
      {items.map((item, i) => (
        <DecorativeListItem
          key={`ITEM_${i}`}
          title={item.title}
          icon={item.icon}
        >
          {item.description}
        </DecorativeListItem>
      ))}
    </Stack>
  );
};

const DecorativeListItem = ({ title, children, icon }) => {
  return (
    <Stack gap={3} direction="row" alignItems="center">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          img: {
            height: 80,
          },
        }}
      >
        <img src={`/images/pages/${icon}.png`} alt={title} />
      </Stack>
      <Stack gap={1} alignItems="flex-start">
        <Label>{title}</Label>
        <Text>{children}</Text>
      </Stack>
    </Stack>
  );
};

export default DecorativeList;
