import React from "react";
import CenteredContent from "../../../components/Layout/CenteredContent";
import SectionHeader from "../../../components/Layout/SectionHeader";
import Title from "../../../components/Typography/Title";
import Text from "../../../components/Typography/Text";
import { Stack } from "@mui/material";
import SubTitle from "../../../components/Typography/SubTitle";
import Section from "../../../components/Layout/Section";
import Info from "../../../components/Info/Info";
import Image from "../../../components/Image/Image";
import CustomLink from "../../../components/CustomLink/CustomLink";

const HowToBreed = () => {
  return (
    <CenteredContent>
      <SectionHeader>
        <Title>How To Breed</Title>
      </SectionHeader>

      <Stack gap={8}>
        <Stack gap={4}>
          <Image
            src="/images/pages/guides/breeding/click-breed.jpg"
            alt="Click Breed"
            borderRadius={3}
            boxShadow={true}
          />
          <Section
            info={
              <Info>Both Axies must be in your Inventory in order breed.</Info>
            }
          >
            <SubTitle>Prepare Your Axies</SubTitle>
            <Text large={true}>
              First, open the Marketplace and log in, if you aren't already. Go
              to your Inventory, click on one of the Axies you want to breed and
              then click Breed.
            </Text>
          </Section>
        </Stack>

        <Stack gap={4}>
          <Image
            src="/images/pages/guides/breeding/select-axie.jpg"
            alt="Select a mate"
            borderRadius={3}
            boxShadow={true}
          />
          <Section>
            <SubTitle>Select Your Mate</SubTitle>
            <Text large={true}>
              Click on the outline of an Axie to browse for your desired mate.
            </Text>
          </Section>
        </Stack>

        <Stack gap={4}>
          <Image
            src="/images/pages/guides/breeding/lets-breed.jpg"
            alt="Confirm the breed"
            borderRadius={3}
            boxShadow={true}
          />
          <Section
            info={
              <Info>
                You will need SLP and AXS tokens in order to breed. Check out
                the
                <CustomLink hashTo="/guides/breeding/#resource-requirements">
                  Resource Requirements
                </CustomLink>
                section for more info.
              </Info>
            }
          >
            <SubTitle>Let's Breed!</SubTitle>
            <Text large={true}>
              Click Let's Breed to confirm the breed. A Ronin confirmation
              dialog will open. Click Confirm and wait for the transaction to
              complete.
            </Text>
          </Section>
        </Stack>

        <Stack gap={4}>
          <Image
            src="/images/pages/guides/breeding/egg.jpg"
            alt="Axie egg"
            borderRadius={3}
            boxShadow={true}
          />
          <Section>
            <SubTitle>Hatch Countdown</SubTitle>
            <Text large={true}>
              An egg will appear in your inventory. Click on the egg to view its
              current state. After 5 days, you'll be able to hatch your egg!
            </Text>
          </Section>
        </Stack>
      </Stack>
    </CenteredContent>
  );
};

export default HowToBreed;
