import React from "react";
import { Grid, Stack } from "@mui/material";

const FooterColumn = ({ children }) => (
  <Grid item xs={12} sm={3}>
    <Stack gap={4}>{children}</Stack>
  </Grid>
);

export default FooterColumn;
