import { Stack, Grid } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import CustomLink from "../../components/CustomLink/CustomLink";
import Section from "../../components/Layout/Section";
import { useTheme } from "@mui/system";
import List from "../../components/List/List";
import ListItem from "../../components/List/ListItem";
import Image from "../../components/Image/Image";

const RunesCharms = () => {
  const theme = useTheme();
  return (
    <Stack gap={6}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={8}>
          <Section>
            <Section>
              <Title>Power-Ups</Title>
              <Text large={true}>
                Power-ups enable you to customize your Axie's abilities:
              </Text>

              <List>
                <ListItem>
                  <CustomLink to="/runes">Runes</CustomLink> give Axies special
                  passive effects that automatically activate in battle. You can
                  equip 1 Rune per Axie.
                </ListItem>
                <ListItem>
                  <CustomLink to="/charms">Charms</CustomLink> enhance the
                  abilities that Axies get from their Cards. You can equip 1
                  Charm per Card or 6 Charms in total.
                </ListItem>
              </List>

              <Text large={true}>
                Where do you get these power-ups? This is the exciting part.
                Some Runes and Charms can be crafted, while others can only be
                collected in-game or acquired from special events.
              </Text>
            </Section>
          </Section>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Stack
            alignItems="flex-end"
            justifyContent="center"
            gap={4}
            sx={{
              height: "100%",
              img: {
                width: "100%",
                maxWidth: 360,
              },
              [theme.breakpoints.down("md")]: {
                alignItems: "center",
                img: {
                  maxWidth: 260,
                },
              },
            }}
          >
            <Image
              to="/runes"
              src="/images/pages/what-are-axies/runes.png"
              alt="Axie Runes"
              scale={1.08}
            />
            <Image
              to="/charms"
              src="/images/pages/what-are-axies/charms.png"
              alt="Axie Charms"
              scale={1.08}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default RunesCharms;
