export const MOBILE_BREAKPOINT = 768;

export const TRIBALLY_SLOGAN = "Powered By Tribally Games";

export const AXIE_TECH_API = `https://${process.env.REACT_APP_API_HOSTNAME}`;
export const AXIE_TECH_GUILD_DISCORD = "https://discord.com/invite/NUJatfbB8g";
export const AXIE_TECH_COMPANY_NAME = "Axie Tech";
export const AXIE_TECH_CONTACT_EMAIL = "info@axie.tech";
export const AXIE_TECH_URL = "https://welcome.axie.tech";

export const MARKETPLACE_URL = "https://marketplace.axieinfinity.com/axie";
export const ESPORTS_SCHEDULE_URL =
  "https://skymavis.notion.site/Grant-3-Official-Calendar-1eb79c51ffd04fc9918fb0ac197f08e0";
export const CREATORS_PROGRAM =
  "https://axie.substack.com/p/axiecreatorprogram";
export const KATANA_URL = "https://katana.roninchain.com/#/swap";
export const BUILDERS_PROGRAM_URL = "https://axie.substack.com/p/builders";
export const BUILDERS_PROGRAM_APPLICATION =
  "https://skymavis.typeform.com/to/F6X80eks?typeform-source=x1tv4vaf40x.typeform.com";
export const AXIE_CODE_OF_CONDUCT = "https://axieinfinity.com/code-of-conduct/";
export const AXIE_INFINITY_DOWNLOAD = "https://welcome.skymavis.com/download/";
export const AXIE_INFINITY_SCATTER = "https://scatter.roninchain.com/";

export const AXIE_TECH_STEM = "https://axie.tech/";

export const CARD_EXPLORER_URL = AXIE_TECH_STEM + "explorer/cards";
export const RUNE_EXPLORER_URL = AXIE_TECH_STEM + "explorer/runes";
export const CHARM_EXPLORER_URL = AXIE_TECH_STEM + "explorer/charms";
export const CURSE_EXPLORER_URL = AXIE_TECH_STEM + "explorer/curses";
export const TOOL_EXPLORER_URL = AXIE_TECH_STEM + "explorer/tools";
export const INSPECTOR_URL = AXIE_TECH_STEM + "inspector";
export const BREEDING_SIMULATOR_URL = AXIE_TECH_STEM + "breeding-simulator";
export const BREEDING_COSTS_URL = AXIE_TECH_STEM + "breeding-costs";
export const BREEDING_CALCULATOR_URL = AXIE_TECH_STEM + "breeding-calculator";
export const TEAM_BUILDER_URL = AXIE_TECH_STEM + "team-builder";
export const SCHOLARSHIP_CALCULATOR_URL =
  AXIE_TECH_STEM + "scholarship-calculator";

export const AXIE_INFINITY_DISCORD =
  "https://welcome.skymavis.com/regional-servers/";
export const AXIE_INFINITY_TWITTER = "https://twitter.com/axieinfinity";
export const AXIE_INFINITY_ESPORTS_TWITTER = "https://twitter.com/EsportsAxie";
export const AXIE_INFINITY_TWITCH = "https://www.twitch.tv/axieinfinity";

export const AXIE_CLASSES = [
  "Beast",
  "Aquatic",
  "Plant",
  "Bug",
  "Bird",
  "Reptile",
  "Mech",
  "Dawn",
  "Dusk",
];
export const BODY_PARTS = ["Eyes", "Ears", "Back", "Mouth", "Horn", "Tail"];
export const AXIE_CLASS_COLORS = {
  plant: "#6cc000",
  bird: "#ff8bbd",
  beast: "#ffb812",
  bug: "#ff5341",
  aquatic: "#00b8ce",
  reptile: "#dc8be4",
  dusk: "#129092",
  dawn: "#beceff",
  mech: "#c6bdd4",
};

export const CURRENT_SEASON = "Season 7";
