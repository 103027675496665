import { Stack, Grid, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Info from "../../components/Info/Info";
import Image from "../../components/Image/Image";
import Section from "../../components/Layout/Section";
import CustomLink from "../../components/CustomLink/CustomLink";
import { INSPECTOR_URL } from "../../config";

const PotentialPoints = () => {
  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={4} order={{ xs: 1, sm: 1, md: 0 }}>
          <Section>
            <Image
              src="/images/pages/charms/potential-points.png"
              alt="Axie Potential Points"
              maxWidth={360}
            />
            <Info style={{ maxWidth: 350, mr: "auto" }}>
              Neutral Charms can be paid for with a mix of Potential Points from
              any class.
            </Info>
          </Section>
        </Grid>
        <Grid item xs={12} sm={12} md={8} order={{ xs: 0, sm: 0, md: 1 }}>
          <Stack gap={2}>
            <Stack gap={1}>
              <Title>Potential Points</Title>
              <Text large={true}>
                In order to equip a Charm, an Axie has to have enough
                <CustomLink
                  endSpace={false}
                  hashTo="/what-are-axies#potential-points"
                >
                  Potential Points
                </CustomLink>
                .
              </Text>

              <Text large={true}>
                An Axie has a total of 15{" "}
                <CustomLink
                  endSpace={false}
                  hashTo="/what-are-axies#potential-points"
                >
                  Potential Points
                </CustomLink>{" "}
                derived from the class of its body and its parts.
              </Text>
              <Text large={true}>
                An axie’s potential points are made up of: 3 points for the
                body, and 2 points for every part.
              </Text>
              <Text large={true}>
                Charms vary in{" "}
                <CustomLink
                  endSpace={false}
                  hashTo="/what-are-axies#potential-points"
                >
                  Potential Points
                </CustomLink>{" "}
                Cost.
              </Text>
              <Stack direction="row" flexWrap="wrap"></Stack>

              <Text large={true}>
                Wondering how many Potential Points your Axie has? Use the
                <CustomLink href={INSPECTOR_URL}>Axie Inspector</CustomLink> to
                find out!
              </Text>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PotentialPoints;
