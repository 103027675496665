import React from "react";
import { Stack } from "@mui/material";
import Text from "../Typography/Text";
import { getCardProperties } from "store/statusEffects/statusEffectsSelectors";
import { connect } from "react-redux";

const CardSpecialWords = ({ cardProperties }) => {
  return (
    <Stack gap={1}>
      {cardProperties.map((word, i) => (
        <Stack direction="row" gap={2} key={`WORD_${i}`}>
          <Stack
            alignItems="center"
            alignSelf="flex-start"
            sx={{
              background: (theme) => theme.palette.colors.black,
              p: "2px 6px",
              borderRadius: "8px",
              minWidth: 140,
            }}
          >
            <Text fontWeight={800} color="#FFE500">
              {word.name}:
            </Text>
          </Stack>
          <Text>{word.description}</Text>
        </Stack>
      ))}
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  cardProperties: getCardProperties(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardSpecialWords);
