import CustomLink from "../../components/CustomLink/CustomLink";

export const questions = [
  {
    question: "What are Charms?",
    answer: "Charms are wearable items that enhance an Axie's abilities.",
  },
  {
    question: "Where can I get Charms?",
    answer:
      "Some Charms can be crafted using in-game resources such as Moon Shards. Other Charms can only be collected as in-game rewards or obtained during special events.",
  },
  {
    question: "How many Charms can I equip?",
    answer: (
      <>
        Each Axie <CustomLink to="/cards">Card</CustomLink> can only hold 1 Charm at a time.
      </>
    ),
  },
  {
    question: "What's the difference between NFT and non-NFT Charms?",
    answer:
      "NFT Charms can be traded on the Marketplace. Most NFT Charms have specific Classes, while most non-NFT Charms are Neutral Class.",
  },
  {
    question: "What are Seasonal Charms?",
    answer:
      "With every season, new Seasonal Charms will be added, while some older Seasonal Charms will be deprecated. Deprecated Charms cannot be crafted or used in battle, but can still be kept in your collection.",
  },
  {
    question: "What are non-Seasonal Charms?",
    answer:
      "Non-Seasonal Charms are not deprecated in future Seasons of gameplay.",
  },
];
