import { Box, Stack } from "@mui/material";
import React from "react";
import Card from "components/Card/Card";
import Image from "components/Image/Image";
import Info from "components/Info/Info";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { useTheme } from "@mui/system";
import CustomLink from "components/CustomLink/CustomLink";
import { MARKETPLACE_URL } from "config";

const Intro = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        [theme.breakpoints.down("md")]: {
          mb: 20,
        },
        [theme.breakpoints.down("sm")]: {
          mb: 0,
        },
      }}
    >
      <Image
        src="/images/pages/starter-axies/starter-axies.jpg"
        alt="Starter Axies"
        borderRadius={3}
        boxShadow={true}
        style={{
          position: "relative",
          left: "50%",
          top: -120,
          transform: "rotate(-4deg) translate(-50%)",
          width: 900,
          zIndex: 1,
          [theme.breakpoints.down("sm")]: {
            transform: "none",
            position: "initial",
          },
        }}
      />
      <Card
        style={{
          position: "absolute",
          zIndex: 2,
          maxWidth: 550,
          right: 0,
          top: -100,
          [theme.breakpoints.down("md")]: {
            position: "absolute",
            top: "initial",
            bottom: 0,
          },
          [theme.breakpoints.down("sm")]: {
            position: "initial",
          },
        }}
      >
        <Stack gap={1}>
          <Title fontSize={26}>Meet Buba, Olek &amp; Puffy</Title>
          <Text>
            Buba, Olek and Puffy are just a few of the Starter Axies that
            Trainers are able to play with, completely for free! As you progress
            in Adventure, you can collect even more starter Axies to add to your
            team.
          </Text>
        </Stack>
      </Card>
      <Info
        style={{
          maxWidth: 600,
          bottom: 0,
          left: "37%",
          position: "absolute",
          zIndex: 3,
          [theme.breakpoints.down("md")]: {
            bottom: -200,
            left: "10%",
          },
          [theme.breakpoints.down("sm")]: {
            top: 0,
            left: 0,
            position: "relative",
            mt: 2,
          },
        }}
      >
        While Starter Axies are adorable, they aren't NFTs. This means you can't
        use them to earn SLP. Starter Axies also cannot breed or be traded. If
        you're interested in unlocking these features, pick out your own{" "}
        <CustomLink to="/personal-axies">Personal Axies</CustomLink>
        from the{" "}
        <CustomLink href={MARKETPLACE_URL} endSpace={false}>
          Marketplace
        </CustomLink>
        !
      </Info>
    </Box>
  );
};

export default Intro;
