import React from "react";
import Section from "components/Layout/Section";
import SubTitle from "components/Typography/SubTitle";
import ListItem from "components/List/ListItem";
import List from "components/List/List";

const BloodMoon = () => {
  return (
    <Section>
      <SubTitle>Blood Moon</SubTitle>

      <List ml={0}>
        <ListItem minimal={true}>
          Watch out! The Blood Moon mechanic reduces the max health of all Axies
          starting at the end of Turn 10 of a battle.
        </ListItem>
        <ListItem minimal={true}>
          All Axies Max HP is reduced by an increased amount each turn once
          Bloodmoon is activated.
        </ListItem>
        <ListItem minimal={true}>
          The maximum number of turns is fixed at 18, at which point all Axies
          Max HP is reduced completely.
        </ListItem>
      </List>
    </Section>
  );
};

export default BloodMoon;
