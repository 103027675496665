export const colors = {
  primary: "#FF6060",
  secondary: "#FADA5E",
  black: "#3A3A3A",
  white: "#fff",
  brown: "#936E59",
  darkBrown: "#5b463a",
  darkBlue: "#0E377E",
  shadow: "rgba(0,0,0,0.08)",
  background: "#fffcf9",
  adventure: "#00907C",
  arena: "#189ECF",
  paper: {
    light: "#FFF0DC",
    medium: "#F0DCC0",
    dark: "#E2CFB2",
  },
  star: "#FFB00F",
  wood: {
    background: "#39241A",
    text: "#FAE6C7",
    input: {
      background: "#271A11",
      border: "rgba(255,255,255,0.25)",
    },
  },
  scrollbar: {
    background: "#fffcf9",
    thumb: "#F0DCC0",
    thumbHover: "#FF6060",
  },
  typography: {
    main: "#3A3A3A",
    muted: "#8e8d8d",
  },
  cards: {
    cardEnergy: "#f7a411",
    cardDamage: "#BA3650",
    cardShield: "#006DBD",
    cardHeal: "#008F61",
    skill: "#00D830",
    ethereal: "#55FAFF",
    banish: "#3C87FF",
    innate: "#C8FE54",
    secret: "#E74EFF",
    power: "#FEB400",
    attack: "#FD6100",
    retain_effect: "#00F39D",
  },
  rarity: {
    Common: "#7E7272",
    Rare: "#76902F",
    Epic: "#34708E",
    Mystic: "#6B36A0",
  },
};
