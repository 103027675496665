import React from "react";
import Text from "../../components/Typography/Text";
import { Stack, Button } from "@mui/material";
import CenteredContent from "../../components/Layout/CenteredContent";
import { FaPlay } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import DecorativeBanner from "../../components/DecorativeBanner/DecorativeBanner";
import HowToPlayBasics from "../Guides/GameplayGuide/BattleBasics";

const HowToPlay = () => {
  return (
    <Stack gap={{ xs: 6, sm: 8, md: 10 }} id="how-to-play">
      <DecorativeBanner
        bannerSlug="map"
        titleSlug="new-players/how-to-play"
        title="How To Play"
        titleMaxWidth={400}
      />
      <CenteredContent>
        <Stack gap={4}>
          <HowToPlayBasics />
        </Stack>
        <Stack direction="row" gap={1}>
          <Text>
            If you'd like learn more about Axie battles, check out our detailed
            Gameplay Guide!
          </Text>{" "}
          <Button
            variant="contained"
            endIcon={<FaPlay />}
            size="small"
            sx={{ alignSelf: "flex-end", minWidth: "180px" }}
            to="/guides/gameplay"
            component={RouterLink}
          >
            Learn More
          </Button>
        </Stack>
      </CenteredContent>
    </Stack>
  );
};

export default HowToPlay;
