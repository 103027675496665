import { Stack, Grid } from "@mui/material";
import React from "react";
import Text from "components/Typography/Text";
import ClassIcon from "components/ClassIcon/ClassIcon";
import Label from "./Label";
import List from "components/List/List";
import ListItem from "components/List/ListItem";

const Info = ({ axie, potentialPoints, fetchingCards }) => (
  <Grid
    item
    xs={12}
    sm={12}
    md={6}
    lg={4}
    order={{ xs: 1, sm: 1, md: 1, lg: 0 }}
  >
    <Stack gap={2}>
      <Property
        title="Class"
        value={
          axie.class[0].toUpperCase() + axie.class.slice(1, axie.class.length)
        }
      />

      <Property title="Role" value={axie.role} />
      <Specialties axie={axie} />
      {!fetchingCards && <PotentialPoints potentialPoints={potentialPoints} />}
    </Stack>
  </Grid>
);

const Specialties = ({ axie }) => (
  <Stack gap={1}>
    <Label>Specialties</Label>
    <List>
      {axie.specialties.map((specialty, i) => (
        <ListItem key={`SPECIALTY_${i}`} minimal={true} fontWeight={500}>
          {specialty}
        </ListItem>
      ))}
    </List>
  </Stack>
);

const Property = ({ title, value }) => (
  <Stack gap={1}>
    <Label>{title}</Label>
    <Text fontWeight={500}>{value}</Text>
  </Stack>
);

const PotentialPoints = ({ potentialPoints }) => (
  <Stack gap={2}>
    <Label>Potential Points</Label>
    <Stack gap={2} direction="row" alignItems="center">
      {potentialPoints != null &&
        potentialPoints.map((points, i) => (
          <Stack key={`POINTS_${i}`} alignItems="center">
            <ClassIcon axieClass={points.class} />
            <Text fontWeight={800} large={true}>
              {points.amount}
            </Text>
          </Stack>
        ))}
    </Stack>
  </Stack>
);

export default Info;
