import { Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/system";

const Text = ({
  children,
  large,
  textAlign = "left",
  fontWeight = 400,
  color = "text.black",
  style,
  changa,
}) => {
  const theme = useTheme();

  return (
    <Typography
      variant="body2"
      fontSize={large ? 20 : 16}
      textAlign={textAlign}
      fontWeight={fontWeight}
      color={color}
      display="inline-block"
      sx={{
        ...style,
        fontFamily: changa ? "'Changa One', 'Poppins', sans serif" : undefined,
        [theme.breakpoints.down("sm")]: {
          fontSize: large ? 19 : 15,
        },
      }}
    >
      {children}
    </Typography>
  );
};

export default Text;
