import React from "react";
import styles from "./navigation.module.scss";
import * as config from "../../config";
import { NavLink, useLocation } from "react-router-dom";
import NavigationDropdown from "./NavigationDropdown";
import { IoMenu, IoClose } from "react-icons/io5";
import { useTheme } from "@mui/system";
import {
  IconButton,
  Stack,
  Container,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import CustomLink from "../CustomLink/CustomLink";

const MobileNavItem = ({ link, closeAndCollapse }) => {
  const heightPerItem = 50;
  const navHeight = link.dropdownItems.length * heightPerItem + 8;
  const { pathname } = useLocation();
  const mainLinkActive =
    link.dropdownItems.map((item) => item.to).includes(pathname) ||
    link.to === pathname;

  return link.dropdownItems.length ? (
    <ListItem
      onClick={() => link.setIsOpen(!link.isOpen)}
      sx={{
        borderBottom: `1px solid #eee`,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <LinkText isActive={mainLinkActive} text={link.text} />
      <div
        className={styles.mobileChildLinks}
        style={link.isOpen ? { height: navHeight } : { height: 0 }}
      >
        {link.dropdownItems.map((item, i) => (
          <NavLink
            to={item.to}
            key={`MOBILE_LINK_${i}`}
            onClick={() => closeAndCollapse()}
          >
            <LinkText isActive={item.to === pathname} text={item.text} />
          </NavLink>
        ))}
      </div>
    </ListItem>
  ) : (
    <ListItem sx={{ borderBottom: `1px solid #eee` }}>
      <NavLink to={link.to} onClick={() => closeAndCollapse()}>
        <LinkText isActive={mainLinkActive} text={link.text} />
      </NavLink>
    </ListItem>
  );
};

export const MobileNav = ({ links, setMobileMenuOpen, mobileMenuOpen }) => {
  const closeAndCollapse = () => {
    Object.keys(links).forEach((link) => {
      if ("setIsOpen" in links[link]) {
        links[link].setIsOpen(false);
      }
    });
    setMobileMenuOpen(false);
  };
  return (
    <div
      className={`${styles.mobileNav} ${
        mobileMenuOpen ? styles.mobileNavOpen : ""
      }`}
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Container fixed>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 0.5 }}
        >
          <CustomLink style={{ img: { height: 40 } }} to="/">
            <img src="/images/logo.png" alt="Axie Tech Logo" />
          </CustomLink>
          <IconButton
            className={styles.hamburger}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            {mobileMenuOpen ? <IoClose /> : <IoMenu />}
          </IconButton>
        </Stack>

        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 6,
            p: 0,
            overflowY: "auto",
          }}
        >
          {Object.keys(links).map((link, i) => (
            <MobileNavItem
              link={links[link]}
              closeAndCollapse={closeAndCollapse}
              key={`MOBILE_ITEM_${i}`}
            />
          ))}
        </List>
      </Container>
    </div>
  );
};

const LinkText = ({ isActive, text, colorOverrides }) => {
  const theme = useTheme();
  return (
    <Typography
      className={styles.navLink}
      fontSize="15px"
      sx={{
        whiteSpace: "nowrap",
        color: colorOverrides?.navigation?.text
          ? colorOverrides?.navigation?.text
          : isActive
          ? theme.palette.text.primary
          : theme.palette.text.regular,
        "&:hover": {
          color: colorOverrides?.navigation?.textHover
            ? colorOverrides?.navigation?.textHover
            : theme.palette.text.primary,
        },
        fontWeight: isActive ? 700 : 600,
      }}
    >
      {text}
    </Typography>
  );
};

const DesktopNavItem = ({ link, colorOverrides }) => {
  const { pathname } = useLocation();
  const isActive =
    link.dropdownItems.some((item) => pathname.includes(item.to)) ||
    link.to === pathname;
  return link.dropdownItems.length ? (
    <ListItem
      onMouseEnter={() => onMouseEnterDropdown(link.setIsOpen)}
      onMouseLeave={() => onMouseLeaveDropdown(link.setIsOpen)}
      sx={{ p: 0 }}
    >
      <LinkText
        isActive={isActive}
        text={link.text}
        colorOverrides={colorOverrides}
      />
      {link.isOpen && (
        <NavigationDropdown
          menuItems={link.dropdownItems}
          alignRight={link.alignRight}
          colorOverrides={colorOverrides}
        />
      )}
    </ListItem>
  ) : (
    <ListItem sx={{ p: 0 }}>
      <NavLink to={link.to}>
        <LinkText
          isActive={isActive}
          text={link.text}
          colorOverrides={colorOverrides}
        />
      </NavLink>
    </ListItem>
  );
};

export const DesktopNav = ({ links, setMobileMenuOpen, colorOverrides }) => (
  <div className={styles.desktopNav}>
    <List sx={{ display: "flex", alignItems: "center", p: 0 }}>
      {Object.keys(links).map((link, i) => (
        <DesktopNavItem
          link={links[link]}
          key={`NAVIGATION_LINK_${i}`}
          setMobileMenuOpen={setMobileMenuOpen}
          colorOverrides={colorOverrides}
        />
      ))}
    </List>
  </div>
);

const onMouseEnterDropdown = (setOpen) => {
  if (window.innerWidth < config.MOBILE_BREAKPOINT) {
    setOpen(false);
  } else {
    setOpen(true);
  }
};

const onMouseLeaveDropdown = (setOpen) => {
  if (window.innerWidth < config.MOBILE_BREAKPOINT) {
    setOpen(false);
  } else {
    setOpen(false);
  }
};
