import { Stack, Grid, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Image from "../../components/Image/Image";
import List from "../../components/List/List";
import ListItem from "../../components/List/ListItem";
import Info from "../../components/Info/Info";
import Section from "../../components/Layout/Section";

const SeasonsRewards = () => {
  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={7}>
          <Section>
            <Title>Seasons &amp; Rewards</Title>
            <Text large={true}>
              Ranked Mode takes place over different Seasons, each lasting
              around 2 months, with a 1 month break in between.
            </Text>

            <Text large={true}>
              Players can receive 2 types of rewards each Season:
            </Text>

            <List ml={0}>
              <ListItem title="First-Time Rewards">
                Received every time players are promoted to a new Rank or Tier.
              </ListItem>
              <ListItem title="End-of-Season Rewards">
                Received at the end of the Season.
              </ListItem>
            </List>

            <Text large={true}>
              At the start of a new Season, a player's Rank will be reset to Egg
              IV and their Victory Stars will be reduced to 0.
            </Text>
          </Section>
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <Stack gap={3}>
            <Image
              maxWidth={340}
              src="/images/pages/arena/rewards.jpg"
              alt="Origins Season 5 Leaderboard Rewards"
              title="Origins Season 5 Leaderboard Rewards"
              style={{ m: "0 auto" }}
            />
            <Info style={{ maxWidth: 340, m: "0 auto" }}>
              You can still compete in Ranked gameplay between Seasons. There
              are sometimes off-season rewards available to be earned!
            </Info>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SeasonsRewards;
