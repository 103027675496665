import React from "react";
import Section from "components/Layout/Section";
import SubTitle from "components/Typography/SubTitle";
import ListItem from "components/List/ListItem";
import List from "components/List/List";

const Keep = () => {
  return (
    <Section>
      <SubTitle>Keep</SubTitle>

      <List ml={0}>
        <ListItem minimal={true}>
          Keep is a more complex mechanic that gives you the option of spending
          one energy to ensure you redraw a card in the next round.
        </ListItem>
        <ListItem minimal={true}>
          This card can no longer be used in the turn you opted to keep it.
        </ListItem>
        <ListItem minimal={true}>
          This is helpful when there’s no beneficial play in that round or when
          you’re trying to set up a huge combo play!
        </ListItem>
        <ListItem>
          Don’t worry if it’s hard to see where you’ll get use for keep at
          first, you’ll get the hang of it as you play!
        </ListItem>
      </List>
    </Section>
  );
};

export default Keep;
