import { Chip, Stack, Box, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/system";

const Title = ({
  children,
  textAlign = "left",
  color = "text.black",
  fontSize = 40,
  newTag,
  comingSoonTag,
  accent,
  style,
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      justifyContent={textAlign === "center" ? "center" : "flex-start"}
      sx={{ ...style }}
    >
      {accent && (
        <img src="/images/accents/pattern-title.png" alt="Pattern Accent" />
      )}
      <Typography
        variant="h2"
        textAlign={textAlign}
        fontSize={fontSize}
        color={color}
        sx={{
          [theme.breakpoints.down("sm")]: {
            fontSize: fontSize * 0.9,
          },
        }}
      >
        {children}
      </Typography>
      {accent && (
        <Box sx={{ img: { transform: "rotate(180deg)" } }}>
          <img src="/images/accents/pattern-title.png" alt="Pattern Accent" />
        </Box>
      )}
      {newTag && <Chip label="New!" color="primary" />}
      {comingSoonTag && <Chip label="Coming Soon!" color="secondary" />}
    </Stack>
  );
};

export default Title;
