import React from "react";
import { Box } from "@mui/material";

const DecorativeBackground = () => {
  return (
    <Box
      sx={{
        px: 2,
        position: "relative",
        height: 1000,
        mt: -80,
        mb: 2,
        zIndex: 1,
        overflow: "hidden",
        borderRadius: "24px",
        "& img": {
          borderRadius: "24px",
          width: "calc(100% - 32px)",
          position: "absolute",
          bottom: 0,
        },
      }}
    >
      <img
        src="/images/banners/background-footer.jpg"
        alt="Axie Infinity Forest"
      />
      <Box
        sx={{
          height: "100%",
          background: "linear-gradient(#fffcf9, #fffcf900 10%)",
          position: "relative",
          zIndex: 2,
        }}
      />
    </Box>
  );
};

export default DecorativeBackground;
