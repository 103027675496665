import { combineReducers } from "redux";
import dataReducer from "./data/dataReducer";
import statusEffectsReducer from "./statusEffects/statusEffectsReducer";

const rootReducer = combineReducers({
  data: dataReducer,
  statusEffects: statusEffectsReducer,
});

export default rootReducer;
