import React from "react";
import Page from "../../components/Page/Page";
import FAQ from "../../components/FAQ/FAQ";
import { questions } from "./questions";
import CallToAction from "../../components/CallToAction/CallToAction";
import Seasonality from "./Seasonality";
import RuneBasics from "./RuneBasics";
import EquippingRunes from "./EquippingRunes";
import Diagram from "../../components/Diagram/Diagram";
import NFTvsNonNFT from "./NFTvsNonNFT";
import PageSEO from "../../components/SEO/PageSEO";
import StarterRunes from "pages/Runes/StarterRunes";

const Runes = () => {
  return (
    <>
      <PageSEO
        title="Runes | Axie.Tech"
        description="Runes are Special Items that give Axies passive effects that automatically activate in battle."
      />
      <Page
        headerImg="/images/banners/runes.jpg"
        title="Runes"
        headerHeight={300}
        headerText="Runes provide passive effects that automatically activate in battle."
        container={false}
      >
        <RuneBasics />
        <EquippingRunes />
        <Diagram type="rune" />
        <Seasonality />
        <NFTvsNonNFT />
        <StarterRunes />
        <FAQ questions={questions} />
      </Page>
      <CallToAction
        title="More Power-Ups!"
        description="Learn how to power-up your Axie's Cards using Charms."
        buttonText="Charms"
        buttonTo="/charms"
        axie="puffy"
      />
    </>
  );
};

export default Runes;
