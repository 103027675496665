import { Stack } from "@mui/material";
import React from "react";

const List = ({ children, ml = 3 }) => {
  return (
    <Stack gap={1} sx={{ ml }}>
      {children}
    </Stack>
  );
};

export default List;
