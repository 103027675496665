import Info from "components/Info/Info";
import Bold from "components/Typography/Bold";
import { useTheme } from "@mui/material";

const EraInfo = ({ itemType }) => {
  const theme = useTheme();

  return (
    <Info
      style={{
        maxWidth: 370,
        position: "absolute",
        right: -32,
        bottom: -80,
        [theme.breakpoints.down("lg")]: {
          position: "relative",
          bottom: 0,
          right: "auto",
          left: "50%",
          transform: "translateX(-50%)",
        },
        [theme.breakpoints.down("md")]: {
          maxWidth: "100%",
        },
      }}
    >
      Origins season are split into 3 Eras. Eras limit the Runes & Charms you
      can use in your team! <br />
      <Bold>Rare Era:</Bold> Common & Rare {itemType}. <br />
      <Bold>Epic Era:</Bold> Common, Rare & Epic {itemType}. <br />
      <Bold>Mystic Era:</Bold> Common, Rare, Epic & Mystic {itemType}.
    </Info>
  );
};

export default EraInfo;
