import { Stack } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";

const CenteredContent = ({ children }) => {
  const theme = useTheme();
  return (
    <Stack
      gap={4}
      sx={{
        px: 2,
        maxWidth: 800,
        m: "0 auto",
        [theme.breakpoints.down("sm")]: {
          px: 2,
          maxWidth: 600,
        },
      }}
    >
      {children}
    </Stack>
  );
};

export default CenteredContent;
