import { Box, Button, Grid, Stack } from "@mui/material";
import React from "react";
import SubTitle from "../../components/Typography/SubTitle";
import Text from "../../components/Typography/Text";
import { Link as RouterLink } from "react-router-dom";
import Card from "../../components/Card/Card";
import { FaPlay } from "react-icons/fa";
import { useTheme } from "@mui/system";

const StarterAxies = () => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={6}
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 40,
          left: -10,
          "& img": {
            width: 80,
            transform: "rotate(-8deg)",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        }}
      >
        <img src="images/accents/axies/puffy-right.png" alt="Puffy" />
      </Box>
      <Card>
        <Stack
          gap={2}
          sx={{
            px: 4,
            [theme.breakpoints.down("sm")]: {
              px: 0,
            },
          }}
        >
          <Stack gap={1}>
            <SubTitle>Starter Axies</SubTitle>
            <Text>
              As a new player you’ll receive 3 free starter axies named Buba,
              Olek & Puffy. These axies are soulbound axies. Meaning they are
              not NFT’s, therefore cannot be traded with other players or bred
              to create more axies on the marketplace. You can unlock more
              Starter axies by playing adventure mode!
            </Text>
          </Stack>
          <Button
            variant="contained"
            endIcon={<FaPlay />}
            to="/starter-axies"
            component={RouterLink}
            size="small"
            sx={{ alignSelf: "flex-start" }}
          >
            Learn More
          </Button>
        </Stack>
      </Card>
      <Box
        sx={{
          position: "absolute",
          display: "none",
          zIndex: 2,
          [theme.breakpoints.down("lg")]: {
            right: 150,
            bottom: 0,
            transform: "translateY(80%)",
            display: "block",
            img: { width: 80, transform: "rotate(-8deg)" },
          },
        }}
      >
        <img src="images/accents/axies/puffy-right.png" alt="Puffy" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: -80,
          right: -10,
          zIndex: 2,
          "& img": {
            width: 150,
            transform: "rotate(8deg)",
          },
          [theme.breakpoints.down("sm")]: {
            right: 0,
          },
        }}
      >
        <img src="images/accents/axies/olek.png" alt="Olek" />
      </Box>
    </Grid>
  );
};

export default StarterAxies;
