import { Typography, Stack } from "@mui/material";
import { removeSpecialCharactersInDescription } from "helpers/cleaning";
import React from "react";
import Capsule from "../../components/Capsule/Capsule";
import Card from "../../components/Card/Card";

const Effect = ({ icon, name, description, stackable }) => {
  return (
    <Card
      style={{
        transition: "0.2s transform ease",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.05)",
        },
      }}
    >
      <Stack
        direction="row"
        gap={2}
        sx={{
          "& img": {
            width: 60,
            objectFit: "contain",
          },
        }}
      >
        <img src={icon} alt={`${name} - Axie Status Effect`} />
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">{name}</Typography>
            {stackable && <Capsule>Stackable</Capsule>}
          </Stack>
          <Typography variant="body2">
            {removeSpecialCharactersInDescription(description)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default Effect;
