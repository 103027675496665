import React from "react";
import CallToAction from "../../../components/CallToAction/CallToAction";
import Page from "../../../components/Page/Page";
import PageSEO from "../../../components/SEO/PageSEO";
import HelpfulTools from "./HelpfulTools";
import HowToBreed from "./HowToBreed";
import Intro from "./Intro";
import ResourceRequirements from "./ResourceRequirements";

const Builds = () => {
  return (
    <>
      <PageSEO
        title="Axie Breeding Guide | Axie.Tech"
        description="Just like real-world pets, Personal Axies can breed to create more Axies. In this Guide, you'll learn how to breed your Axies and what resources are required."
      />
      <Page
        headerImg="/images/banners/eggs.jpg"
        headerHeight={360}
        title="Breeding"
        headerText="A beginner's guide to breeding Axies."
      >
        <Intro />
        <HowToBreed />
        <ResourceRequirements />
        <HelpfulTools />
      </Page>
      <CallToAction
        title="Ready to do some breeding?"
        description="Head over to the Marketplace to find the Axies you want to breed!"
        marketplaceButton={true}
        axie="puffy"
      />
    </>
  );
};

export default Builds;
