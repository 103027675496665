import { createSelector } from "reselect";

const getCards = (state) => state.data.cards || [];
const getCurses = (state) => state.data.curseCards || [];
const getTools = (state) => state.data.toolCards || [];
const getRunes = (state) => state.data.runes || [];
const getCardsLoading = (state) => state.data.fetchingCards;

const sortByName = (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

export const getSummonCards = createSelector(
  [getCards, getCardsLoading],
  (cards, loading) => {
    const summons = cards.filter((c) => c.summon.name !== null);

    return {
      summons: summons.sort((a, b) => sortByName(a, b)),
      loading,
    };
  }
);

export const getCurseCards = createSelector(
  [getCurses, getCards, getCardsLoading],
  (curses, cards, loading) => {
    const mapping = {
      Blackmail: ["back-pigeon-post"],
      Burn: ["tail-hot-butt"],
      Confused: ["eyes-confused", "ears-swirl"],
      Goo: [
        "ears-ear-breathing",
        "back-snail-shell",
        "horn-unko",
        "tail-thorny-caterpillar",
        "horn-lagging",
        "mouth-pincer",
      ],
      Jinx: ["mouth-doubletalk", "ears-inkling", "tail-tadpole", "back-raven"],
      Scorch: ["mouth-blackflame-tongue"],
      Void: ["mouth-goda"],
    };

    const augmented = curses.map((c) => {
      const spawnedFromCards = [];

      mapping[c.name].forEach((partId) => {
        const item = cards.find((c) => c.partId === partId);
        if (item) {
          spawnedFromCards.push(item);
        }
      });
      return { ...c, spawnedFromCards };
    });

    return {
      curses: augmented,
      loading,
    };
  }
);

export const getToolCards = createSelector(
  [getTools, getCards, getRunes, getCardsLoading],
  (tools, cards, runes, loading) => {
    const specialMapping = {
      "Energy Coin": ["Given to the player who goes 2nd"],
    };
    const runeMapping = {
      Scan: ["Trap Nullifier"],
      "Giant Bubble": ["Giant Bubble"],
      Purge: ["Dark Flame"],
      Prayer: ["Divine Ring"],
      "Venom Burst": ["Venom Master"],
    };
    const pardIdMapping = {
      "Lumber Shield": ["back-timber"],
      "Feather Dagger": ["horn-wing-horn"],
      Mandarine: ["horn-mandarine"],
      Pacu: ["mouth-tiny-dino"],
      "Pure Water": ["back-watering-can"],
      Strawberry: ["horn-strawberry-shortcake"],
    };

    const augmented = tools.map((c) => {
      const spawnedFromCards = [];
      const spawnedFromRunes = [];
      const spawnedFromSpecial = [];

      if (c.name in pardIdMapping) {
        pardIdMapping[c.name].forEach((partId) => {
          const item = cards.find((c) => c.partId === partId);
          if (item) {
            spawnedFromCards.push(item);
          }
        });
      }
      if (c.name in runeMapping) {
        runeMapping[c.name].forEach((rune) => {
          const item = runes.find((r) => r.name === rune);
          if (item) {
            spawnedFromRunes.push(item);
          }
        });
      }
      if (c.name in specialMapping) {
        specialMapping[c.name].forEach((text) => {
          spawnedFromSpecial.push(text);
        });
      }
      return { ...c, spawnedFromCards, spawnedFromRunes, spawnedFromSpecial };
    });

    return {
      tools: augmented,
      loading,
    };
  }
);
