import { Stack, Typography, useTheme } from "@mui/material";

export const H2 = ({ children }) => (
  <Typography variant="h2" color="text.primary" fontSize="26px">
    {children}
  </Typography>
);
export const H3 = ({ children }) => (
  <Typography
    variant="h3"
    color="text.bright"
    fontSize="15px"
    fontWeight="700"
    sx={{ textTransform: "uppercase", letterSpacing: "0.5px" }}
  >
    {children}
  </Typography>
);
export const P = ({ children }) => (
  <Typography variant="body2" color="text.regular" sx={{ lineHeight: 1.78 }}>
    {children}
  </Typography>
);

export const Strong = ({ children }) => (
  <Typography variant="span" color="text.bright" fontWeight="700">
    {children}
  </Typography>
);

export const Section = ({ children }) => <Stack gap={2}>{children}</Stack>;

export const List = ({ children }) => (
  <ul style={{ listStyleType: "disc", paddingLeft: 32 }}>{children}</ul>
);

export const ListItem = ({ children }) => {
  const theme = useTheme();
  return <li style={{ color: theme.palette.text.regular }}>{children}</li>;
};

export const Note = ({ children }) => (
  <Typography
    variant="body2"
    color="text.muted"
    fontSize="14px"
    sx={{ fontStyle: "italic" }}
  >
    {children}
  </Typography>
);
