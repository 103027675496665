export const teams = [
  {
    id: "rage",
    name: "Rage Build",
    url: "https://www.axie.tech/team-builder?front=8-400-472-350-306-300-258-0-0-0-0-0-0-0&mid=2-380-388-242-255-248-258-0-0-0-0-0-0-0&back=2-380-388-312-255-248-344-0-0-0-0-0-0-0",
    strategy:
      "Stack Rage on the midliner and backliner as quickly as possible, enter Fury Mode and annihilate the opponent with crits.",
    axies: {
      front: {
        parts: [
          "eyes-mavis",
          "ears-hollow",
          "mouth-herbivore",
          "horn-rose-bud",
          "back-shiitake",
          "tail-shiba",
        ],
      },
      mid: {
        parts: [
          "eyes-chubby",
          "ears-belieber",
          "mouth-confident",
          "horn-little-branch",
          "back-ronin",
          "tail-shiba",
        ],
      },
      back: {
        parts: [
          "eyes-chubby",
          "ears-belieber",
          "mouth-confident",
          "horn-little-branch",
          "back-tri-spikes",
          "tail-post-fight",
        ],
      },
    },
    rationale: [
      "Damage dealers have as many rage-stacking abilities as possible, along with high damage attacks and attacks which synergise well with Fury mode such as Ronin and Post-Fight.",
      "Tank has 3 summon abilities which boost Rage output of Little Branch.",
      "Summons and heals add some sustain and protect the damage dealers.",
    ],
    strongAgainst: [
      {
        description:
          "Sustain teams - Fury mode can burst through with single-turn kills, preventing a sustain team from healing back up.",
      },
      {
        description:
          "Poison/Bleed teams - Burst damage and aggression will usually take the upper hand before Poison/Bleed can tick down the opponent's health quickly enough.",
      },
    ],
    weakAgainst: [
      {
        description:
          "Backdoor teams - Opponents can often use targetting abilities to kill our damage dealer before we can stack enough Rage to hit Fury mode.",
      },
      {
        description:
          "Curses teams - Getting the most out of Fury mode is highly dependent on getting a good card draw. Disrupting our deck with curse cards lowers our chances as the battle goes on, and can make it very difficult to capitalise on Fury Mode rounds.",
      },
    ],
  },
  {
    id: "sustain",
    name: "Sustain Build",
    url: "https://www.axie.tech/team-builder?front=8-453-444-296-304-352-358-0-0-0-0-0-0-0&mid=1-363-373-227-233-231-237-0-0-0-0-0-0-0&back=1-363-373-227-233-231-237-0-0-0-0-0-0-0",
    strategy:
      "Keep our HP high, chip away at the opponent and out-sustain them. Exploit the deck advantage of having more axies alive than your opponent.",
    axies: {
      front: {
        parts: [
          "eyes-cucumber-slice",
          "ears-lotus",
          "mouth-serious",
          "horn-beech",
          "back-bidens",
          "tail-snake-jar",
        ],
      },
      mid: {
        parts: [
          "eyes-clear",
          "ears-seaslug",
          "mouth-lam",
          "horn-anemone",
          "back-anemone",
          "tail-nimo",
        ],
      },
      back: {
        parts: [
          "eyes-clear",
          "ears-seaslug",
          "mouth-lam",
          "horn-anemone",
          "back-anemone",
          "tail-nimo",
        ],
      },
    },
    rationale: [
      "Damage dealers both have double anemone, providing a lot of passive healing as they deal out damage. Lam boosts damage when HP is high which works well here.",
      "This is paired with many Banish cards which decrease our deck size and enable more frequent draws on Anemone.",
      "Tank boasts Cucumber Slice and Snake Jar for even more sustain, plus Bidens to remove any early debuffs.",
    ],
    strongAgainst: [
      {
        description:
          "Poison teams - cleanses prevent poison build-up and often means that poison teams lack the burst damage to kill off sustain teams.",
      },
      {
        description:
          "Backdoor teams - Snake Jar and healing are perfect for disrupting a backdoor strategy.",
      },
    ],
    weakAgainst: [
      {
        description:
          "Rage teams - Fury mode can burst through with single-turn kills, preventing a sustain team from healing back up.",
      },
      {
        description:
          "DMG ramp teams - DMG ramp will usually outpace healing and overpower sustain teams, especially late-game.",
      },
    ],
  },
  {
    id: "dmg-ramp",
    name: "Damage Ramp Build",
    url: "https://www.axie.tech/team-builder?front=8-447-401-351-304-301-237-0-0-0-0-0-0-0&mid=2-379-478-296-254-302-260-0-0-0-0-0-0-0&back=3-477-405-282-273-302-256-0-0-0-0-0-0-0",
    strategy:
      "Banish and discard Cards as much as possible to focus on drawing and playing as many Nut Cracker and Little Owl attacks as possible. If your tank can sustain for long enough, the ramped DMG will overpower your opponent in the late game.",
    axies: {
      front: {
        parts: [
          "eyes-blossom",
          "ears-pink-cheek",
          "mouth-serious",
          "horn-strawberry-shortcake",
          "back-pumpkin",
          "tail-nimo",
        ],
      },
      mid: {
        parts: [
          "eyes-zeal",
          "ears-nut-cracker",
          "mouth-nut-cracker",
          "horn-cactus",
          "back-bidens",
          "tail-nut-cracker",
        ],
      },
      back: {
        parts: [
          "eyes-little-owl",
          "ears-owl",
          "mouth-little-owl",
          "horn-cactus",
          "back-spiky-wing",
          "tail-cottontail",
        ],
      },
    },
    rationale: [
      "Damage dealers both have 3 DMG ramp cards each, 3x Nut Cracker and 3x Little Owl. Late game these cards will do enormous damage for only 1 energy each.",
      "These are combined with some Banish cards to allow the DMG ramp cards to be drawn more frequently as well as some high DMG cards.",
      "Tank provides some sustain through Strawberry and Pumpkin, as well as helping to cycle through cards quicker (and hit DMG ramp cards more often) with Pink Cheek and others.",
    ],
    strongAgainst: [
      {
        description:
          "Sustain teams - DMG ramp will usually outpace healing and overpower sustain teams, especially late-game.",
      },
      {
        description:
          "Rage teams - DMG ramp teams are slightly less bursty but more reliable at increasing damage output over the course of the battle.",
      },
    ],
    weakAgainst: [
      {
        description:
          "Backdoor teams - Opponents can often use targetting abilities to kill our damage dealer before we have time to ramp up our damage.",
      },
      {
        description:
          "Curse teams - The enemy curses slow down our damage ramp and limit our damage output late-game. Banishing cards to reduce our deck size also compounds the disruption effect of curses.",
      },
    ],
  },
  {
    id: "poison-bleed",
    name: "Poison/Bleed Build",
    url: "https://www.axie.tech/team-builder?front=8-438-382-296-304-301-310-0-0-0-0-0-0-0&mid=9-452-450-313-304-321-294-0-0-0-0-0-0-0&back=9-452-429-278-304-321-294-0-0-0-0-0-0-0",
    strategy:
      "Stack as much poison onto all enemies as early in the battle as possible, continue to add poison and bleed throughout the battle and use Thorny Caterpillar for big damage on debuffed targets.",
    axies: {
      front: {
        parts: [
          "eyes-papi",
          "ears-puppy",
          "mouth-serious",
          "horn-strawberry-shortcake",
          "back-bidens",
          "tail-yam",
        ],
      },
      mid: {
        parts: [
          "eyes-scar",
          "ears-small-frill",
          "mouth-serious",
          "horn-incisor",
          "back-green-thorns",
          "tail-thorny-caterpillar",
        ],
      },
      back: {
        parts: [
          "eyes-scar",
          "ears-sidebarb",
          "mouth-serious",
          "horn-incisor",
          "back-garish-worm",
          "tail-thorny-caterpillar",
        ],
      },
    },
    rationale: [
      "As many poison-inducing abilities as possible across the damage dealers.",
      "These are combined with some Bleed damage cards, as well as Thorny Caterpillar for huge damage output on debuffed targets.",
      "Tank provides some sustain through Strawberry and Papi, as well as some poison assistance with Yam.",
    ],
    strongAgainst: [
      {
        description:
          "Curse teams - Poison builds are one of the least affected by deck disruption, as they get their poison stacks on early, and our high-damage cards like Thorny and Garish will reduce the impact of deck disruption.",
      },
      {
        description:
          "A wide variety of teams - we can switch between sustain and longer-ticking Poison/bleed effects vs high damage output from Thorny Caterpillar and Garish Worm when we need to play more aggressively.",
      },
    ],
    weakAgainst: [
      {
        description:
          "Sustain teams - heals and cleanses prevent poison build-up and often means that poison teams lack the burst damage to kill off sustain teams.",
      },
      {
        description:
          "Cleanse - Any team with cleanse abilities can remove our Poison/Bleed, which not only reduces the damage ticking, but also reduces the effectiveness of our big damage cards.",
      },
    ],
  },
  {
    id: "curse",
    name: "Curse Build",
    url: "https://www.axie.tech/team-builder?front=8-458-444-345-289-286-354-0-0-0-0-0-0-0&mid=4-361-424-279-338-285-348-0-0-0-0-0-0-0&back=6-361-368-264-338-341-238-0-0-0-0-0-0-0",
    strategy:
      "Stack the opponent's deck with as many curse cards as possible and disrupt their deck enough to out-damage them mid/late-game.",
    axies: {
      front: {
        parts: [
          "eyes-gecko",
          "ears-lotus",
          "mouth-cute-bunny",
          "horn-pliers",
          "back-snail-shell",
          "tail-hot-butt",
        ],
      },
      mid: {
        parts: [
          "eyes-sleepless",
          "ears-ear-breathing",
          "mouth-goda",
          "horn-caterpillars",
          "back-buzz-buzz",
          "tail-gravel-ant",
        ],
      },
      back: {
        parts: [
          "eyes-sleepless",
          "ears-inkling",
          "mouth-goda",
          "horn-kestrel",
          "back-raven",
          "tail-tadpole",
        ],
      },
    },
    rationale: [
      "Sleepless, Raven, Inkling, Tadpole, Buzz Buzz, Ear Breathing, Snail Shell and Hot Butt can all shuffle curse cards into the opponent's deck.",
      "Caterpillars, Cute Bunny and Gravel Ant can all put Fear onto enemies, a debuff which shuffles Curse cards into the opponent's deck when they play Attack cards - helping to counter aggressive teams.",
      "Kestrel disables an enemy's attack cards, which further compounds the deck disruption that our curses are causing. Gecko and Lotus provide a healing option and a cleanse for any troublesome debuffs.",
    ],
    strongAgainst: [
      {
        description:
          "Rage teams - Disrupting a Rage team's deck can make it very difficult for the opponent to capitalise on their Fury Mode rounds. Their small deck sizes also compound the disruption effect.",
      },
      {
        description:
          "DMG Ramp teams - DMG ramp teams try to overpower you in the late-game. Our curses are the perfect counter to limit their damage output late-game. Their small deck sizes also compound the disruption effect.",
      },
    ],
    weakAgainst: [
      {
        description:
          "Poison teams - Poison builds are one of the least affected by deck disruption, as they get their poison stacks on early and can still deal good damage with only one or two attack cards in-hand.",
      },
      {
        description:
          "Sustain teams - This team lacks the burst damage capabilities to kill off opposition sustain team.",
      },
    ],
  },
  {
    id: "backdoor",
    name: "Backdoor Build",
    url: "https://www.axie.tech/team-builder?front=7-438-474-228-325-301-358-0-0-0-0-0-0-0&mid=5-402-424-228-323-341-292-0-0-0-0-0-0-0&back=3-402-401-228-440-341-241-0-0-0-0-0-0-0",
    strategy:
      "Use targeting attack abilities to give us control over which enemy axie to kill first - giving us a big advantage over our opponent for mid/late-game, and use Robin summons to boost multi-hit attack damage.",
    axies: {
      front: {
        parts: [
          "eyes-papi",
          "ears-curved-spine",
          "mouth-kotaro",
          "horn-strawberry-shortcake",
          "back-perch",
          "tail-snake-jar",
        ],
      },
      mid: {
        parts: [
          "eyes-robin",
          "ears-ear-breathing",
          "mouth-toothless-bite",
          "horn-kestrel",
          "back-perch",
          "tail-twin-tail",
        ],
      },
      back: {
        parts: [
          "eyes-robin",
          "ears-pink-cheek",
          "mouth-tiny-turtle",
          "horn-kestrel",
          "back-perch",
          "tail-shrimp",
        ],
      },
    },
    rationale: [
      "Toothless Bite, Shrimp, (and to some extent, Kotaro and Ear Breathing) provide opportunities to target the opponent's backliner early-game, and to try and make the backliner the enemy axie with the least health.",
      "Once the enemy backliner has the least health, Perch can be used to inflict big damage.",
      "Robin summons boost damage output, particularly for Perch and Twin Tail, which are multi-hit abilities.",
      "Frontliner has some sustain abilities to buy our team more time to get the backdoor attacks off in poor card draw situations at the early game.",
    ],
    strongAgainst: [
      {
        description:
          "Teams with a frontliner sustaining defender/tank, as we can play around it.",
      },
      {
        description:
          "Teams that take a long time to ramp up, such as DMG Ramp and Rage teams. We can often strategically eliminate the biggest threat before they have had a chance to ramp up.",
      },
    ],
    weakAgainst: [
      {
        description:
          "Highly balanced teams, and those playing without a frontline defender - where taking out the backliner gives us less of an advantage at mid-game.",
      },
      {
        description:
          "Sustain/Summon teams - if the opponent can Summon, Taunt and Heal to prevent their backliner being burned early game, our strategy falls down.",
      },
    ],
  },
];
