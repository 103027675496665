import React from "react";
import {
  Box,
  Stack,
  Typography,
  Tooltip,
  Container,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/system";
import Section from "../Layout/Section";
import Title from "../Typography/Title";
import { connect } from "react-redux";
import Text from "../Typography/Text";
import CardTemplate from "../GameTemplates/CardTemplate";
import RuneTemplate from "../GameTemplates/RuneTemplate";
import CharmTemplate from "../GameTemplates/CharmTemplate";
import {
  CARD_EXPLORER_URL,
  CHARM_EXPLORER_URL,
  CURRENT_SEASON,
  RUNE_EXPLORER_URL,
} from "config";
import { FaPlay } from "react-icons/fa";
import { useMediaQuery } from "@mui/material";

const Diagram = ({ type, cards, runes, charms }) => {
  const theme = useTheme();

  const cardFeatures = [
    { n: 1, title: "Card Name", left: "72%", top: "57%" },
    { n: 2, title: "Description", left: "92%", top: "80%" },
    { n: 3, title: "Tags", left: "66%", top: "66%" },
    { n: 4, title: "Body Part", left: "13%", top: "53%" },
    {
      n: 5,
      title: "Card Stats",
      description: "Damage, Shield and/or Heal",
      left: "50%",
      top: "6%",
    },
    { n: 6, title: "Energy Cost", left: "20%", top: "14%" },
    { n: 7, title: "Class", left: "42%", top: "92%" },
  ];

  const runeFeatures = [
    { n: 1, title: "Rune Name", left: "10%", top: "38%" },
    { n: 2, title: "Description", left: "66%", top: "62%" },
    { n: 3, title: "Class", left: "27%", top: "3%" },
  ];

  const charmFeatures = [
    { n: 1, title: "Charm Name", left: "80%", top: "46%" },
    { n: 2, title: "Description", left: "66%", top: "73%" },
    { n: 3, title: "Class", left: "27%", top: "3%" },
    { n: 4, title: "Potential Cost", left: "12%", top: "32%" },
  ];

  const diagramMapping = {
    card: {
      title: "Anatomy of a Card",
      description: "Each Axie Card is made up of the following components:",
      features: cardFeatures,
      diagram: <Card features={cardFeatures} cards={cards} />,
      button: (
        <Button
          href={CARD_EXPLORER_URL}
          target="_blank"
          variant="contained"
          size="small"
          endIcon={<FaPlay />}
          sx={{ alignSelf: "flex-start" }}
        >
          Explore Cards
        </Button>
      ),
    },
    rune: {
      title: "Anatomy of a Rune",
      description: "Each Rune is made up of the following components:",
      features: runeFeatures,
      diagram: <Rune features={runeFeatures} runes={runes} />,
      button: (
        <Button
          href={RUNE_EXPLORER_URL}
          target="_blank"
          variant="contained"
          size="small"
          endIcon={<FaPlay />}
          sx={{ alignSelf: "flex-start" }}
        >
          Explore Runes
        </Button>
      ),
    },
    charm: {
      title: "Anatomy of a Charm",
      description: "Each Charm is made up of the following components:",
      features: charmFeatures,
      diagram: <Charm features={charmFeatures} charms={charms} />,
      button: (
        <Button
          href={CHARM_EXPLORER_URL}
          target="_blank"
          variant="contained"
          size="small"
          endIcon={<FaPlay />}
          sx={{ alignSelf: "flex-start" }}
        >
          Explore Charms
        </Button>
      ),
    },
  };

  return (
    <Stack
      sx={{
        background: theme.palette.colors.wood.background,
        backgroundImage: "url('/images/banners/workshop-dark.jpg')",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        py: 16,
        zIndex: 10,
      }}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={{ xs: 6, sm: 6, md: 8 }}
          sx={{
            [theme.breakpoints.down("sm")]: {
              flexWrap: "wrap",
            },
          }}
        >
          <Stack gap={4}>
            <Section style={{ maxWidth: 400 }}>
              <Title
                color={theme.palette.colors.wood.text}
                style={{ textShadow: `2px 2px ${theme.palette.colors.black}` }}
              >
                {diagramMapping[type].title}
              </Title>
              <Text color="text.white" fontWeight={500}>
                {diagramMapping[type].description}
              </Text>
              <FeaturesList features={diagramMapping[type].features} />
            </Section>
            {diagramMapping[type].button}
          </Stack>
          {diagramMapping[type].diagram}
        </Stack>
      </Container>
    </Stack>
  );
};

const Card = ({ cards, features }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const card =
    cards != null && cards.find((c) => c.partId === "mouth-herbivore");

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
        transform: "rotate(4deg) translateX(-24px)",
        [theme.breakpoints.down("sm")]: {
          transform: "rotate(4deg)",
          mt: 2,
        },
      }}
    >
      {card != null && <CardTemplate card={card} width={small ? 300 : 340} />}
      <Labels features={features} />
    </Box>
  );
};

const Rune = ({ runes, features }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const rune = runes
    ? runes.find(
        (c) => c.name === "Leftover Potion" && c.season === CURRENT_SEASON
      )
    : undefined;

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
        transform: "rotate(4deg)",
        [theme.breakpoints.down("sm")]: {
          transform: "rotate(4deg)",
          mt: 2,
        },
      }}
    >
      {rune != null && <RuneTemplate rune={rune} width={small ? 300 : 340} />}
      <Labels features={features} />
    </Box>
  );
};

const Charm = ({ charms, features }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const charm = charms
    ? charms.find(
        (c) => c.name === "Charm of Focus II" && c.season === CURRENT_SEASON
      )
    : undefined;

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
        transform: "rotate(4deg)",
        [theme.breakpoints.down("sm")]: {
          transform: "rotate(4deg)",
          mt: 2,
        },
      }}
    >
      {charm != null && (
        <CharmTemplate charm={charm} width={small ? 300 : 340} />
      )}
      <Labels features={features} />
    </Box>
  );
};

const Labels = ({ features }) =>
  features.map((feature, i) => (
    <Label
      key={`LABEL_${i}`}
      n={feature.n}
      title={feature.title}
      left={feature.left}
      top={feature.top}
    />
  ));

const FeaturesList = ({ features }) => (
  <Stack gap={2}>
    {features.map((feature, i) => (
      <Feature
        n={feature.n}
        title={feature.title}
        description={feature.description}
        key={`FEATURE_${i}`}
      />
    ))}
  </Stack>
);

const Feature = ({ n, title, description }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" gap={1.5}>
      <Typography
        variant="span"
        fontSize={32}
        fontFamily="'Changa One', 'Poppins', sans serif"
        color={theme.palette.colors.secondary}
        sx={{ position: "relative", bottom: 1 }}
      >
        {n}
      </Typography>
      <Stack gap={0.5}>
        <Typography
          variant="span"
          fontSize={20}
          color="#fff"
          fontWeight={600}
          sx={{ position: "relative", bottom: 1 }}
        >
          {title}
        </Typography>
        {description != null && (
          <Typography
            variant="span"
            fontSize={12}
            color="#fff"
            sx={{ position: "relative", bottom: 1 }}
          >
            {description}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const Label = ({ n, title, left, top }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: theme.palette.colors.white,
            color: theme.palette.colors.black,
            fontWeight: "bold",
            fontSize: 14,
            boxShadow: "0px 0px 4px rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "absolute",
          background: theme.palette.colors.secondary,
          borderRadius: "50%",
          fontSize: 22,
          width: 30,
          height: 30,
          cursor: "pointer",
          transition: "0.2s transform ease",
          left,
          top,
          boxShadow: "0px 0px 8px rgba(0,0,0,0.8)",
          zIndex: 6,
          "&:hover": {
            transform: "scale(1.2)",
          },
        }}
      >
        <Typography
          variant="span"
          fontSize={22}
          fontFamily="'Changa One', 'Poppins', sans serif"
          color="#3A3A3A"
          sx={{ position: "relative", bottom: 1 }}
        >
          {n}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  cards: state.data.cards,
  runes: state.data.runes,
  charms: state.data.charms,
});

export default connect(mapStateToProps, mapDispatchToProps)(Diagram);
