import { Stack, Grid } from "@mui/material";
import React from "react";
import StarterAxies from "../StarterAxies";
import PersonalAxies from "../PersonalAxies";
import AxiesHeader from "./AxiesHeader";

const Axies = ({ title }) => {
  return (
    <Stack gap={6}>
      <AxiesHeader title={title} />

      <Grid
        container
        spacing={{ xs: 8, sm: 8, md: 4 }}
        sx={{
          mb: 10,
        }}
      >
        <StarterAxies />
        <PersonalAxies />
      </Grid>
    </Stack>
  );
};

export default Axies;
