import React from "react";
import Label from "./Label";
import CardTemplate from "components/GameTemplates/CardTemplate";
import { Stack, useTheme } from "@mui/material";
import CustomLoader from "components/CustomLoader/CustomLoader";
import CardSummary from "./CardSummary";

const Cards = ({ cards, cardData, fetchingCards, cardStats, cardTags }) => {
  const theme = useTheme();

  return (
    <Stack gap={2} alignItems="center">
      <Label>Cards</Label>

      {fetchingCards ? (
        <CustomLoader color={theme.palette.colors.primary} />
      ) : (
        <Stack gap={8}>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            justifyContent="center"
            flexWrap="wrap"
          >
            <CardSummary data={cardStats} title="Stats" />
            <CardSummary data={cardTags} title="Tags" addCardsToLabel={true} />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="center"
            sx={{
              positon: "relative",
              zIndex: 1,
              [theme.breakpoints.down("lg")]: {
                flexWrap: "wrap",
                gap: 4,
                px: 4,
              },
            }}
          >
            {cards.map((card, i) => {
              const axieCard =
                cardData != null && cardData.find((c) => c.partId === card);
              if (axieCard) {
                return (
                  <CardTemplate
                    card={axieCard}
                    key={`CARD_${i}`}
                    width={180}
                    style={{
                      transition: "0.2s transform ease",
                      "&:hover": { transform: "scale(1.05)" },
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Cards;
