import { Stack } from "@mui/material";
import React from "react";

const Section = ({ children, info, alignItems, style, gap = 3 }) => {
  return (
    <Stack gap={gap} alignItems={alignItems} sx={style}>
      <Stack gap={2}>{children}</Stack>
      {info != null && info}
    </Stack>
  );
};

export default Section;
