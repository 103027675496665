import React from "react";
import Info from "../../../components/Info/Info";
import Text from "../../../components/Typography/Text";
import Section from "../../../components/Layout/Section";
import Title from "../../../components/Typography/Title";
import { Grid, Stack, Button } from "@mui/material";
import Image from "../../../components/Image/Image";
import MarketplaceButton from "../../../components/MarketplaceButton/MarketplaceButton";
import { TEAM_BUILDER_URL } from "../../../config";
import { FaPlay } from "react-icons/fa";
import CustomLink from "../../../components/CustomLink/CustomLink";

const Intro = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={7}>
        <Section
          info={
            <Info>
              Remember, even the strongest Axie needs teammates! Successful
              players choose Axies that synergise well with each other.
            </Info>
          }
        >
          <Title>Building Your First Team</Title>
          <Text large={true}>
            There are an almost infinite number of combinations and strategies
            available in Axie Infinity: Origins! It's going to take millions of
            battles across the player-base for us to see if any true meta
            emerges, but here are 6 example team builds to get you started, and
            an idea of the strengths and weaknesses between them.
          </Text>
          <Stack
            direction="row"
            gap={4}
            alignItems="center"
            flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
          >
            <Text large={true}>
              Already have an idea for your first team? Plan your Cards, Charms
              and Runes using the
              <CustomLink href={TEAM_BUILDER_URL} endSpace={false}>
                Axie.Tech Team Builder
              </CustomLink>
              !
            </Text>
            <Button
              variant="contained"
              endIcon={<FaPlay />}
              size="small"
              href={TEAM_BUILDER_URL}
              target="_blank"
              sx={{ whiteSpace: "nowrap", minWidth: "max-content" }}
            >
              Team Builder
            </Button>
          </Stack>
        </Section>
      </Grid>

      <Grid item xs={12} sm={12} md={5}>
        <Stack gap={1} alignItems="center">
          <Image
            src="/images/pages/guides/builds/picking-axies.jpg"
            alt="Picking out an Axie"
            maxWidth={400}
          />
          <MarketplaceButton />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Intro;
