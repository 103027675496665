import React from "react";
import Text from "../../../components/Typography/Text";
import Title from "../../../components/Typography/Title";
import { Grid, Stack } from "@mui/material";
import Section from "../../../components/Layout/Section";
import CustomLink from "../../../components/CustomLink/CustomLink";
import List from "../../../components/List/List";
import ListItem from "../../../components/List/ListItem";
import { BREEDING_COSTS_URL, KATANA_URL } from "../../../config";

const ResourceRequirements = () => {
  return (
    <Grid container spacing={6} id="resource-requirements">
      <Grid item xs={12} sm={12} md={7}>
        <Section>
          <Title>Resource Requirements</Title>
          <Text large={true}>
            Breeding an Axie costs SLP and AXS. The amount of SLP depends on how
            many times the parent Axies have been bred. The AXS portion is
            variable and subject to adjustment based on economic factors.
          </Text>
          <Text large={true}>
            Check out the{" "}
            <CustomLink href={BREEDING_COSTS_URL}>
              Breeding Costs Table
            </CustomLink>{" "}
            for current SLP and AXS breeding requirements.
          </Text>
        </Section>
      </Grid>

      <Grid item xs={12} sm={12} md={5}>
        <Stack gap={1}>
          <Text large={true} fontWeight={800}>
            Acquiring SLP and AXS
          </Text>
          <List ml={0}>
            <ListItem>
              SLP can be earned in-game by playing
              <CustomLink to="/arena">Arena</CustomLink> or purchased on
              <CustomLink href={KATANA_URL} endSpace={false}>
                Katana
              </CustomLink>
              .
            </ListItem>
            <ListItem>
              AXS can be purchased on
              <CustomLink href={KATANA_URL} endSpace={false}>
                Katana
              </CustomLink>
              .
            </ListItem>
          </List>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ResourceRequirements;
