import { Typography } from "@mui/material";
import React from "react";

const Bold = ({ children }) => {
  return (
    <Typography fontSize="inherit" fontWeight={800} variant="span">
      {children}
    </Typography>
  );
};

export default Bold;
