import { AXIE_TECH_API } from "../config";

const cardsEndpoint = `${AXIE_TECH_API}/cards/v3`;

export const requestCards = async () => {
  const response = await fetch(cardsEndpoint, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};
