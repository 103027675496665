import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import Card from "../Card/Card";
import Title from "../Typography/Title";

const FAQ = ({ questions }) => {
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: "url('/images/accents/pattern-decorative-light.png')",
          backgroundPosition: "center center",
          backgroundRepeat: "repeat-x",
          backgroundSize: "contain",
          height: 100,
          width: "100%",
          mb: -4,
        }}
      />
      <Stack
        gap={2}
        alignItems="center"
        sx={{ maxWidth: 700, m: "0 auto", px: 2 }}
      >
        <Stack
          alignItems="center"
          sx={{
            backgroundImage: "url('/images/accents/blue-banner.jpg')",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            p: 4,
            borderRadius: 4,
            width: "100%",
          }}
        >
          <Title color="text.white">FAQ</Title>
        </Stack>

        <Stack gap={4}>
          {questions &&
            questions.map((question, i) => (
              <Question question={question} key={`QUESTION_${i}`} />
            ))}
        </Stack>
      </Stack>
    </Box>
  );
};

const Question = ({ question }) => {
  const theme = useTheme();
  return (
    <Card
      style={{
        p: 1,
        [theme.breakpoints.down("sm")]: {
          p: 0.5,
        },
      }}
    >
      <Stack gap={1} sx={{ p: 4 }}>
        <Typography variant="h3">{question.question}</Typography>
        <Typography variant="body1">{question.answer}</Typography>
        {question.button != null && (
          <Box sx={{ alignSelf: "flex-start" }}>{question.button}</Box>
        )}
      </Stack>
    </Card>
  );
};

export default FAQ;
