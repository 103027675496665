import { Stack, Grid, Container } from "@mui/material";
import React from "react";
import Text from "../../components/Typography/Text";
import Image from "../../components/Image/Image";
import Title from "../../components/Typography/Title";
import ListItem from "../../components/List/ListItem";
import List from "../../components/List/List";
import { MARKETPLACE_URL } from "../../config";
import PageTitle from "../../components/Typography/PageTitle";
import SectionHeader from "../../components/Layout/SectionHeader";
import Section from "../../components/Layout/Section";
import CustomLink from "../../components/CustomLink/CustomLink";

const About = () => {
  return (
    <Container>
      <Stack gap={{ xs: 10, sm: 10, md: 16 }}>
        <SectionHeader>
          <PageTitle>Personal Axies</PageTitle>
          <Text large={true} textAlign="center">
            Personal Axies are your own unique digital collectibles and NFTs
            (Non-Fungible Tokens) that live on the blockchain.
          </Text>
        </SectionHeader>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={7} sx={{ position: "relative" }}>
            <Section>
              <Title>What are Personal Axies?</Title>
              <Text large={true}>
                Just like
                <CustomLink to="/starter-axies" endSpace={false}>
                  Starter Axies
                </CustomLink>
                , Personal Axies can be used to battle in
                <CustomLink to="/adventure">Adventure</CustomLink> and
                <CustomLink to="/arena" endSpace={false}>
                  Arena
                </CustomLink>
                .
              </Text>
              <Text large={true}>
                Personal Axies also have unique abilities including:
              </Text>
              <List>
                <ListItem title="Earning">
                  Personal Axies can earn special rewards, including crypto
                  tokens such as Smooth Love Potion (SLP)
                </ListItem>
                <ListItem title="Breeding">
                  Personal Axies can breed to make more Axies!
                </ListItem>
                <ListItem title="Trading">
                  Personal Axies can be bought and sold by players on the{" "}
                  <CustomLink href={MARKETPLACE_URL}>Marketplace</CustomLink>
                </ListItem>
              </List>
            </Section>
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <Image
              src="/images/pages/personal-axies/marketplace.jpg"
              alt="Axies on the Marketplace"
              borderRadius={3}
              boxShadow={true}
            />
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

export default About;
