import React, { useState, useEffect } from "react";
import styles from "./navigation.module.scss";
import { IoMenu, IoClose } from "react-icons/io5";
import { gameDropdownItems, axiesDropdownItems } from "./navigation.links";
import { IconButton, Stack, Container } from "@mui/material";
import { MobileNav, DesktopNav } from "./Menus";
import CustomLink from "../CustomLink/CustomLink";

const Navigation = ({ colorOverrides }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [axiesDropdown, setAxiesDropdown] = useState(false);
  const [gameDropdown, setGameDropdown] = useState(false);
  const [renderDesktop, setRenderDesktop] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 900) {
      setRenderDesktop(true);
    } else {
      setRenderDesktop(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        handleSetMobileMenuOpen(false);
        setRenderDesktop(true);
      } else {
        setRenderDesktop(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSetMobileMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  };

  const navigationLinks = {
    newPlayers: {
      to: "/new-players",
      text: "New Players",
      dropdownItems: [],
    },
    axies: {
      text: "Axies",
      dropdownItems: axiesDropdownItems,
      isOpen: axiesDropdown,
      setIsOpen: setAxiesDropdown,
    },
    game: {
      text: "Game",
      dropdownItems: gameDropdownItems,
      isOpen: gameDropdown,
      setIsOpen: setGameDropdown,
    },
    guides: {
      to: "/guides",
      text: "Guides",
      dropdownItems: [],
    },
    community: {
      to: "/community",
      text: "Community",
      dropdownItems: [],
    },
  };

  return (
    <div className={styles.navigation}>
      <Container fixed>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            py: 0.5,
          }}
        >
          <CustomLink style={{ img: { height: 40 } }} to="/">
            <img src="/images/logo.png" alt="Axie Tech Logo" className="logo" />
          </CustomLink>
          {!renderDesktop && (
            <MobileNav
              links={navigationLinks}
              setMobileMenuOpen={handleSetMobileMenuOpen}
              mobileMenuOpen={mobileMenuOpen}
            />
          )}
          {renderDesktop && (
            <DesktopNav
              links={navigationLinks}
              setMobileMenuOpen={handleSetMobileMenuOpen}
              mobileMenuOpen={mobileMenuOpen}
              colorOverrides={colorOverrides}
            />
          )}
          <IconButton
            className={styles.hamburger}
            onClick={() => handleSetMobileMenuOpen(!mobileMenuOpen)}
          >
            {mobileMenuOpen ? <IoClose /> : <IoMenu />}
          </IconButton>
        </Stack>
      </Container>
    </div>
  );
};

export default Navigation;
