export const commonClasses = [
  {
    class: "plant",
    image: "/images/class-icons/plant.png",
    alt: "Plant Class",
  },
  {
    class: "beast",
    image: "/images/class-icons/beast.png",
    alt: "Beast Class",
  },
  {
    class: "aquatic",
    image: "/images/class-icons/aquatic.png",
    alt: "Aquatic Class",
  },
];

export const rareClasses = [
  {
    class: "reptile",
    image: "/images/class-icons/reptile.png",
    alt: "Reptile Class",
  },
  { class: "bug", image: "/images/class-icons/bug.png", alt: "Bug Class" },

  { class: "bird", image: "/images/class-icons/bird.png", alt: "Bird Class" },
];

export const secretClasses = [
  { class: "mech", image: "/images/class-icons/mech.png", alt: "Mech Class" },
  { class: "dawn", image: "/images/class-icons/dawn.png", alt: "Dawn Class" },
  { class: "dusk", image: "/images/class-icons/dusk.png", alt: "Dusk Class" },
];
