import { Grid, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Info from "../../components/Info/Info";
import Section from "../../components/Layout/Section";
import Image from "../../components/Image/Image";
import CustomLink from "../../components/CustomLink/CustomLink";

const EquippingCharms = () => (
  <Container>
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={8}>
        <Section
          info={
            <Info>
              You can collect and store multiple copies of the same Charm in
              your Inventory.
            </Info>
          }
        >
          <Title>Equipping Charms</Title>
          <Text large={true}>
            Each of an axie’s 6 <CustomLink to="/cards">Cards</CustomLink> can
            be equipped with 1 charm, this charm can be neutral or of the same
            class as the card it is being equipped to.
          </Text>
          <Text large={true}>
            To equip a Charm, open the game and go to an Axie's profile page.
            Tap on the Cards button and the tap one of the empty slots to browse
            your Inventory and select your desired Charm.
          </Text>
        </Section>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Image
          src="/images/pages/charms/equipping-charms.jpg"
          alt="Equipping Charms in Card Slots"
          borderRadius={3}
          boxShadow={true}
        />
      </Grid>
    </Grid>
  </Container>
);

export default EquippingCharms;
