import React from "react";
import { Stack, Box, Container } from "@mui/material";
import TextBubble from "./TextBubble";
import Image from "../../../components/Image/Image";
import CustomLink from "../../../components/CustomLink/CustomLink";
import { useWindowDimensions } from "../../../helpers/window";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";

const HowToPlay = () => {
  return (
    <Stack>
      <Setup />
      <Gameplay />
      <Objective />
    </Stack>
  );
};

const Setup = () => {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const bubble1 = (
    <>
      Welcome to your first Axie battle! The goal is to use your Axies to defeat
      your opponent.
    </>
  );

  const bubble2 = (
    <>
      Each player starts with a Deck of 18 Cards. Every Turn, you draw 5
      <CustomLink to="/cards">Cards</CustomLink> into your hand.
    </>
  );

  return lgDown ? (
    <SetupSmall bubble1={bubble1} bubble2={bubble2} />
  ) : (
    <SetupLarge bubble1={bubble1} bubble2={bubble2} />
  );
};

const SetupSmall = ({ bubble1, bubble2 }) => (
  <Stack gap={2} sx={{ position: "relative", mt: -4 }}>
    <Image
      src="/images/pages/gameplay-guide/how-to-play.png"
      alt="Battle"
      maxWidth={450}
      style={{
        mx: "auto",
      }}
      id="how-to-play"
    />

    <Stack gap={1} alignItems="center" sx={{ mb: -15 }}>
      <TextBubble maxWidth={450}>{bubble1}</TextBubble>
      <TextBubble maxWidth={450}>{bubble2}</TextBubble>
    </Stack>

    <Image
      src="/images/pages/gameplay-guide/battle.jpg"
      alt="Battle"
      style={{
        mx: "auto",
      }}
    />
  </Stack>
);

const SetupLarge = ({ bubble1, bubble2 }) => {
  const { width } = useWindowDimensions();
  const imgWidth = width < 1600 ? width : 1600;

  return (
    <Box
      sx={{
        backgroundImage: "url('/images/pages/gameplay-guide/battle.jpg')",
        backgroundPosition: "top center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        height: imgWidth * 0.4624,
      }}
    >
      <Container fixed sx={{ position: "relative", height: "100%" }}>
        <Image
          src="/images/pages/gameplay-guide/how-to-play.png"
          alt="How to Play"
          maxWidth={450}
          style={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            zIndex: 2,
          }}
          id="how-to-play"
        />

        <TextBubble maxWidth={500} left="12%" top="13%">
          {bubble1}
        </TextBubble>

        <TextBubble maxWidth={450} left="64%" bottom="24%">
          {bubble2}
        </TextBubble>
      </Container>
    </Box>
  );
};

const Gameplay = () => (
  <Box
    sx={{
      backgroundImage: "url('/images/pages/gameplay-guide/combat.jpg')",
      backgroundPosition: "top center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: 600,
      mt: -1,
    }}
  >
    <Container fixed sx={{ position: "relative", height: "100%" }}>
      <TextBubble maxWidth={500} left="12%" top="13%">
        Cards cost Energy to play.
      </TextBubble>
      <TextBubble
        maxWidth={500}
        right="4%"
        bottom="20%"
        styleSmall={{ bottom: "18%", left: 16 }}
      >
        Once you've used up all your Energy or are done playing Cards, it's time
        to end your Turn.
      </TextBubble>
      <TextBubble
        maxWidth={500}
        right="24%"
        bottom="-10%"
        styleSmall={{ bottom: "-2%", left: 16 }}
      >
        Any unused Cards are discarded at the end of your Turn, unused energy is
        not carried over by default.
      </TextBubble>
    </Container>
  </Box>
);

const Objective = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.colors.wood.background,
        py: 8,
        borderBottomRightRadius: 24,
        borderBottomLeftRadius: 24,
      }}
    >
      <Image
        src="/images/accents/pattern-decorative.png"
        alt="Decorative pattern"
        style={{ mb: -10 }}
      />

      <Container sx={{ position: "relative" }}>
        <Image
          src="/images/pages/gameplay-guide/status-effects.png"
          alt="Axies with status effects"
          style={{ mb: -1 }}
          maxWidth={600}
        />

        <TextBubble
          maxWidth={480}
          right="0"
          top="10%"
          styleSmall={{ position: "static" }}
        >
          Floating icons above your Axies' heads indicate that they have active
          <CustomLink to="/status-effects" endSpace={false}>
            Status Effects
          </CustomLink>
          .
        </TextBubble>

        <Box
          sx={{
            mt: 4,
            position: "relative",
          }}
        >
          <TextBubble
            maxWidth={400}
            left="12%"
            top="5%"
            styleSmall={{ position: "static", my: 2 }}
          >
            The first player to reduce the 3 opposing Axies' health to zero,
            wins!
          </TextBubble>
          <Image
            src="/images/pages/gameplay-guide/victory.jpg"
            alt="Victory banner"
            maxWidth={800}
            borderRadius={3}
            boxShadow={true}
            style={{ ml: "auto" }}
          />

          <TextBubble
            maxWidth={500}
            bottom="-18%"
            left="56%"
            styleSmall={{
              position: "relative",
              bottom: 0,
              left: "inherit",
              ml: "auto",
              maxWidth: 340,
              mt: -1,
              zIndex: 2,
            }}
          >
            Don't forget to collect your rewards!
          </TextBubble>
        </Box>

        <TextBubble
          maxWidth={500}
          left="0"
          bottom="-27%"
          styleSmall={{ position: "static", mt: 8 }}
        >
          Phew, did you get all that? Don't worry. We're now going to review all
          of the Battle Mechanics in more detail.
        </TextBubble>
      </Container>
      <Image
        src="/images/accents/pattern-decorative.png"
        alt="Decorative pattern"
        style={{ mt: -10 }}
      />
    </Box>
  );
};

export default HowToPlay;
