import { Container, Grid, Stack } from "@mui/material";
import React from "react";
import SubTitle from "../../components/Typography/SubTitle";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Section from "../../components/Layout/Section";
import Image from "../../components/Image/Image";
import Info from "../../components/Info/Info";
import CustomLink from "../../components/CustomLink/CustomLink";
import List from "../../components/List/List";
import ListItem from "../../components/List/ListItem";
import { CURRENT_SEASON } from "config.js";

const Leaderboard = () => {
  return (
    <Container id="challenger-sphere">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={5}>
          <Section>
            <Title>Leaderboard</Title>
            <Text large={true}>
              Each season, the entire playerbase will be ranked on the
              leaderboard.
            </Text>
            <SubTitle>Ranking System</SubTitle>
            <List ml={0}>
              <ListItem title="Rank">
                A player's rank is based on the number of Victory Stars that
                they have collected from Ranked matches.
              </ListItem>
              <ListItem title="Ties">
                If two players have an equal number of Stars, then win rate will
                be used as a first tie-breaker, followed by the time required to
                reach the current Victory Star amount.
              </ListItem>
            </List>

            <Info>
              Fancy yourself a top player? Register for an
              <CustomLink to="/esports">Esports</CustomLink> event!
            </Info>
          </Section>
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          <Stack gap={6}>
            <Image
              src="/images/pages/arena/rewards/rewards.jpg"
              alt={`${CURRENT_SEASON} Leaderboard Rewards`}
              borderRadius="12px"
              boxShadow={true}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Leaderboard;
