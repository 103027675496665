import React from "react";
import { Stack, Grid } from "@mui/material";
import Label from "./Label";
import Text from "components/Typography/Text";

const Story = ({ axie }) => (
  <Grid
    item
    xs={12}
    sm={12}
    md={12}
    lg={4}
    order={{ xs: 2, sm: 2, md: 2, lg: 2 }}
  >
    <Stack gap={1}>
      <Label>Story</Label>
      <Stack gap={1.5} sx={{ maxHeight: 500, overflowY: "auto" }}>
        {axie.story.map((s, i) => (
          <Text key={`STORY_${i}`} fontWeight={500}>
            {s}
          </Text>
        ))}
      </Stack>
    </Stack>
  </Grid>
);

export default Story;
