import React from "react";
import CallToAction from "../../../components/CallToAction/CallToAction";
import Page from "../../../components/Page/Page";
import PageSEO from "../../../components/SEO/PageSEO";
import Intro from "./Intro";
import ListOfTeams from "./ListOfTeams";
import { teams } from "./teams";

const Builds = () => {
  return (
    <>
      <PageSEO
        title="Axie Builds Guide | Axie.Tech"
        description="With hundreds of different Axie abilities to choose from, there are an almost infinite number of combinations and strategies available in Axie Infinity: Origins."
      />
      <Page
        headerImg="/images/banners/axies.jpg"
        headerHeight={360}
        title="Axie Builds"
        headerText="A beginner's guide to building Axie teams."
      >
        <Intro />
        <ListOfTeams teams={teams} />
      </Page>
      <CallToAction
        title="Ready to build your team?"
        description="Head over to the Marketplace and start picking out your Axies!"
        marketplaceButton={true}
        axie="buba"
      />
    </>
  );
};

export default Builds;
