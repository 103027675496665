import { Link } from "@mui/material";
import React from "react";
import Title from "components/Typography/Title";
import Text from "components/Typography/Text";
import { Link as RouterLink } from "react-router-dom";
import SectionHeader from "components/Layout/SectionHeader";

const AxiesHeader = ({ title = "Axies" }) => {
  return (
    <SectionHeader>
      <Title>{title}</Title>
      <Text large={true} textAlign="center">
        In Axie Infinity: Origins, you can collect two types of Axies:{" "}
        <Link to="/starter-axies" component={RouterLink}>
          Starter Axies
        </Link>{" "}
        and{" "}
        <Link to="/personal-axies" component={RouterLink}>
          Personal Axies
        </Link>
        .
      </Text>
    </SectionHeader>
  );
};

export default AxiesHeader;
