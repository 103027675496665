import React from "react";
import Text from "../../components/Typography/Text";
import { AXIE_INFINITY_DOWNLOAD } from "../../config";
import CustomLink from "../../components/CustomLink/CustomLink";
import PostcardSection from "../../components/Layout/PostcardSection";

const Welcome = () => {
  return (
    <PostcardSection
      title="Welcome, New Players"
      img="/images/banners/axie-infinity-origin.jpg"
      buttons={[
        { text: "Axies", to: "/new-players/#axies" },
        { text: "How to Play", to: "/new-players/#how-to-play" },
      ]}
      info={
        <>
          Axie Infinity: Origins is available for desktop and Android mobile
          devices. Click here to
          <CustomLink href={AXIE_INFINITY_DOWNLOAD} endSpace={false}>
            visit the downloads page
          </CustomLink>
          .
          <br /> The iOS version is coming soon!
        </>
      }
    >
      <Text large={true}>An exciting adventure awaits you!</Text>
      <Text>
        At its heart, Axie Infinity is a game about collecting and battling
        adorable creatures called Axies. But Axie Infinity is more than just a
        game. It is a universe of experiences with an active player-owned
        economy and a close-knit community.
      </Text>

      <Text>
        This New Player Guide contains all of the basic info that you will need
        to get started as a new player. We're so glad you're here.
      </Text>
    </PostcardSection>
  );
};

export default Welcome;
