import { Grid } from "@mui/material";
import React from "react";
import Page from "../../components/Page/Page";
import Image from "../../components/Image/Image";
import PageSEO from "../../components/SEO/PageSEO";

const Guides = () => {
  return (
    <>
      <PageSEO
        title="Guides | Axie.Tech"
        description="Everything from the basics to advanced strategies. Take your Axie gameplay to the next level!"
      />
      <Page
        headerImg="/images/banners/map.jpg"
        title="Guides"
        headerText="Everything from the basics to advanced strategies. Take your Axie gameplay to the next level!"
        headerHeight={320}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} md={4}>
            <Image
              src="/images/pages/guides/gameplay-guide.jpg"
              alt="Gameplay Guide"
              to="/guides/gameplay"
              scale={1.1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Image
              src="/images/pages/guides/builds-guide.jpg"
              alt="Builds Guide"
              to="/guides/builds"
              scale={1.1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Image
              src="/images/pages/guides/collectors-guide.jpg"
              alt="Collector's Guide"
              to="/guides/collectors"
              scale={1.1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Image
              src="/images/pages/guides/breeding-guide.jpg"
              alt="Breeding Guide"
              to="/guides/breeding"
              scale={1.1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Image
              src="/images/pages/guides/creators-guide.jpg"
              alt="Creator's Guide"
              to="/guides/creators"
              scale={1.1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Image
              src="/images/pages/guides/scatter-guide.jpg"
              alt="Scatter Guide"
              to="/guides/scatter"
              scale={1.1}
            />
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default Guides;
