import React from "react";
import { Stack } from "@mui/material";

const NamePlate = ({ children }) => (
  <Stack
    sx={{
      background: "#fff",
      borderRadius: "8px",
      p: "4px 16px",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #936E59",
      boxShadow: "0px 5.33078px 0px rgba(147, 110, 89, 0.25)",
    }}
  >
    {children}
  </Stack>
);

export default NamePlate;
