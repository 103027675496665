import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import SubTitle from "../../../components/Typography/SubTitle";
import Text from "../../../components/Typography/Text";
import Image from "../../../components/Image/Image";
import { useTheme } from "@mui/system";
import List from "../../../components/List/List";
import ListItem from "../../../components/List/ListItem";
import { FaStar } from "react-icons/fa";
import Bold from "../../../components/Typography/Bold";

const VictoryStars = () => {
  return (
    <Grid container spacing={8}>
      <Grid item xs={12} sm={12} md={8}>
        <Stack gap={3}>
          <Stack gap={1}>
            <SubTitle>Victory Stars</SubTitle>
            <Text large={true}>
              To climb the ranks, you must collect Victory Stars by winning
              battles in Ranked Mode:
            </Text>
          </Stack>

          <List>
            <ListItem>
              The number of Victory Stars you need to climb to the next Rank
              depends on your current Rank and Tier. For example, let's say you
              are in Egg IV (4th Tier in Rank Egg). Since this Tier requires 40
              Victory Stars, you must collect 40 Victory Stars before you will
              be promoted to Egg III (3rd Tier in Rank Egg).
            </ListItem>

            <ListItem>
              In Ranks Egg, Chick and Hare, the Victory Star outcomes are:
            </ListItem>

            <Stack
              flexDirection="row"
              justifyContent="center"
              flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
              gap={{ xs: 2, sm: 2, md: 4 }}
              sx={{ py: 1 }}
            >
              <StarReward title="Win" stars="+20" />
              <StarReward title="Draw" stars="+10" />
              <StarReward title="Lose" stars="0" />
            </Stack>

            <ListItem>
              In Ranks Boar, Wolf, Bear, the Victory Star outcomes are:
            </ListItem>

            <Stack
              flexDirection="row"
              justifyContent="center"
              flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
              gap={{ xs: 2, sm: 2, md: 4 }}
              sx={{ py: 1 }}
            >
              <StarReward title="Win" stars="+20" />
              <StarReward title="Draw" stars="+0" />
              <StarReward title="Lose" stars="-10" />
            </Stack>
            <ListItem>
              In Tiger and Dragon, the Victory Star outcomes are:
            </ListItem>

            <Stack
              flexDirection="row"
              justifyContent="center"
              flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
              gap={{ xs: 2, sm: 2, md: 4 }}
              sx={{ py: 1 }}
            >
              <StarReward title="Win" stars="+10" />
              <StarReward title="Draw" stars="+0" />
              <StarReward title="Lose" stars="-10" />
            </Stack>

            <ListItem>
              In Rank Challenger, win and loss amounts are calculated by an ELO
              formula based on the difference in Victory Stars between the two
              players.
            </ListItem>

            <ListItem>
              Excess Victory Stars acquired at the current Rank are counted
              towards the next Rank.
            </ListItem>

            <ListItem>
              A player is demoted 1 Tier if they lose their next match after
              reaching 0 Victory Stars. For example: if an Egg III player with 0
              Victory Stars loses their next battle, then they will be demoted
              to Egg IV with 30 Victory Stars.
            </ListItem>

            <ListItem>
              At the start of a new Season, a player's Rank will be reset to Egg
              IV and their Victory Stars will be reduced to 0.
            </ListItem>
          </List>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Stack gap={2}>
          <Image
            src="/images/pages/arena/stars.jpg"
            alt="Victory Stars"
            borderRadius={3}
          />
          <Stack gap={1}>
            <SubTitle>Star Decay</SubTitle>
            <Text large={true}>
              A decay system prevents trainers from reaching Boar rank and above
              and then not playing.
              <br></br>
              Trainers ranked Boar and above who do not play at least 2 ranked
              game per day, will lose a scaling amount of ELO until they fall to
              lower rank.
              <br></br>
              Trainers ranked Challenger will need to play at least 5 ranked
              games per day to avoid star decay.
            </Text>
          </Stack>

          <Image
            src="/images/pages/arena/stars.jpg"
            alt="Victory Stars"
            borderRadius={3}
          />
          <Stack gap={1}>
            <SubTitle>Bonus Stars!</SubTitle>
            <Text large={true}>
              Bonus Victory Stars can be acquired in 2 ways:
            </Text>
          </Stack>

          <Text large={true}>
            <Star />
            <Bold>
              By reaching a winning streak of 3 or more victories.
            </Bold>{" "}
            You receive +10 Victory Star per winning streak win. This bonus only
            applies to Rank Bear and below.
          </Text>

          <Text large={true}>
            <Star />
            <Bold>By finishing a previous season at a higher rank.</Bold> Based
            on your Rank in the previous season, you will receive a bonus for
            each win. This bonus is reduced by 1 each time you're promoted to a
            new Rank.
          </Text>

          <Image
            src="/images/pages/arena/stars.jpg"
            alt="Victory Stars"
            borderRadius={3}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

const Star = () => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        display: "inline-flex",
        width: 30,
        height: 30,
        p: 1,
        background: theme.palette.colors.primary,
        borderRadius: "50%",
        color: "#fff",
        mr: 1,
      }}
    >
      <FaStar />
    </Stack>
  );
};

const StarReward = ({ title, stars }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{
        background: theme.palette.colors.wood.background,
        p: "8px 12px",
        borderRadius: 3,
        transition: "0.2s transform ease",
        "&:hover": {
          transform: "scale(1.1)",
          cursor: "pointer",
        },
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          justifyContent: "center",
        },
      }}
    >
      <Typography fontSize={26} fontWeight={500} variant="h4" color="#fff">
        {title}:
      </Typography>
      <Typography
        fontSize={32}
        fontWeight={500}
        variant="h4"
        color={theme.palette.colors.star}
      >
        {stars}
      </Typography>
      <Image src={`/images/pages/arena/star.png`} alt={title} maxWidth={36} />
    </Stack>
  );
};

export default VictoryStars;
