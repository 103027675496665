import { Box } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/system";

const Paper = ({ children, vertical = true, style }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.colors.paper.medium,
        p: 2,
        borderRadius: "30px",
        ...style,
      }}
    >
      <Box
        sx={{
          background: theme.palette.colors.paper.light,
          borderRadius: "20px",
          position: "relative",
          [theme.breakpoints.down("sm")]: { borderRadius: "28px" },
        }}
      >
        {vertical && (
          <>
            <Box
              sx={{
                backgroundImage:
                  "url('/images/accents/pattern-vertical-left.png')",
                height: "100%",
                width: 57,
                position: "absolute",
                left: 30,
                backgroundRepeat: "repeat-y",
                opacity: 0.2,
              }}
            />
            <Box
              sx={{
                backgroundImage:
                  "url('/images/accents/pattern-vertical-right.png')",
                height: "100%",
                width: 57,
                position: "absolute",
                right: 30,
                opacity: 0.2,
              }}
            />
          </>
        )}
        {!vertical && (
          <Box
            sx={{
              backgroundPosition: "center center",
              backgroundSize: "contain",
              position: "absolute",
              top: 10,
              backgroundImage: "url('/images/accents/pattern.png')",
              height: 20,
              width: "100%",
              backgroundRepeat: "repeat-x",
              opacity: 0.2,
            }}
          />
        )}
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            px: 2,
            py: 4,
            [theme.breakpoints.down("md")]: {
              py: 2,
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Paper;
