import React from "react";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import * as icons from "../../icons/icons";

const CardSummary = ({ data, title, addCardsToLabel }) => {
  const theme = useTheme();
  const mapping = {
    cardEnergy: { icon: "energy", label: "energy" },
    cardDamage: { icon: "damage", label: "damage" },
    cardShield: { icon: "shield", label: "shield" },
    cardHeal: { icon: "heal", label: "heal" },
    skill: { icon: "tagSkill", label: "skill" },
    ethereal: { icon: "tagEthereal", label: "ethereal" },
    banish: { icon: "tagBanish", label: "banish" },
    innate: { icon: "tagInnate", label: "innate" },
    secret: { icon: "tagSecret", label: "secret" },
    power: { icon: "tagPower", label: "power" },
    attack: { icon: "tagDamage", label: "damage" },
    retain_effect: { icon: "tagRetain", label: "retain" },
  };

  return data != null ? (
    <Wrapper title={title}>
      {Object.keys(data).map((key, i) => (
        <Tooltip
          title={`${data[key]} ${mapping[key].label} ${
            addCardsToLabel ? (data[key] === 1 ? "card" : "cards") : ""
          }`}
          key={`STAT_${i}`}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            sx={{ cursor: "pointer" }}
          >
            {key in mapping && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: 20,
                  width: 20,
                  "& svg": { height: 18, width: "auto" },
                }}
              >
                {icons[mapping[key].icon](theme.palette.colors.cards[key])}
              </Stack>
            )}
            <Typography
              variant="span"
              fontSize={20}
              fontWeight={600}
              color={theme.palette.colors.cards[key]}
            >
              {data[key]}
            </Typography>
          </Stack>
        </Tooltip>
      ))}
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = ({ children, title }) => (
  <Stack
    direction="row"
    alignItems="center"
    gap={1}
    justifyContent="center"
    sx={{
      background: "#fff",
      borderRadius: "24px",
      p: "4px 8px",
      boxShadow: "0 4px rgba(0,0,0,0.08), 0 0 10px rgba(240,220,192,0.09)",
    }}
  >
    <Typography
      fontWeight={700}
      color="text.brown"
      fontSize={13}
      sx={{ textTransform: "uppercase" }}
    >
      {title}
    </Typography>
    {children}
  </Stack>
);

export default CardSummary;
