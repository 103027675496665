import Text from "components/Typography/Text";
import { Box, useTheme } from "@mui/material";

const BubbleText = ({ children, maxWidth }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.colors.primary,
        p: 2,
        borderRadius: "16px",
        boxShadow: "0 4px rgba(0,0,0,0.08)",
        display: "inline-block",
        maxWidth,
      }}
    >
      <Text color="text.white" fontWeight={600}>
        {children}
      </Text>
    </Box>
  );
};

export default BubbleText;
