import { Grid, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Section from "../../components/Layout/Section";
import Image from "../../components/Image/Image";
import { useTheme } from "@mui/system";

const NFTvsNonNFT = () => {
  const theme = useTheme();
  return (
    <Container>
      <Grid container spacing={7}>
        <Grid item xs={12} sm={12} md={8}>
          <Section>
            <Title>Are Charms NFTs?</Title>
            <Text large={true}>
              Charms come in two varieties: non-NFT and NFT. Non-NFT Charms are
              in-game items only – they cannot be traded on the Marketplace.
              Most non-NFT Charms are Neutral Class.
            </Text>
            <Text large={true}>
              On the other hand, NFT Charms can be traded on the Marketplace.
              NFT Charms also have specific Classes and require additional
              crafting materials to mint.
            </Text>
          </Section>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Image
            src="/images/pages/charms/nft-charms.jpg"
            alt="NFT Charms"
            maxWidth={420}
            style={{
              justifyContent: "center",
              height: "100%",
              m: "0 auto",
              [theme.breakpoints.down("md")]: {
                maxWidth: 300,
              },
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NFTvsNonNFT;
