import { Container, Stack, Box } from "@mui/material";
import React from "react";
import SectionHeader from "../../components/Layout/SectionHeader";
import PageTitle from "../../components/Typography/PageTitle";
import Text from "../../components/Typography/Text";
import CustomLink from "../../components/CustomLink/CustomLink";
import { useTheme } from "@mui/system";
import { connect } from "react-redux";
import CardTemplate from "../../components/GameTemplates/CardTemplate";
import { getCardRotation, getCardTranslation } from "../../helpers/card";
import { useMediaQuery } from "@mui/material";

const Intro = ({ cards }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const ids = [
    "eyes-blossom",
    "ears-clover",
    "back-bidens",
    "mouth-zigzag",
    "horn-cactus",
    "tail-hot-butt",
  ];
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          opacity: 0.3,
          backgroundImage: "url('/images/banners/background-forest-1.jpg')",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          width: "100%",
          height: "80vh",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          background:
            "linear-gradient(180deg, rgba(255, 252, 249, 0) 80%, #FFFCF9 100%)",
          width: "100%",
          height: "80vh",
          zIndex: 2,
        }}
      />
      <Stack
        gap={20}
        sx={{
          pt: 20,
          position: "relative",
          zIndex: 2,
          [theme.breakpoints.down("md")]: {
            pt: 14,
          },
          [theme.breakpoints.down("sm")]: {
            pt: 10,
          },
        }}
      >
        <Container>
          <Stack gap={{ xs: 6, sm: 6, md: 10 }} sx={{ position: "relative" }}>
            <SectionHeader>
              <PageTitle>Cards</PageTitle>
              <Text
                large={true}
                textAlign="center"
                fontWeight={500}
                style={{ textShadow: "0px 0px 5px #fff" }}
              >
                Each Axie has 6 different Cards that determine its abilities in
                Battle.
              </Text>
            </SectionHeader>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={1}
              sx={{
                mt: -4,
                [theme.breakpoints.down("lg")]: {
                  flexWrap: "wrap",
                },
              }}
            >
              {ids.map((id, i) => {
                const card =
                  cards != null && cards.find((c) => c.partId === id);

                if (card) {
                  return (
                    <CardTemplate
                      card={card}
                      key={`CARD_${i}`}
                      width={small ? 100 : 160}
                      style={{
                        transition: "0.2s transform ease",
                        "&:hover": {
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          zIndex: 10,
                        },
                        transform: `rotate(${getCardRotation(
                          i,
                          ids.length
                        )}deg) translateY(${getCardTranslation(
                          i,
                          ids.length
                        )}px)`,
                      }}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Stack>

            <Stack sx={{ maxWidth: 700, m: "0 auto" }}>
              <Text
                large={true}
                textAlign="center"
                fontWeight={500}
                style={{ textShadow: "0px 0px 5px #fff" }}
              >
                When building your team, you should think about the strength of
                each Axie by itself as well as in combination with its
                teammates. Check out our
                <CustomLink to="/guides/builds">Builds Guide</CustomLink>
                for team-building ideas!
              </Text>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({ cards: state.data.cards });

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
