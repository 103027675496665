export const axiesDropdownItems = [
  {
    text: "What are Axies?",
    to: "/what-are-axies",
  },
  {
    text: "Starter Axies",
    to: "/starter-axies",
  },
  {
    text: "Personal Axies",
    to: "/personal-axies",
  },
];

export const gameDropdownItems = [
  {
    text: "Cards",
    to: "/cards",
  },
  {
    text: "Arena",
    to: "/arena",
  },
  {
    text: "Runes",
    to: "/runes",
  },
  {
    text: "Esports",
    to: "/esports",
  },
  {
    text: "Charms",
    to: "/charms",
  },
  {
    text: "Status Effects",
    to: "/status-effects",
  },
];
