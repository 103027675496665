import React from "react";
import CustomLink from "../../../../components/CustomLink/CustomLink";
import Section from "../../../../components/Layout/Section";
import SubTitle from "../../../../components/Typography/SubTitle";
import ListItem from "../../../../components/List/ListItem";
import List from "../../../../components/List/List";

const RevengeCards = () => {
  return (
    <Section>
      <SubTitle>Revenge Cards</SubTitle>

      <List ml={0}>
        <ListItem minimal={true}>
          When an axie dies, all of its{" "}
          <CustomLink to="/cards">cards</CustomLink>
          will disappear from your deck. These cards will then be replaced by 4
          revenge cards that will shuffle into your discard pile.
        </ListItem>
        <ListItem minimal={true}>
          Revenge cards are weaker than regular cards. They serve to punish a
          player for losing an axie early in the battle.
        </ListItem>

        <ListItem minimal={true}>
          Revenge cards have{" "}
          <CustomLink hashTo="/cards/#tags" endSpace={false}>
            Ethereal and Banish Tags
          </CustomLink>{" "}
          tags, this means they’ll be discarded to your banish pile at the end
          of the turn.
        </ListItem>
      </List>
    </Section>
  );
};

export default RevengeCards;
