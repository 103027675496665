import React from "react";
import { Container, Box, Stack } from "@mui/material";
import { useTheme } from "@mui/system";
import PageHeader from "../PageHeader/PageHeader";

const Page = ({
  children,
  headerImg,
  headerHeight = 340,
  headerText,
  title,
  gap = 24,
  container = true,
  style,
  hidePattern = false,
  contents,
}) => {
  const theme = useTheme();
  return (
    <Box>
      {headerImg != null && (
        <PageHeader
          img={headerImg}
          height={headerHeight}
          text={headerText}
          title={title}
          contents={contents}
        />
      )}
      {headerImg == null && !hidePattern && (
        <Box
          sx={{
            backgroundImage:
              "url('/images/accents/pattern-decorative-light.png')",
            backgroundPosition: "center center",
            backgroundRepeat: "repeat-x",
            backgroundSize: "contain",
            height: 100,
            width: "100%",
            [theme.breakpoints.down("sm")]: {
              height: 50,
              mb: -2,
            },
          }}
        />
      )}
      <WrappedContainer container={container}>
        <Stack
          gap={gap}
          sx={{
            pt: headerImg == null ? 10 : 18,
            pb: 16,
            position: "relative",
            zIndex: 2,
            [theme.breakpoints.down("md")]: {
              pt: 10,
              pb: 2,
              gap: 12,
            },
            [theme.breakpoints.down("sm")]: {
              pt: 8,
              gap: 10,
            },
            ...style,
          }}
        >
          {children}
        </Stack>
      </WrappedContainer>
    </Box>
  );
};

const WrappedContainer = ({ container, children }) =>
  container ? <Container fixed>{children}</Container> : <>{children}</>;

export default Page;
