import React from "react";
import { Link, Stack } from "@mui/material";
import SubTitle from "../../Typography/SubTitle";
import Text from "../../Typography/Text";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/system";

const TitledLinks = ({ title, links }) => {
  const theme = useTheme();

  return (
    <Stack
      gap={2}
      sx={{
        "& .MuiLink-root": {
          color: theme.palette.colors.typography.muted,
          fontWeight: 500,
          "&:hover": {
            color: theme.palette.colors.primary,
          },
        },
      }}
    >
      {title != null && (
        <SubTitle color="text.orange" fontSize={26}>
          {title}
        </SubTitle>
      )}
      {links.map((link, i) => (
        <Text key={`LINK_${i}`}>
          {link.to != null ? (
            <Link to={link.to} component={RouterLink} disabled={true}>
              {link.text}
            </Link>
          ) : (
            <Link href={link.href} target="_blank">
              {link.text}
            </Link>
          )}
        </Text>
      ))}
    </Stack>
  );
};

export default TitledLinks;
