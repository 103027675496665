import React from "react";
import Section from "../../../components/Layout/Section";
import { Stack, Grid } from "@mui/material";
import CenteredContent from "../../../components/Layout/CenteredContent";
import SubTitle from "../../../components/Typography/SubTitle";
import CustomLink from "../../../components/CustomLink/CustomLink";
import DecorativeBanner from "../../../components/DecorativeBanner/DecorativeBanner";
import List from "../../../components/List/List";
import ListItem from "../../../components/List/ListItem";
import BattleBasics from "./BattleBasics";
import EarlyRounds from "./Content/EarlyRounds";
import RevengeCards from "./Content/RevengeCards";
import Keep from "./Content/Keep";
import EnergyBurst from "./Content/EnergyBurst";
import BloodMoon from "./Content/BloodMoon";

const BattleMechanics = () => {
  return (
    <Stack gap={{ xs: 6, sm: 8, md: 10 }}>
      <DecorativeBanner
        bannerSlug="arena"
        titleSlug="gameplay-guide/battle-mechanics"
        title="Battle Mechanics"
        titleMaxWidth={580}
        id="basic-battle-mechanics"
      />
      <CenteredContent>
        <Stack gap={8}>
          <BattleBasics />
          <Grid id="battle-mechanics" container spacing={8}>
            <LeftColumn />
            <RightColumn />
          </Grid>
        </Stack>
      </CenteredContent>
    </Stack>
  );
};

const LeftColumn = () => (
  <Grid item xs={12} sm={12} md={6}>
    <Stack gap={4}>
      <Section>
        <SubTitle>The Deck</SubTitle>

        <List ml={0}>
          <ListItem minimal={true}>
            Each player has an 18 card deck, 6{" "}
            <CustomLink to="/cards">Cards</CustomLink> per axie, 1 card per axie
            part.
          </ListItem>
          <ListItem minimal={true}>
            On the top left of the screen is your draw pile. Click on it to see
            the cards left in your hand that you can draw in the next rounds.
          </ListItem>
          <ListItem minimal={true}>
            On the right side of the screen is your discard pile. At the
            beginning of each turn cards are drawn from your draw pile into your
            hand.
          </ListItem>
          <ListItem minimal={true}>
            When the turn ends, both played and unplayed, cards will move to
            your discard pile. You can see these cards by clicking on your
            discard pile.
          </ListItem>
          <ListItem minimal={true}>
            Once your Draw Pile is empty, your discard pile is shuffled back
            into your draw pile. These cards will then be drawn in a random
            order.
          </ListItem>
        </List>
      </Section>

      <RevengeCards />

      <Keep />

      <EnergyBurst />
    </Stack>
  </Grid>
);

const RightColumn = () => (
  <Grid item xs={12} sm={12} md={6}>
    <Stack gap={4}>
      <Section>
        <SubTitle>Early Rounds</SubTitle>

        <List ml={0}>
          <EarlyRounds />
        </List>
      </Section>

      <Section>
        <SubTitle>Status Effects</SubTitle>

        <List ml={0}>
          <ListItem minimal={true}>
            There are a variety of different Status Effects that can impact
            Axies during battle.
          </ListItem>
          <ListItem minimal={true}>
            <CustomLink hashTo="/status-effects/#buffs">Buffs</CustomLink> are
            positive status effects.
          </ListItem>
          <ListItem minimal={true}>
            <CustomLink hashTo="/status-effects/#debuffs">Debuffs</CustomLink>
            are negative status effects.
          </ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>Targeting</SubTitle>
        <List ml={0}>
          <ListItem minimal={true}>
            Assuming there are no other active game mechanics, such as Taunt or
            other targeting effects, attacks will target the frontmost opponent
            by default.
          </ListItem>
          <ListItem minimal={true}>
            Some Cards allow you to select the target - this can either be an
            ally to heal or an enemy to attack.
          </ListItem>
          <ListItem minimal={true}>
            Some Cards will attack random targets, which may or may not bypass
            the frontmost opponent. Other Cards will specifically target the
            second-frontmost Axie or the furthest Axie in certain circumstances.
            Subsequent attacks do not continue to target the Axie who was
            attacked with the initial Card.
          </ListItem>
          <ListItem minimal={true}>
            The Taunt effect will cause all incoming enemy attacks to target
            this Axie. This can be used both defensively (to protect others) or
            offensively (to focus attacks on a strategic target).
          </ListItem>
        </List>
      </Section>

      <BloodMoon />
    </Stack>
  </Grid>
);

export default BattleMechanics;
