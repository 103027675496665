import { Stack, Container } from "@mui/material";
import React from "react";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import RuneTemplate from "../../components/GameTemplates/RuneTemplate";
import { connect } from "react-redux";
import Section from "../../components/Layout/Section";
import Image from "../../components/Image/Image";
import { CURRENT_SEASON } from "config";
import EraInfo from "./EraInfo";

const RuneBasics = ({ runes }) => {
  const names = [
    "Leftover Potion",
    "Mini Legion",
    "Pure Instinct",
    "Shady Exchange",
    "Thick Shield",
    "Pure Power",
    "Pure Skill",
    "Tough Strike",
  ];

  return (
    <Container>
      <Stack gap={4} sx={{ position: "relative" }}>
        <Section>
          <Title>Rune Basics</Title>
          <Text large={true}>
            Runes are special wearable items that provide passive effects that
            automatically activate in battle. Runes come in 10 different
            classes, 1 for each Axie Class, plus a Neutral Class.
          </Text>
        </Section>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{
            xs: "center",
            sm: "center",
            md: "center",
            lg: "flex-start",
          }}
          gap={{ xs: 2, sm: 2, md: 0 }}
          flexWrap={{ xs: "wrap", sm: "wrap", md: "wrap", lg: "nowrap" }}
        >
          {runes != null &&
            names.map((name, i) => {
              const rune = runes.find(
                (c) => c.name === name && c.season === CURRENT_SEASON
              );

              if (rune) {
                return (
                  <RuneTemplate
                    rune={rune}
                    key={`RUNE_${i}`}
                    width={160}
                    style={{
                      mx: -1,
                      transition: "0.2s transform ease",
                      "&:hover": {
                        cursor: "pointer",
                        transform: "scale(1.3)",
                        zIndex: 10,
                      },
                      transform: `rotate(4deg)`,
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
        </Stack>
        <Text large={true}>Runes also come in 4 different rarities:</Text>

        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
          justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
        >
          <Image
            src="/images/pages/runes/common.png"
            alt="Common Rarity Rune"
            maxWidth={180}
            scale={1.1}
          />
          <Image
            src="/images/pages/runes/rare.png"
            alt="Rare Rarity Rune"
            maxWidth={180}
            scale={1.1}
          />
          <Image
            src="/images/pages/runes/epic.png"
            alt="Epic Rarity Rune"
            maxWidth={180}
            scale={1.1}
          />
          <Image
            src="/images/pages/runes/mystic.png"
            alt="Mystic Rarity Rune"
            maxWidth={180}
            scale={1.1}
          />
        </Stack>

        <EraInfo itemType="Runes" />
      </Stack>
    </Container>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  runes: state.data.runes,
});

export default connect(mapStateToProps, mapDispatchToProps)(RuneBasics);
