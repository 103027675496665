import React from "react";
import CardTemplate from "../../components/GameTemplates/CardTemplate";
import { Stack, Container } from "@mui/material";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import { connect } from "react-redux";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import { getCurseCards } from "../../store/data/dataSelectors";
import SectionHeader from "../../components/Layout/SectionHeader";
import { useTheme } from "@mui/system";
import NameAndCreated from "./NameAndCreated";
import CreatedWith from "./CreatedWith";

const Curses = ({ curseData }) => {
  const theme = useTheme();
  return (
    <Container>
      <Stack gap={4}>
        <SectionHeader>
          <Title newTag={true}>Curses</Title>
          <Text large={true} textAlign="center">
            Curse Cards are added to your Opponent's deck to cause negative
            effects over time.
          </Text>
        </SectionHeader>
        {curseData.curses == null ? (
          <></>
        ) : curseData.loading ? (
          <CustomLoader />
        ) : (
          <Stack gap={2}>
            {curseData.curses.map((curse, i) => (
              <Stack
                key={`CURSE_${i}`}
                gap={4}
                direction="row"
                alignItems="center"
                sx={{
                  background: "#fff",
                  borderRadius: 3,
                  p: 2,
                  boxShadow: "0px 0px 8px #f0dcc0a8",
                  [theme.breakpoints.down("lg")]: {
                    flexDirection: "column",
                  },
                }}
              >
                <Stack
                  gap={4}
                  direction="row"
                  alignItems="center"
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      gap: 4,
                      alignItems: "flex-start",
                    },
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                      alignItems: "center",
                    },
                  }}
                >
                  <NameAndCreated item={curse} />
                  <CardTemplate
                    width={240}
                    card={curse}
                    style={{
                      transition: "0.2s transform ease",
                      "&:hover": { transform: "scale(1.05)" },
                      mt: 1,
                    }}
                  />
                </Stack>

                <CreatedWith item={curse} itemType="curse" />
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  curseData: getCurseCards(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Curses);
