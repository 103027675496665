import { Stack, Typography } from "@mui/material";
import React from "react";
import SubTitle from "../../../components/Typography/SubTitle";
import Text from "../../../components/Typography/Text";
import Image from "../../../components/Image/Image";
import { useTheme } from "@mui/system";

const Stats = () => {
  return (
    <Stack gap={4}>
      <Stack gap={1}>
        <SubTitle>Stats</SubTitle>
        <Text large={true}>
          Ranked Mode introduces 2 new stats that impact your earning potential:
        </Text>
      </Stack>

      <Stack
        direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
        gap={{ xs: 4, sm: 4, md: 4, lg: 8 }}
      >
        <StatWrapper title="Stamina" slug="stamina">
          <Text large={true}>Each Ranked battle costs 1 Stamina.</Text>
          <Text large={true}>
            Your Stamina is determined by the total number of Axies you have in
            your account. The maximum amount of Stamina you can have is 30.
          </Text>
          {/* <Image
            src={`/images/pages/arena/stamina-formula.png`}
            alt="Stamina formula"
            style={{ my: 2 }}
          /> */}
          <Image
            src={`/images/pages/arena/new-stamina-formula.png`}
            alt="Stamina formula"
            style={{ my: 2 }}
          />
          <Text fontWeight={800}>
            For example, if you have 4 Starter Axies and 6 Personal Axies, then
            you have 16 Stamina.
          </Text>
          <Text large={true}>
            You can still battle in Ranked Mode when you run out of Stamina,
            however, you'll receive a lower percentage of rewards as with your
            full stamina.
          </Text>
          <Text large={true}>
            The percentage of rewards you can earn will depend on the value of
            your Ronin Spirit.
          </Text>
        </StatWrapper>

        <StatWrapper title="Ronin Spirit" slug="ronin-spirit">
          <Text large={true}>
            Ronin Spirit determines how much SLP and extra Moon Shards you can
            win from battles.
          </Text>
          <Text large={true}>
            Your Ronin spirit is based on how many personal axies you have on a
            team.
          </Text>
          <Image
            src={`/images/pages/arena/ronin-spirit-formula.png`}
            alt="Ronin Spirit formula"
            style={{ my: 2 }}
          />
          <Text fontWeight={800}>
            For example, if your team is comprised of 3 Personal Axies, then
            your Ronin Spirit's level is III.
          </Text>
          <Text large={true}>
            The higher your Ronin Spirit, the higher the percentage rewards you
            can potentially earn.
          </Text>
        </StatWrapper>
      </Stack>
    </Stack>
  );
};

const StatWrapper = ({ title, children, slug }) => {
  const theme = useTheme();

  return (
    <Stack
      gap={2}
      sx={{
        background: theme.palette.colors.paper.light,
        borderRadius: "24px",
        p: 3,
        flexBasis: "50%",
        transition: "0.2s transform ease",
        border: `4px solid transparent`,
        "&:hover": {
          transform: "scale(1.08)",
          border: `4px solid ${theme.palette.colors.primary}`,
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack
          sx={{
            backgroundImage: "url('/images/accents/red-banner.jpg')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            p: "4px 8px",
            borderRadius: "8px",
          }}
        >
          <Typography
            fontSize={26}
            fontWeight={500}
            variant="h4"
            color="#fff"
            sx={{ textShadow: "0 0 5px rgba(0,0,0,0.6)" }}
          >
            {title}
          </Typography>
        </Stack>

        <Image
          src={`/images/pages/arena/${slug}-icon.png`}
          alt={title}
          style={{ maxWidth: 60, ml: "auto", my: -1 }}
        />
      </Stack>

      <Stack gap={1}>{children}</Stack>
    </Stack>
  );
};

export default Stats;
