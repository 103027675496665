import React from "react";
import {
  Autocomplete,
  TextField,
  Chip,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";

const Search = ({
  options,
  inputValue,
  setInputValue,
  selectedValues,
  setSelectedValues,
}) => {
  const theme = useTheme();

  return (
    <Stack sx={{ mt: 2 }}>
      <Autocomplete
        value={selectedValues}
        onChange={(event, newValue) => {
          setSelectedValues(newValue);
          setInputValue("");
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        multiple
        id="search"
        sx={{
          width: "100%",
          "& .MuiAutocomplete-inputRoot, & .MuiAutocomplete-inputRoot:hover,  & .MuiAutocomplete-inputRoot:active, & .Mui-Focused, & .MuiAutocomplete-inputRoot:focus, & .MuiFilledInput-root.Mui-focused":
            {
              background: "#fff",
              boxShadow: "0px 0px 5px rgba(0,0,0,.2)",
            },
          "& .MuiFilledInput-root": {
            borderRadius: "8px",
            minWidth: 300,
            pb: "8px",
            color: theme.palette.colors.black,
            fontWeight: 500,
          },
          "& .MuiInputLabel-root": {},
          "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            minWidth: 60,
          },
        }}
        options={options}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
          return (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`/images/status-effects/${option.image}`}
                alt={`$${option.name} status effect`}
              />
              <Typography variant="span" fontWeight={500}>
                {option.name} ({option.type})
              </Typography>
            </Box>
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              color="primary"
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        ListboxProps={{
          sx: {
            "& .MuiAutocomplete-option[aria-selected='true'], & .MuiAutocomplete-option:hover, & .MuiAutocomplete-option[aria-selected='true']:hover, & .MuiAutocomplete-option[aria-selected='true'].Mui-focused":
              {
                background: theme.palette.colors.primary,
                "& span": { color: "#fff !important" },
              },
          },
        }}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            hiddenLabel
            placeholder="Search"
            variant="filled"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
          />
        )}
      />
    </Stack>
  );
};

export default Search;
