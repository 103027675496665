import { Container, Grid, Stack } from "@mui/material";
import React from "react";
import SubTitle from "../../components/Typography/SubTitle";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";
import Image from "../../components/Image/Image";
import Section from "../../components/Layout/Section";
import List from "../../components/List/List";
import ListItem from "../../components/List/ListItem";
import Info from "../../components/Info/Info";

const PracticeMode = () => {
  return (
    <Container id="practice-mode">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <Section>
            <Stack gap={3} direction="row" alignItems="center">
              <Image
                src="/images/pages/arena/icons/practice.png"
                alt="Practice Mode"
                maxWidth={100}
              />
              <Title newTag={true}>Practice Mode</Title>
            </Stack>
            <Text large={true}>
              Practice Mode allows you to hone your skills without having to
              worry about your ranking.
            </Text>
            <Text large={true}>
              You can play an unlimited number of Practice Mode battles every
              day. Each win will earn you 1 EXP.
            </Text>
            <SubTitle>Battle System</SubTitle>
            <List>
              <ListItem minimal={true}>
                Every battle starts with both trainers deciding on their turn
                preferences (Go First or Go Second).
              </ListItem>
              <ListItem minimal={true}>
                If both players choose the same turn preference- they enter into
                Rock Paper Scissors to decide who gets their preference.
              </ListItem>
              <ListItem>
                Each player will have 45 seconds to finish their turn. After 45
                seconds, the turn will end automatically.
              </ListItem>
              <ListItem minimal={true}>
                If a player has been inactive for 2 turns, they will have 10
                seconds to become active again, or else they will be forced to
                lose the match.
              </ListItem>
            </List>
          </Section>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Section
            info={
              <Info>
                There is no ranking system or leaderboard in Practice Mode.
              </Info>
            }
          >
            <SubTitle>Battle Log</SubTitle>
            <Text large={true}>
              Check the in-game Battle Log to see your recent matchups and
              battle history.
            </Text>

            <Image src="/images/pages/arena/battle-log.jpg" alt="Battle Log" />
          </Section>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PracticeMode;
