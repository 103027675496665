export const axies = {
  buba: {
    slug: "buba",
    name: "Buba",
    class: "beast",
    role: "Damage Dealer",
    specialties: ["Rage", "Burst Damage", "Backdoor"],
    cards: [
      "eyes-sparky",
      "ears-foxy",
      "mouth-foxy",
      "horn-persimmon",
      "back-forest-hero",
      "tail-buba-brush",
    ],
    story: [
      "Buba is one of Olek's young and promising Frontier Corp students.",
      "Buba was born in a small village near the forest.  When he was a young lad, a Frontier battalion marched through his hometown, which sparked an interest and later on his heart's dream to become the greatest Frontier soldier ever.",
      "He's short-tempered, groomed, and overly meticulous. His rage can sometimes be so intense that it causes his eyes to spark electricity.",
      "Even though it sometimes doesn't come across, he truly cares for those that are closest to him and would do anything to help them.",
      "Buba loves his brush-shaped tail, even though he's a terrible artist. If you want to upset Buba, just try and mess up his tail.",
    ],
    perk: "Sometimes, Buba's rage can be so intense that it causes his eyes to spark electricity.",
  },
  olek: {
    slug: "olek",
    name: "Olek",
    class: "plant",
    role: "Tank",
    specialties: ["High Shield", "Self-Heal", "Card Draw"],
    cards: [
      "eyes-risky-trunk",
      "ears-hidden-ears",
      "mouth-beetroot",
      "horn-rusty-helm",
      "back-succulent",
      "tail-sprout",
    ],
    story: [
      "Olek is a veteran warrior with a lifetime of fighting experience who recently retired to become a turnip farmer. One of his most distinctive characteristics is the large scar on his back, which he acquired as he protected his fallen comrade during a ferocious battle many years ago. During the fight, Olek leaped in front of his friend just as a Chimera viciously attacked, nearly killing Olek in the process. He was ultimately saved by his trusty helmet, which he still wears to this day, despite it's rust and misshapen nature.",
      "Olek is a brave, calm, and patient teacher who loves to share his knowledge with others. It's no surprise that he was tasked to mentor and train the next generation of Frontier soldiers. His goal is to ensure that each of his students passes the Maturity Ceremony, which is the final evaluation that demonstrates an Axie's ability to meet Frontier soldier standards.",
      "Olek is regularly attempting to flirt with the ladies, but it never seems to go quite as planned. Most of the time his students end up saving him and everyone around from further embarrassment!",
    ],
    perk: "Since most of his body is plant-based, Olek has the ability to bury himself into the ground which allows him to absorb the soil's nutrients and heal his injures.",
  },
  puffy: {
    slug: "puffy",
    name: "Puffy",
    class: "aquatic",
    role: "Damage Dealer",
    specialties: ["Damage Boost", "Burst Damage", "HP Drain"],
    cards: [
      "eyes-baby",
      "mouth-puff",
      "ears-little-crab",
      "horn-jellytacle",
      "back-tiny-dino",
      "tail-puff",
    ],
    story: [
      "Puffy is one of Olek's young, promising Frontier Corp students.",
      "Most find her to be incredibly cheerful, full of life, and perhaps even a little naive, but those closest to her know better!  She can be very calculating and mischievous.",
      "Even though she comes from a well known family who lives in a small village near Lake Shrine, Puffy chose to establish her own identity and independence early in her life. She hopes that becoming a Frontier soldier will allow her to visit all of the biggest cities in Lunacia, and put her back in contact with her older brother Puff, a legendary Axie.",
      "Her distinguishing characteristic, the colorful crab-twintail horns, were a gift from her brother.  When they were both growing up together, Puff and Puffy would often race up waterfalls, but Puff always seemed to get the upper hand. One day, Puffy came up with a trick of wrapping herself inside a water bubble, to instead quickly float up the waterfall and finally win the race.  Puff gave her the twintail as a gift for her cleverness, and the water bubble continues to be an important battle tactic during her training.",
    ],
    perk: "Puffy can create a water bubble large enough to enclose herself and her allies, helping them float above the air.",
  },
  ena: {
    slug: "ena",
    name: "Ena",
    class: "plant",
    role: "Support Defensive",
    specialties: ["Heal Ally", "Card Clone", "Protect Ally"],
    cards: [
      "eyes-papi",
      "ears-sakura",
      "mouth-cub",
      "horn-mandarine",
      "back-magic-sack",
      "tail-aegis-talisman",
    ],
    story: [
      "Ena is an Axie born and raised in the forest as a lifelong servant of the Moon Shrine. In that role, she developed a close friendship with the Guardian Fairy, and takes pride in caring for her needs. One day while cleaning the shrine's basement, Ena found a hidden, magical pouch.",
      "She and the Guardian Fairy discovered that this pouch could create a copy of any inorganic object, at the cost of the holder's energy, and if misused perhaps their life. Sensing the danger of this artifact in the wrong hands, the Old Guardian bond the magical pouch to Ena, making her the only one who could use it.",
      "Ena really needs her beauty rest. She loves sleep so much that she sometimes even falls asleep in the middle of a fight. After meeting the trio of Olek, Buba and Puffy, Ena decided to join them, and travel out of the forest for the first time in her life.",
    ],
    perk: "One day, the small spring within the forest started to rapidly overflow and threatened to flood the nearby area. Ena used her duplicating pouch to copy and drop a giant boulder into the spring's opening. This dried-out spring lives on today.",
  },
  tripp: {
    slug: "tripp",
    name: "Tripp",
    class: "beast",
    role: "Damage Dealer",
    specialties: ["Damage Ramp", "Burst Damage", "Card Draw"],
    cards: [
      "eyes-zeal",
      "ears-nut-cracker",
      "mouth-nut-cracker",
      "horn-acorn-cap",
      "back-nutdha-statue",
      "tail-nut-cracker",
    ],
    story: [
      "Tripp is a curious Axie who has an almost obsessive interest in acorns. As a younger boy, he one night dreamed that a massive, beautiful acorn fell from the sky and landed in the Great Forest, far away from the small village where he was raised.  He woke up from that dream, convinced it was a prophecy, and formed the Cult of Acorn as it's first (and only) member.",
      "In his desperate search for the Great Acorn, Tripp got lost in the forest. Fortunately, he was discovered by the Old Guardian, who tried to help him find his way back home. Tripp, while appreciative of the Guardian's help, instead offered to become her protector, to both repay the Guardian's kindness, and continue his search for the Great Acorn.",
      "Months passed, and while the bond between the Old Guardian and Tripp grew, the Great Acorn was nowhere to be found. At the peak of his despair, he ultimately came across the trio of Olek, Buba, and Puffy and saw it as a sign to continue his quest to discover the Great Acorn alongside them.",
    ],
    perk: "Tripp almost broke the Lunacian nut-cracking contest record. He cracked 243 nuts in less than a minute, far exceeding the previous record of 124. Unfortunately, he ate them all, which disqualified him from being listed in the record books.",
  },
  momo: {
    slug: "momo",
    name: "Momo",
    class: "bird",
    role: "Damage Dealer",
    specialties: ["Damage Ramp", "Burst Damage"],
    cards: [
      "eyes-concentrate",
      "ears-brimstone",
      "mouth-feathery-dart",
      "horn-big-sister",
      "back-lil-bro",
      "tail-death-shower",
    ],
    story: null,
    perk: null,
  },
  pomodoro: {
    slug: "pomodoro",
    name: "Pomodoro",
    class: "bug",
    role: "Support Defensive",
    specialties: ["Shield Ally", "Protect Ally", "Draw Card"],
    cards: [
      "eyes-passion",
      "ears-leaf-bug",
      "mouth-platypus",
      "horn-aborted-dream",
      "back-village-hero",
      "tail-leaf-bug",
    ],
    story: null,
    perk: null,
  },
  venoki: {
    slug: "venoki",
    name: "Venoki",
    class: "reptile",
    role: "Damage Dealer",
    specialties: ["Poison", "Damage Ramp", "Clone Card"],
    cards: [
      "eyes-punky",
      "ears-venom-nail",
      "mouth-chemical-fang",
      "horn-poison-tube",
      "back-death-shroom",
      "tail-centipede",
    ],
    story: null,
    perk: null,
  },
  xia: {
    slug: "xia",
    name: "Xia",
    class: "beast",
    role: "Damage Dealer",
    specialties: ["Rage", "Damage Ramp", "Shield"],
    cards: [
      "eyes-hardboiled",
      "ears-belieber",
      "mouth-hazelnut",
      "horn-rocky-skull",
      "back-pangolin-slayer",
      "tail-pangolin",
    ],
    story: null,
    perk: null,
  },
  bing: {
    slug: "bing",
    name: "Bing",
    class: "beast",
    role: "Damage Dealer",
    specialties: ["Rage", "Damage Ramp"],
    cards: [
      "eyes-puppy",
      "ears-puppy",
      "mouth-puppy",
      "horn-toy-ball",
      "back-cone-shell",
      "tail-shiba",
    ],
    story: null,
    perk: null,
  },
  rouge: {
    slug: "rouge",
    name: "Rouge",
    class: "aquatic",
    role: "Damage Dealer",
    specialties: ["Bubble", "Sponge", "Support/Offensive"],
    cards: [
      "eyes-kind-fish",
      "ears-gill",
      "mouth-ranchu",
      "horn-lotus",
      "back-sponge",
      "tail-eye-wing",
    ],
    story: null,
    perk: null,
  },
  noir: {
    slug: "noir",
    name: "Noir",
    class: "aquatic",
    role: "Damage Dealer",
    specialties: ["Bubble", "Sponge", "Shield"],
    cards: [
      "eyes-cold-fish",
      "ears-gill",
      "mouth-ranchu",
      "horn-beast-bun",
      "back-sponge",
      "tail-oranda",
    ],
    story: null,
    perk: null,
  },
};
